//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import PostTemplateCtaStatic from "../../components/marketing/postTemplateCtaStatic";

//Icons

const useCaseData = [
  {
    context: "Sustainability tokens",
    contextColor: "yellow-1",
    title: "Show demonstrated action",
    description:
      "Demonstrate how clients are aligning themselves with your green certification, by using public ledgers to verify and record their actions",
    image: "nft4ClimateEco",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "experience",
    // videosource: 'console-1',
  },

  {
    context: "Increasing trust",
    contextColor: "sky-1",
    title: "Increase certification value",
    description:
      "Add value to your certification by showing sustainability actions taken in a simple, easy-to-use format using public ledgers",
    image: "nft3Climate",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "trust",
    // videosource: 'console-3',
  },
  {
    context: "Sustainability goals",
    contextColor: "pink-1",
    title: "Drive guest engagement",
    description:
      "Drive guest engagement by showing - not just telling -  how your certification program advances an organization's sustainability goals",
    image: "nft1ASocial",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "pink",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "sustainability",
    // videosource: 'console-2',
  },
  {
    context: "Augment transparency",
    contextColor: "green-1",
    title: "Drive increased transparency",
    description:
      "Coordinate actions from stakeholders - including properties, certification programs and auditors - on public ledgers, and display them to drive transparency",
    image: "nft3ClimateSendAlt",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'gray-5',
    anchor: "transparency",
    // videosource: 'console-2',
  },
  // {
  //   context: 'Climate-friendly public ledgers',
  //   contextColor: 'seafoam-1',
  //   title: 'Reduce energy cost for Web3 minting',
  //   description:
  //     'Use Ethereum proof-of-stake and Polygon blockchains to reduce minting energy consumption by over 99%',
  //   image: 'nft3ClimateEco',
  //   iconShow: false,
  //   // //iconContext: 'iphone',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'gray-5',
  //   anchor: 'sustainability',
  //   // videosource: 'console-2',
  // },

  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: false,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-seatosky py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Increase trust and transparency in sustainability strategies
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">
                Enhance certifications with public ledgers
              </h1>

              <p class="lead">
                Use public ledgers to verify and show that clients are compliant
                with your green certification program
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />
      <hr size={1} />
      <div class="mt-3 mx-2 mx-lg-5">
        <div class="my-2 d-flex flex-column ">
          <PostTemplateCtaStatic
            title="See a tour of a Sustainability Action Report"
            link="/tour/sustainability-action-report"
            cta="See tour"
          />
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Green Sustainability Certifications"
      description="Drive trust and transparency in your sustainability certification program"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-lisbon-sustainability-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
