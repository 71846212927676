//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Packages

//Components
import Layout from "../components/layout";
import { Metatags } from "../components/marketing/metatags";

import AlternatingCallout from "../components/marketing/alternatingCallout";
import FeatureQuad from "../components/marketing/featureQuad";
// import NavSubAbout from "../components/nav/navSubAbout";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Create NFTs",
    contextColor: "dkblue-1",
    title: "Design compelling NFT artwork",
    description:
      "Get started using branded templates to create NFT artwork quickly using a no-code tool, and import your own assets to include in your non-fungible token",
    image: "nft1LoyaltyB",
    iconShow: true,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "sky-1",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Mint your NFT",
    contextColor: "sky-1",
    title: "Create NFTs easily on the blockchain",
    description:
      "Make NFT creation easy when mint your NFT on the Ethereum or Polygon blockchains. Add metadata to your non-fungible token artwork and mint your NFT on the blockchain",
    image: "nft2EventBands",
    iconShow: true,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "dkgreen-1",
    anchor: "mobile",
    // videosource: 'console-2',
  },

  {
    context: "Sell using a custom branded marketplace",
    contextColor: "dkgreen-1",
    title: "Set pricing and sell your NFT using your brand",
    description:
      "Quickly create a NFT marketplace with your brand assets, and sell NFTs directly to your customers",
    image: "nft2EventBandsStore",
    iconShow: true,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "yellow-1",
    anchor: "marketplace",
    // videosource: 'console-3',
  },
  {
    context: "Build community",
    contextColor: "yellow-1",

    title: "Engage users with branded communities",
    description:
      "Deliver exclusive content, perks, and benefts to customers via turnkey landing pages that authenticate users via their NFTs",
    image: "nft3LoyaltyA",
    iconShow: true,
    // //iconContext: 'imagespread',
    iconColor: "yellow",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "pink-1",
    anchor: "engage",
  },
  {
    context: "Modern loyalty",
    contextColor: "pink-1",

    title: "Create an NFT loyalty program",
    description:
      "Award NFTs to customers based on their activity, and engage deeply with Gen Z / Millennial customers by supporting social impact programs they care about",
    image: "nft2LoyaltyImpactB",
    iconShow: true,
    // //iconContext: 'imagespread',
    iconColor: "pink",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'pink-1',
    anchor: "loyalty",
  },
];

const featureData = {
  title: "Benefits of creating NFTs using PicoNext",
  titlecolor: "white-1",
  mode: "trio",
  bggradient: "gradient-callout-overcast",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Unique assets",
          colbgcolor: "sky-1",
          iconname: "award",
          description:
            "Create one-of-a-kind artwork cryptographically authenticated by the blockchain",
        },
        {
          title: "Rapid creation",
          colbgcolor: "bluegreen-1",
          iconname: "lightning",
          description:
            "Easily create visual, NFT assets and short videos using drag-and-drop",
        },
        {
          title: "Complete platform",
          colbgcolor: "sky-1",
          iconname: "moneycheck",
          description:
            "Use an end-to-end platform to design artwork, mint NFTs and list for sale",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    {/* <NavSubAbout /> */}

    <div class=" gradient-callout-tropical py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Design, mint, and deliver branded NFTs to your customers
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h1 class="fw-light black-1 display-2">NFT Creator</h1>

            <p class="lead">
              Use an end-to-end NFT creator that helps you design, mint, and
              list your NFT for sale &mdash; especially for brands
            </p>
          </div>
        </div>
      </div>
    </div>
    <AlternatingCallout featureData={useCaseData} />

    <div class="gradient-callout-tropical">
      <div class="container container-page my-4 py-4">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <h2 class="fw-medium white-1">Request a demo</h2>
            <p class="display-4 white-1">
              PicoNext NFT creation is part of an end-to-end Web3 solution,
              including design, loyalty, communities and more.
            </p>
            <div>
              <Link to="/demo/video/" class="btn btn-cta-outline">
                See demo &rarr;
                {/* <FaArrowRight class="btn-arrow" /> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container container-page my-4 pt-4 pb-2">
        <div class="row">
          <div class="col-8 offset-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <h3 class="fw-light black-1 display-3">NFT creator features</h3>

            <ul class="text-start">
              <li class="my-2 fw-light">
                <span class="fw-bold">Create NFT artwork. </span>
                PicoNext helps you create NFT artwork without coding. Use
                templates to get started and customize them with elements like
                emoji, typography, color and more.
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">Design NFTs anywhere. </span>
                And design your NFT on mobile, tablet, or desktop &mdash;
                whatever suits you best &mdash; without apps to download,
                install, or update.
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">Customize your NFT design. </span>
                In addition, you can choose from thousands of videos and stock
                imagery to add to your NFT artwork &mdash; simply search and add
                to your creation.
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">Add metadata and mint your NFT. </span>
                NFTs offer a unique, one-of-a-kind way to authenticate your
                digital creation. Add metadata to your NFT to further enhance
                your creation, and then mint it on the blockchain from directly
                within PicoNext using ERC-721 or ERC-1155 contracts on Ethereum
                or Polygon.
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">
                  Sell NFTs using a branded marketplace.{" "}
                </span>
                Quickly spin up one - or many - marketplace pages, and customize
                with your brand assets and messaging. Set pricing for NFTs and
                list for sale in your storefront in the quantities your project
                requires.
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">
                  Engage users with private branded communities.{" "}
                </span>
                Quickly create community-oriented landing pages that serve as a
                hub for your NFT-enabled community &mdash; delivering exclusive
                perks, benefits, and content to members who hold specific NFTs
              </li>
              <li class="my-2 fw-light">
                <span class="fw-bold">
                  Launch a NFT customer loyalty program.{" "}
                </span>
                Award NFTs to customers based on their activity, and engage
                deeply with Gen Z / Millennial customers by supporting social
                impact programs they care about
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
      <FeatureQuad featureData={featureData} />
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="NFT Creator"
      description="Create NFTs, mint NFTs and list for sale using PicoNext"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: { eq: "featureperspective/feature-nft-1A-create.png" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
