//React
import React from "react";

//Gatsby

//Components
import IndustryImages from "./industryImages";

const Snippet = (props) => (
  <IndustryImages industry={props.logo} size="" output="image" />
);

export default Snippet;
