//React
import React from "react";

//Packages

//Components
import Layout from "../components/layoutLanding";
import { Metatags } from "../components/marketing/metatags";

import ContactLeadForm from "../components/marketing/contactLeadForm";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div class="flex-grow-1">
          <ContactLeadForm
            leadSource="request-access"
            title="Request access"
            subtitle="Request access to the PicoNext Digital Product Passport Platform"
          />
        </div>
      </Layout>
    );
  }
}

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Request Access"
      description="Request access to the PicoNext Digital Product Passport platform"
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};
