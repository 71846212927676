//React
import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";

//Gatsby
import { Link, graphql } from "gatsby";

//Packages
// import parse from "url-parse";

//Actions

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";
import InstantDemo from "../../components/demo/instantDemo";
import VideoDemo from "../../components/demo/videoDemo";

//Icons
// import { FaArrowRight } from "react-icons/fa";

//Redux
// const mapStateToProps = ({ isLoadingDemo, demoData }) => {
//   return {
//     isLoadingDemo,
//     demoData,
//   };
// };
// const mapDispatchToProps = (dispatch) => ({
//   initiateDppDemo: (userData) => {
//     dispatch(initiateDppDemo(userData));
//   },
// });

const Snippet = () => {
  // const [campaignId, setCampaignId] = useState("");

  // useEffect(() => {
  //   const url = parse(window.location.href, true);

  //   console.log("demo/index - url");
  //   console.log(url);

  //   if (url.query.c) {
  //     console.log("demo/index - url.query.c");
  //     console.log(url.query.c);

  //     setCampaignId(url.query.c);
  //   }
  // }, []);

  return (
    <Layout>
      <div class="gradient-callout-cousteau">
        <div class="container container-page container-landing-minimal">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-start">
              <h1 class="display-2 fw-medium white-1">
                Digital Product Passport Demo
              </h1>
              {/* <p class="display-4 white-4">
                Use AI to create an instant, sample DPP for one of your
                products, at no charge
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div class={`container container-page container-page-demo`}>
        {/* {!currentPageMode && ( */}
        <div class="row my-4">
          <div class="col-12 col-lg-8 offset-lg-2 ">
            <h2 class="px-2 text-center">Select a demo</h2>
            <div class="display-4 p-2 mb-2 black-1 text-center">
              See how PicoNext can help you create Digital Product Passports
              with an interactive demo
            </div>

            <div class="d-flex flex-column flex-md-row align-items-stretch">
              <Link
                class="demo-selection-item"
                to="/request-consultation/"

                // onClick={() => {
                //   setCurrentPageMode("instant");
                // }}
              >
                Request consultation
              </Link>
              {/* <Link
                class="demo-selection-item"
                to="/demo/create-dpp"

                // onClick={() => {
                //   setCurrentPageMode("instant");
                // }}
              >
                Create free, sample DPP using AI
              </Link> */}
              <Link
                class="demo-selection-item"
                to="/demo/video"
                // onClick={() => {
                //   setCurrentPageMode("video");
                // }}
              >
                View video demo
              </Link>
            </div>
          </div>
        </div>
        {/* )} */}
        {/* {currentPageMode === "instant" && (
          <InstantDemo
            currentPagePos={currentPagePos}
            setCurrentPagePos={setCurrentPagePos}
            currentPageMode={currentPageMode}
            setCurrentPageMode={setCurrentPageMode}
            campaignId={campaignId}
            setCampaignId={setCampaignId}
            data={data}
          />
        )}
        {currentPageMode === "video" && (
          <VideoDemo
            currentPagePos={currentPagePos}
            setCurrentPagePos={setCurrentPagePos}
            currentPageMode={currentPageMode}
            setCurrentPageMode={setCurrentPageMode}
            campaignId={campaignId}
            setCampaignId={setCampaignId}
            data={data}
          />
        )} */}
        {/* <hr size="1" /> */}

        {/* <div class="text-center w-100 d-flex flex-row align-items-center justify-content-center mt-3">
          <Link
            class="btn btn-link btn-link-plain black-5 small"
            to="/request-consultation/"
          >
            Request a consultation
          </Link>
        </div> */}
      </div>
    </Layout>
  );
};

export default Snippet;
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet);

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Demo"
      description="View interactive demos of the PicoNext Digital Product Passport platform"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/dpp-planner-manufacturing-chart.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
