//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import BlogCallout from "../../components/marketing/blogCallout";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Mission-driven tokens",
    contextColor: "yellow-1",
    title: "Create experiences for your social cause",
    description:
      "Craft beautiful experience tokens from pre-built templates that drive impact, and mint them on sustainable blockchains",
    image: "nft1Social",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Create community",
    contextColor: "sky-1",
    title: "Build purpose-driven communities",
    description:
      "Engage participants with content in token-enabled communities that drive impact and change",
    image: "nft2LoyaltyImpact",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-3',
  },
  {
    context: "Programmatic donations",
    contextColor: "dkblue-1",
    title: "Raise funds for social impact",
    description:
      "Use blockchain smart contracts to programatically donate funds to organizations that align with your customers' values",
    image: "nft3Social",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Climate change",
    contextColor: "green-1",
    title: "Promote climate sustainability",
    description:
      "Use token events to keep stakeholders informed about your climate mitigation strategies and drive increased transparency around coordinated efforts",
    image: "nft1ASocialAlt",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'green-1',
    anchor: "mobile",
    // videosource: 'console-2',
  },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: false,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-fire py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Build sustainable communities that drive social impact
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Blockchain for Social Impact</h1>

              <p class="lead">
                Engage customers with mission-driven programs that fulfill a
                social purpose
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />
      <BlogCallout
        context="socialimpact"
        title="Social Impact Resources"
        desc="Articles to help you engage customers using blockchain-based social impact programs"
        showMoreButton={true}
      />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Branded experiences for Social Impact"
      description="Engage deeply with Gen Z / Millennial customers with social impact programs"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/resortsplus-impact-africawater-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
