//React
import React, { useEffect, useState } from "react";

//Packages
import parse from "url-parse";

//Components
import Layout from "../components/layoutLanding";
import { Metatags } from "../components/marketing/metatags";

import ContactLeadForm from "../components/marketing/contactLeadForm";

const Snippet = (props) => {
  const [campaignId, setCampaignId] = useState("");
  useEffect(() => {
    //like componentDidMount
    const url = parse(window.location.href, true);

    console.log("request-demo/index - url");
    console.log(url);

    if (url.query.c) {
      console.log("request-demo/index - url.query.c");
      console.log(url.query.c);
      setCampaignId(url.query.c);
    }
  }, []);

  return (
    <Layout>
      <div class="flex-grow-1">
        <ContactLeadForm
          context="shopify-access"
          title="Request access to the PicoNext Product Passport app"
          subtitle="Request access to the PicoNext Product Passport app for Shopify. We'll email you an invite when available."
          leadSource={`request-invite-shopify${
            campaignId ? `-${campaignId}` : ""
          }`}
        />
      </div>
    </Layout>
  );
};

export default Snippet;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Request access - PicoNext Product Passport app for Shopify"
      description="Request access to the PicoNext Product Passport app for Shopify."
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};
