//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import PostTemplateCtaStatic from "../../components/marketing/postTemplateCtaStatic";
import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Direct integration with Shopify",
          description:
            "Accelerate DPP creation by accessing product data directly from within the Shopify admin panel.",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutShopify2",
        },
        {
          title: "Generative AI",
          description:
            "Quickly summarize product information into DPP templates within the Shopify admin using the PicoNext AI Assistant.",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutShopify1",
        },
        {
          title: "Customizable DPP templates",
          description:
            "Organize data with pre-configured DPP templates and sustainability attributes for a variety of product categories, or customize your own template unique to your product set",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner1",
        },
        {
          title: "Turnkey DPP rendering",
          description:
            "Publish Digital Product Passports instantly in the cloud and render DPP information using a pre-formatted, turnkey DPP viewer",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutShopify3",
        },

        {
          title: "DPP Planner",
          description:
            "Organize, edit, and add DPP product information using a visual, template-based tool",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner2",
        },
        {
          title: "QR code download",
          description:
            "Download QR codes from within Shopify to distribute along with products and give customers easy access to DPP information",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutShopify4",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Create Digital Product Passports directly from within your
                Shopify store
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/passport-usecases/shopify-app-create.jpg"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Create Digital Product Passports in Shopify
            </h1>

            <p class="display-4 line-height-4 p-2">
              Create Digital Product Passports using generative AI using the
              PicoNext Product Passports app for Shopify
            </p>
            <Link
              to="/request-commerce-access/"
              class="btn btn-outline-primary"
            >
              Request app access &rarr;
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>

    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Features: PicoNext Product Passports app for Shopify
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container my-2 mt-4 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Take a video tour of the PicoNext Product Passports app for
              Shopify
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <YoutubeComponent id="O2t265IJIs0" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr size={1} />
    <div class="mx-3 mx-lg-5">
      <div class="my-2 d-flex flex-column ">
        <PostTemplateCtaStatic
          title="Get access to the PicoNext Product Passports for Shopify app"
          link="/request-commerce-access/"
          cta="Request access"
          gradient="algae"
        />
      </div>
    </div>

    <CalloutSupportSection
      title="the PicoNext Product Passports app for Shopify"
      section="integrations"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passports for Shopify"
      description="Create Digital Product Passports from within Shopify for your store"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
