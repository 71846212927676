//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";

//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Send sustainability data",
          description:
            "Push sustainability data to your Digital Product Passport, like materials composition, recyclability, durability and more",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutTransparency2",
        },
        {
          title: "Categorize data points",
          description:
            "Group sustainability events by category, allowing customers to more easily view lengthy Digital Product Passports",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutWalletExplorer7",
        },
        {
          title: "Manage private DPP data access",
          description:
            "Make certain DPP data events available to stakeholders with approved access - like suppliers, distributors, or government authorities",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutEventAccess1",
        },
        {
          title: "Update DPP info on an item or batch level",
          description:
            "Attach item- or manufacturing batch-level data to your DPP, and define data schemas for managing granular information",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutItemBatch1",
        },

        {
          title: "Record event dates",
          description:
            "Add dates to your data points for when activities occured, and note those dates on the Digital Product Passport for customers",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutTransparency5",
        },
        // {
        //   title: 'Avoid sustainability greenwashing',
        //   description:
        //     "Show stakeholders specific steps you're taking in sustainability programs by recording step-by-step actions in carbon capture, sustainable product sourcing, and more",
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'collection',
        //   image: 'calloutTransparency2',
        // },
        // {
        //   title: 'Mitigate AI deepfake content',
        //   description:
        //     "Use public ledgers to authenticate your branded content, and protect your brand's reputation from deepfake content and AI-generated misinformation",
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'shield',
        //   image: 'calloutTransparency1',
        // },
        // {
        //   title: 'Engage users with easily consumable reports',
        //   description:
        //     'Deliver transparency reports to users in easily consumable, mobile-friendly formats with nothing to download or install',
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',

        //   iconname: 'calendar',
        //   iconcolor: 'sky',
        //   image: 'calloutTransparency3',
        // },
        {
          title: "DPP Planner template integration",
          description:
            "Accelerate your workflow for common Digital Product Passport attributes by using templates to send  data events for your industry and product categories, directly from DPP Planner",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "list",
          image: "calloutTransparency4",
        },
        {
          title: "Use streamlined cloud DPP workflows",
          description:
            "Quickly publish sustainability data to cloud-based DPPs using streamlined workflows in DPP Planner",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "list",
          image: "calloutTransparency6",
        },
        // {
        //   title: 'Customize on-chain messages',
        //   description:
        //     'Send custom messages to the blockchain related to your token, and drive transparency with your customers',
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'conversation',
        // },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Attach sustainability data to your blockchain Digital Product
                Passports
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/passport-usecases/fashionnetwork-passport-shoe-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Send sustainability data to a blockchain Digital Product Passport
            </h1>

            <p class="display-4 line-height-4 p-2">
              Drive customer trust and comply with regulations by appending
              sustainability data to your Digital Product Passport on a public
              ledger
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>

    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">DPP Data Event features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container my-2 mt-4 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              See DPP Data Events in this video tour
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <YoutubeComponent id="ShMhe4EBKsg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr size={1} />

    <CalloutSupportSection
      title="using DPP Data Event capabilities"
      section="tokenevents"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Data Events"
      description="Attach sustainability data to your Digital Product Passport, with categories and dates"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-passport-shoe-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
