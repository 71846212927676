//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";

import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Strategy guide</div>
            <h1 class="h2">
              <span class="fw-normal">Digital Product Passports:</span>

              <br />
              <span class="fw-light">
                Unlocking Sustainability Transparency
              </span>
            </h1>
            <p class="lead line-height-4 py-3">
              In this free guide, get the latest trends on sustainability
              transparency from new survey research, along with ways that brands
              can transform their strategies to take advantage.
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              <StaticImage
                src="../../images/landing/202311-dpp-sustainability/report-dpp-survey-cover.jpg"
                alt="Digital Product Passports:
                    Unlocking Sustainability Transparency"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={190}
                className="mx-3"
                // height={400}
                // style={{ position: `absolute` }}
              />
              <StaticImage
                src="../../images/landing/202311-dpp-sustainability/infographic_Q4_h.jpg"
                alt="Digital Product Passports: Global survey data"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={260}
                className="mx-3"
                // height={400}
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>In this free guide, you'll find:</strong>
              <ul>
                {/* <li>
                      An overview of sustainability transparency options for
                      brands
                    </li> */}
                <li>
                  Strategies that fashion, manufacturing, and other companies
                  can use to drive transparency
                </li>
                <li>
                  How to develop a Digital Product Passport strategy to comply
                  with global regulations
                </li>
                <li>
                  Data and infographics from our original sustainability
                  transparency survey research of 1000+ consumers in Europe and
                  the United States
                </li>
                <li>
                  And more ideas and strategies to use sustainability to engage
                  customers
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
                
                </div> */}

            <p class="lead line-height-4 mt-4">
              Receive your free copy of "Digital Product Passports: Unlocking
              Sustainability Transparency" by filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="offer-DPP-survey-guide"
              hideTitle={true}
              title="Request invite"
              subtitle="Please fill out the form below to receive your free guide"
              cta="Submit"
              context="landing"
              offerAttachment="PicoNext--Report--Digital-Product-Passport-Sustainability-Transparency.pdf"
              offerTitle="Digital Product Passports: Unlocking Sustainability Transparency"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passports: Unlocking Sustainability Transparency"
      description="Get access to the strategy guide Digital Product Passports: Unlocking Sustainability Transparency"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "landing/202311-dpp-sustainability/report-dpp-survey-2up-blog.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
