//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";

//Icons

const useCaseData = [
  {
    title: "Digital Product Passport",
    description:
      "Deliver insight into product sustainability & comply with regulatory requirements",
    image: "productPassportWalletExplorerAlt",

    iconShow: false,
    //iconContext: "iphone",
    iconColor: "dkorange",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "create",
    buttonText: "More about Digital Product Passports",
    buttonPath: "/solutions/digital-product-passport/",
  },
  {
    title: "Sustainability transparency",
    description:
      "Avoid greenwashing & increase trust and transparency in sustainability strategies",
    image: "nft1ATransparency",

    iconShow: false,
    //iconContext: "iphone",
    iconColor: "dkorange",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "create",
    buttonText: "More about Sustainability Transparency",
    buttonPath: "/solutions/web3-climate-sustainability/",
  },

  {
    title: "Authentication for AI-generated content",
    description:
      "Protect your brand's reputation from deepfakes by using public ledgers to authenticate your branded content as genuine",
    image: "auth1Content",
    iconShow: false,
    //iconContext: "recentactors",
    iconColor: "purple",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "equip",
    buttonText: "More about content authentication",
    buttonPath: "/solutions/authentication-ai-generated-content/",
  },
  {
    title: "Customer loyalty",
    description:
      "Find and engage new customers with blockchain loyalty programs that capture experience and create community",
    image: "nft1LoyaltyC1",
    iconShow: false,
    //iconContext: "recentactors",
    iconColor: "purple",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "equip",
    buttonText: "More about loyalty",
    buttonPath: "/solutions/web3-customer-loyalty/",
  },
  {
    title: "Local Access Pass",
    description:
      "Unlock local experiences with tokens to drive brand differentiation and customer loyalty",
    image: "localAccessPass1",
    iconShow: false,
    //iconContext: "recentactors",
    iconColor: "purple",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "equip",
    buttonText: "More about Local Access Passes",
    buttonPath: "/solutions/web3-local-access-pass/",
  },
  {
    title: "Social impact",
    description:
      "Harness the power of your brand community to deliver change and raise funds for social causes using digital tokens",
    image: "nft3SocialAlt",

    iconShow: false,
    //iconContext: "iphone",
    iconColor: "dkorange",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "create",
    buttonText: "More about social impact",
    buttonPath: "/solutions/web3-social-impact/",
  },

  {
    title: "Events",
    description:
      "Engage customers with branded event experiences, enhanced by experience tokens",
    image: "nft2FeatureOverviewAlt",

    iconShow: false,
    //iconContext: "lightning",
    iconColor: "green",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "share",
    buttonText: "More about tokenized events",
    buttonPath: "/solutions/web3-events/",
  },

  // {
  //   title: 'Discover & promote your NFTs',
  //   description:
  //     'Use listing, promotion, and discoverability tools to make customers aware of your NFTs, including on external NFT marketplaces and on your own branded properties',
  //   image: 'featureTemplateTravel',

  //   iconShow: true,
  //   //iconContext: 'iphone',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'create',
  // },

  // {
  //   title: 'Short-form Videos',
  //   description:
  //     'Produce customized videos about your products for Instagram Stories, TikTok and more - all based on brand templates. COMING SOON',
  //   image: 'featurePublisherVideoTrimmer',
  //   iconShow: true,
  //   //iconContext: 'videocamera',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'sky-1',
  //   anchor: 'video',
  // },
];

const About = (props) => (
  <Layout>
    <div class="border-bottom-1-sky-1 gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Accelerate customer engagement with end-to-end, no-code public
                ledger solutions for brands
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="text-center mt-3 mb-4 fw-medium black-1">
      Public ledger solutions for brands
    </h1>
    <AlternatingCallout featureData={useCaseData} />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Blockchain / Public ledger Solutions for Brands"
      description="Accelerate customer engagement through blockchain solutions for Digital Product Passports, customer loyalty, content authentication and more"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "stock/unsplash/ricardo-gomez-angel-pRZVCSTqje4-unsplash.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
