//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";
import CalloutSupportSection from "../../components/marketing/calloutSupportSection";

//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "yellow",
      rowdata: [
        {
          title: "Publish cloud-based DPPs",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "ticket",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Publish cloud-based DPPs to benefit from ease-of-deployment and flexible editing",
          // "Use off-chain tokens as way to ease customers into DPPs and loyalty programs, using cloud-based tokens that don't require digital wallets",
          // href: '/platform/at-a-glance/',
          image: "calloutMinting8",
        },

        // {
        //   title: 'Mint',
        //   colbggradient: 'gradient-callout-algae',
        //   // colbordercolor: 'yellow-1',
        //   titlecolor: 'black-4',

        //   iconname: 'lightning',
        //   iconcolor: 'white',

        //   description:
        //     'Mint NFTs on the blockchain using a no-code tool, directly from within PicoNext',
        //   desccolor: 'white-1',
        // },
        // {
        //   title: 'Deliver single or multiple token copies',
        //   description:
        //     'Mint single copy tokens using the ERC-721 standard, or mint multiple-copy tokens using the ERC-1155 standard',

        //   // colbgcolor: 'dkblue-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'yellow-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',

        //   iconname: 'copy',
        //   iconcolor: 'yellow',
        //   image: 'calloutMinting2',
        // },
        {
          title: "Gather DPP sustainability info",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "wallet",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Use DPP Planner to prepare and gather your sustainability information, using customizable templates geared for fashion, batteries, electronics, and other products",
          // href: '/platform/token-explorer/',
          image: "calloutPlanner1",
        },
        {
          title: "Display DPP Data using preformatted templates",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "wallet",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Use Token Explorer to retrieve DPP data from a blockchain/the cloud and display it to users using preformatted templates, no downloads or installs required",
          // href: '/platform/token-explorer/',
          image: "calloutWalletExplorer5",
        },
        {
          title: "Create QR codes for DPPs",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "ticket",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Download pre-built DPP QR codes, or create custom ones that link to product information",
          // href: '/platform/at-a-glance/',
          image: "calloutMinting9",
        },
        {
          title: "Publish DPPs across blockchains",
          description:
            "Publish DPPs on the Ethereum blockchain or the environmentally friendly Polygon blockchain",

          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'yellow-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "coin",
          iconcolor: "yellow",
          image: "calloutMinting6",
        },

        {
          title: "Blockchain smart contracts",
          description:
            "Use a PicoNext base smart contract to get started quickly with your DPP, with capabilities for DPP Data Events and more",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'yellow-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "yellow",

          iconname: "contract",
          image: "calloutMinting7",
        },
        // {
        //   title: 'Mint to external addresses',
        //   description:
        //     'Mint directly to external wallet addresses directly from within PicoNext',
        //   // colbgcolor: 'dkblue-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'yellow-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'yellow',

        //   iconname: 'wallet',
        // },
      ],
    },
  ],
};

const extraFeatureData = {
  title: "More Token Minting capabilities",
  titlecolor: "black-4",
  mode: "quad",
  bggradient: "gradient-callout-silver-reverse",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        // {
        //   title: 'Transfer tokens',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'black-4',
        //   colbordercolor: 'yellow-1',
        //   iconname: 'transfer',
        //   iconcolor: 'yellow',
        //   desccolor: 'gray-1',
        //   description:
        //     'Transfer tokens directly to recipients from within PicoNext, like after they visit your store or check into your property',
        //   // href: '/platform/digital-product-passport-data-events/',
        //   image: 'calloutMinting4',
        // },

        {
          title: "Connect multiple digital wallets",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "wallet",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Use widely supported digital wallets to publish blockchain-based DPPs, including Metamask, Coinbase, and WalletConnect supported wallets",
          // href: '/platform/token-explorer/',
          image: "calloutMinting5",
        },
        // {
        //   title: 'Mint to external addresses',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'black-4',
        //   colbordercolor: 'yellow-1',
        //   iconname: 'delivery',
        //   iconcolor: 'yellow',
        //   desccolor: 'gray-1',
        //   description:
        //     'Mint directly to external wallet addresses directly from within PicoNext',
        //   // href: '/platform/at-a-glance/',
        //   image: 'calloutMinting1',
        // },
        {
          title: "Use test networks before deployment",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "yellow-1",
          iconname: "ticket",
          iconcolor: "yellow",
          desccolor: "gray-1",
          description:
            "Deploy your Digital Product Passports on test blockchain networks before going live",
          // href: '/platform/at-a-glance/',
          image: "calloutMinting3",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Publish Digital Product Passports with a no-code, end-to-end
                solution &mdash; in the cloud or using a blockchain
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/passport-usecases/dpp-planner-manufacturing-chart.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Publish Digital Product Passports
            </h1>

            <p class="display-4 line-height-4 p-2">
              Rapidly publish Digital Product Passports (DPPs) &mdash; either in
              the cloud or on a blockchain
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3"></div>
    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">DPP Creation features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
              {extraFeatureData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container my-2 mt-4 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Take a video tour of DPP Creation
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <YoutubeComponent id="ShMhe4EBKsg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <CalloutSupportSection
      title="creating DPPs"
      section="mint"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Create Digital Product Passports"
      description="Publish Digital Product Passports (DPP) on a blockchain or in the cloud"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/dpp-planner-manufacturing-chart.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
