//React
import React from "react";
// import { connect } from "react-redux";

//Gatsby
// import { Script } from "gatsby";

//Actions
// import { updateCampaign } from "../actions/updateCampaign";

//Packages
// import parse from "url-parse";
import loadable from "@loadable/component";
import CookieConsent from "react-cookie-consent";

//Components
import NavMain from "./nav/navMain";
// import NavMinimal from "./nav/navMinimal";
// import NavStreamlined from "./nav/navStreamlined";
// import NavNone from "./nav/navNone";
// import FooterMinimal from "./nav/footerMinimal";
// import PostCta from "./marketing/postCta";

// import PricingCta from "./marketing/pricingCta";
// import PromoCampaignEightUseCases from "./marketing/promoCampaignEightUseCases";
// import PromoSurveyDPP from "./marketing/promoSurveyDPP";

import Footer from "./nav/footer";

//Assets
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/main.scss";

//Redux
// const mapDispatchToProps = (dispatch) => ({
//   updateCampaign: (campaignId) => {
//     dispatch(updateCampaign(campaignId));
//   },
// });

const PostCta = loadable(() => import("./marketing/postCta"));
const PromoSurveyDPP = loadable(() => import("./marketing/promoSurveyDPP"));

const Snippet = (props) => {
  const { children } = props;

  return (
    <>
      <div class="min-vh-100 d-flex flex-column" style={{ overflow: "hidden" }}>
        <NavMain />
        {children}
        <PromoSurveyDPP mode="short" containerClass="mt-3 mx-2 mx-lg-5" />
        <PostCta context="nft" containerClass="mx-2 mx-lg-5" />

        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="OK"
          // enableDeclineButton={true}
          // declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          buttonWrapperClasses="ms-2"
          buttonClasses="btn btn-sm btn-outline-success m-2 m-lg-0 me-lg-2"
          // declineButtonClasses="btn btn-outline-sky m-2 m-lg-0 me-lg-2"
          containerClasses="d-flex align-items-center justify-content-start bg-black-4 white-1 py-2 px-4 fw-light"
          disableButtonStyles={true}
          disableStyles={true}
          style={{
            left: 0,
            position: "fixed",
            width: "100%",
            zIndex: 999,
            bottom: 0,
          }}
        >
          This site uses cookies 🍪
        </CookieConsent>
      </div>
      {/* <Script
        src="/scripts/jquery.min.js"
        onLoad={() => setLoadedScript(true)}
      /> */}
      {/* {loadedScript && (
        <>
          <Script src="/scripts/popper.min.js" />
          <Script src="/scripts/bootstrap.min.js" />
        </>
      )} */}
    </>
  );
};

export default Snippet;
// export default connect(null, mapDispatchToProps)(Snippet);
