import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Gatsby
import { graphql } from "gatsby";

//Actions
import { initiateDppDemo } from "../../actions/initiateDppDemo";

//Components
import DemoPosition from "../purchase/demoPosition";
import Loading from "../loading";
import PrivacyCallout from "../marketing/privacyCallout";
import BlogCallout from "../marketing/blogCallout";

//Redux
const mapStateToProps = ({ isLoadingDemo, demoData }) => {
  return {
    isLoadingDemo,
    demoData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  initiateDppDemo: (userData) => {
    dispatch(initiateDppDemo(userData));
  },
});

const Snippet = ({
  data,
  currentPagePos,
  setCurrentPagePos,
  currentPageMode,
  setCurrentPageMode,
  campaignId,
  setCampaignId,
  isLoadingDemo,
  demoData,
  initiateDppDemo,
}) => {
  const [templateId, setTemplateId] = useState(
    "56b95669-bf36-4657-b96a-b463dd2b6b1c"
  );
  const [templateTitle, setTemplateTitle] = useState("Apparel");
  const [targetUrlsProduct, setTargetUrlsProduct] = useState([""]);
  const [targetUrls, setTargetUrls] = useState([]);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [productName, setProductName] = useState("");
  const [formError, setFormError] = useState({});

  const addUrlInput = (productPage) => {
    let revisedUrls;
    if (productPage) {
      revisedUrls = [...targetUrlsProduct, ""];
      setTargetUrlsProduct(revisedUrls);
    } else {
      revisedUrls = [...targetUrls, ""];
      setTargetUrls(revisedUrls);
    }
  };
  const removeUrlInput = (pos, productPage) => {
    let revisedUrls;
    if (productPage) {
      revisedUrls = [...targetUrlsProduct];
      revisedUrls = revisedUrls.filter((_, i) => i !== pos);
      setTargetUrlsProduct(revisedUrls);
    } else {
      revisedUrls = [...targetUrls];
      revisedUrls = revisedUrls.filter((_, i) => i !== pos);
      setTargetUrls(revisedUrls);
    }
  };
  const renderUrlInput = (urlItem, i, formError, productPage) => {
    return (
      <>
        <div
          class={`d-flex flex-row flex-md-row mb-2 justify-content-center align-items-center w-100`}
          key={`urlItem-${productPage ? "product-" : ""}${i}`}
        >
          <input
            type="text"
            class={`form-control form-control-lg my-2 w-100 ${
              (formError?.item === "targetUrls" && !productPage) ||
              (formError?.item === "targetUrlsProduct" && productPage)
                ? "border-2-red-1"
                : ""
            }`}
            placeholder={
              productPage
                ? `Enter product page URL`
                : `Enter sustainability page URL`
            }
            value={urlItem}
            onChange={(e) => {
              let revisedUrls;
              if (productPage) {
                revisedUrls = [...targetUrlsProduct];
                console.log("revisedUrlsProduct - 1");
                console.log(revisedUrls);

                revisedUrls[i] = e.target.value;
                console.log("revisedUrlsProduct - 2");
                console.log(revisedUrls);
                setTargetUrlsProduct(revisedUrls);
              } else {
                revisedUrls = [...targetUrls];
                console.log("revisedUrls - 1");
                console.log(revisedUrls);

                revisedUrls[i] = e.target.value;
                console.log("revisedUrls - 2");
                console.log(revisedUrls);
                setTargetUrls(revisedUrls);
              }
            }}
          />
          {!productPage ? (
            <button
              type="button"
              class="btn btn-slim mx-1 form-collection-link-delete"
              onClick={() => {
                removeUrlInput(i, productPage);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                class="red-1"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: -4 }}
              >
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
              </svg>
            </button>
          ) : (
            <a
              style={{ width: 45, height: 38 }}
              class="btn btn-slim mx-1 form-collection-link-delete"
            ></a>
          )}
        </div>
      </>
    );
  };

  const validateStep = (page) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (page === 1) {
      setFormError({});
      setCurrentPagePos(2);
    } else if (page === 2) {
      // if (!productName) {
      //   setFormError({
      //     item: "productName",
      //     msg: "Please enter a product name",
      //   });
      // } else
      if (targetUrlsProduct.length === 0) {
        setFormError({
          item: "targetUrlsContainer",
          msg: "Please enter a Web address for your product",
        });
      } else if (targetUrlsProduct.some((urlItem) => !urlItem)) {
        setFormError({
          item: "targetUrlsProduct",
          msg: "Please make sure all Web address fields have URLs",
        });
      } else if (
        targetUrlsProduct.some((urlItem) => {
          if (urlItem.includes("https://") || urlItem.includes("http://")) {
            return false;
          } else {
            return true;
          }
        })
      ) {
        setFormError({
          item: "targetUrlsProduct",
          msg: "Please make sure all Web address have either https:// or http:// in them",
        });
      } else if (targetUrls.some((urlItem) => !urlItem)) {
        setFormError({
          item: "targetUrls",
          msg: "Please make sure all Web address fields have URLs",
        });
      } else if (
        targetUrls.some((urlItem) => {
          if (urlItem.includes("https://") || urlItem.includes("http://")) {
            return false;
          } else {
            return true;
          }
        })
      ) {
        setFormError({
          item: "targetUrls",
          msg: "Please make sure all Web address have either https:// or http:// in them",
        });
      } else {
        setFormError({});
        setCurrentPagePos(3);
      }
    } else if (page === 3) {
      if (!userName) {
        setFormError({
          item: "userName",
          msg: "Please enter your name",
        });
      } else if (!userCompany) {
        setFormError({
          item: "userCompany",
          msg: "Please enter your company name",
        });
      } else if (!userEmail) {
        setFormError({
          item: "userEmail",
          msg: "Please enter your business email address",
        });
      } else if (!emailRegex.test(userEmail)) {
        setFormError({
          item: "userEmail",
          msg: "Please enter a valid business email address",
        });
      } else {
        setFormError({});
        // alert("submit");
        initiateDppDemo({
          userEmail,
          userName,
          userCompany,
          url: [...targetUrlsProduct, ...targetUrls],
          productName,
          templateId,
          campaignId,
          templateTitle,
        });
      }
    }
  };
  const renderTemplateButton = (template, mode) => {
    return (
      <>
        <div class="m-1">
          <button
            class={`btn-planner-product-template btn-planner-product-attribute rounded-corners p-2 small line-height-2 mr-1 d-flex flex-column align-items-center justify-content-center ${
              templateId === "56b95669-bf36-4657-b96a-b463dd2b6b1c" &&
              template?.title === templateTitle
                ? "btn-planner-product-attribute-selected"
                : templateId !== "56b95669-bf36-4657-b96a-b463dd2b6b1c" &&
                  template?.id === templateId
                ? "btn-planner-product-attribute-selected"
                : "border-1-gray-6 "
            }`}
            onClick={() => {
              setTemplateId(template.id);
              setTemplateTitle(template.title);
            }}
          >
            <div class="btn-planner-product-template-title text-center w-100 d-flex align-items-center justify-content-center">
              {mode === "general" ? "General" : template?.title}
            </div>
            {/* <div class=" w-100 d-flex flex-row align-items-end"></div> */}
          </button>
        </div>
      </>
    );
  };

  console.log("-------data?.industries?.edges");
  console.log(data?.industries?.edges);
  const otherCat = data?.industries?.edges.find(
    (edge) =>
      edge.node.pageContext.id === "56b95669-bf36-4657-b96a-b463dd2b6b1c"
  );
  console.log("-------otherCat");
  console.log(otherCat);
  const industryData = [
    ...data?.industries?.edges,
    {
      ...otherCat,
      node: {
        ...otherCat.node,
        pageContext: {
          ...otherCat.node.pageContext,
          title: "Other",
        },
      },
    },
  ];
  console.log("-------industryData");
  console.log(industryData);

  return (
    <>
      <div class="row my-0">
        <div class="col-12 col-lg-8 offset-lg-2 ">
          {demoData?.success ? (
            <>
              <div class="display-4 mt-4 p-4 dkgreen-1 text-center font-weight-light border-1-dkgreen-1 rounded-corners">
                Success! We're creating your Digital Product Passport and will
                e-mail it to you{userEmail ? ` at ${userEmail}` : ""}.
              </div>
              <BlogCallout
                context="demopost"
                title="Learn more about Digital Product Passports"
                desc=""
                showMoreButton={false}
                containerClass={`gradient-callout-silver rounded-corners`}
                headerClass={`h3`}
              />
            </>
          ) : (
            <>
              <div class="demo-subhead-mode">Create DPP with AI</div>
              <p class="demo-subhead-desc">
                Provide details about your product and get a free, personalized
                Digital Product Passport sent to you, instantly using AI
              </p>
              <DemoPosition
                posNumber={currentPagePos}
                updatePosNumber={setCurrentPagePos}
              />
            </>
          )}
          {(formError?.msg || demoData?.error) && (
            <div class="mt-4 p-2 red-1 small text-center font-weight-light border-1-red-1 rounded-corners">
              {formError.msg || demoData?.message}
            </div>
          )}
        </div>
      </div>

      {currentPagePos === 1 && !demoData?.success && (
        <div class="row my-4">
          <div class="col-12 col-lg-8 offset-lg-2 ">
            <h2 class="my-2 px-2 text-center">Select your industry</h2>
            <p class="lead px-2 mt-3 black-5 line-height-3 text-center">
              We'll use a DPP template that aligns with your sector
              {/* <br />
        If yours isn't below, select General. */}
            </p>
            <div class="planner-product-attribute-container d-flex flex-row flex-wrap align-items-center justify-content-center my-2">
              {industryData?.map((industry) => (
                <>{renderTemplateButton(industry?.node?.pageContext)}</>
              ))}
            </div>
          </div>
        </div>
      )}
      {currentPagePos === 2 && !demoData?.success && (
        <div class="row my-4">
          <div class="col-12 col-lg-8 offset-lg-2" style={{ paddingLeft: 50 }}>
            <h2 class="my-2 px-2 text-center" style={{ marginRight: 30 }}>
              Add product info
            </h2>
            {/* <p
        class="lead px-2 mb-0 mt-4 black-5 line-height-3 text-center"
        style={{ marginRight: 50 }}
      >
        Enter your product's name
      </p>
      <div class="planner-product-attribute-container d-flex flex-row align-items-center justify-content-center my-2">
        <input
          type="text"
          class={`form-control form-control-lg my-2 ${
            formError?.item === "productName" && "border-2-red-1"
          }`}
          placeholder="Product name"
          value={productName}
          onChange={(e) => {
            setProductName(e.target.value);
          }}
        />
        <a
          style={{ width: 45, height: 38 }}
          class="btn btn-slim mx-1 form-collection-link-delete"
        ></a>
      </div> */}
            <p
              class="lead px-2 mb-0 mt-4 black-5 line-height-3 text-center"
              style={{ marginRight: 50 }}
            >
              Enter the Web page for your product
            </p>
            {targetUrlsProduct.map((urlItem, i) => (
              <>{renderUrlInput(urlItem, i, formError, true)}</>
            ))}

            <p
              class="lead px-2 mb-0 mt-4 black-5 line-height-3 text-center"
              style={{ marginRight: 50 }}
            >
              Add Web page(s) with sustainability information about your product
              (optional)
            </p>
            <div
              class={`planner-product-attribute-container d-flex flex-row flex-wrap align-items-center justify-content-center my-2 ${
                formError?.item === "targetUrlsContainer" && "border-2-red-1"
              }`}
            >
              {targetUrls.map((urlItem, i) => (
                <>{renderUrlInput(urlItem, i, formError)}</>
              ))}
              <div class="d-flex flex-column align-items-center justify-content-start">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-info mt-2 px-4"
                  disabled={targetUrls.length >= 2}
                  onClick={() => {
                    addUrlInput(false);
                  }}
                >
                  Add URL
                </button>
                {targetUrls.length >= 3 && (
                  <div class="sky-1 small my-3">
                    You've reached the limit of Web addresses
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {currentPagePos === 3 && !demoData?.success && (
        <div class="row my-4">
          <div class="col-12 col-lg-8 offset-lg-2 ">
            <h2 class="my-2 p-2 text-center">
              Where should we send your completed DPP?
            </h2>
            <div class="planner-product-attribute-container d-flex flex-column align-items-center justify-content-center my-2">
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userName" && "border-2-red-1"
                }`}
                placeholder="Your name"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userCompany" && "border-2-red-1"
                }`}
                placeholder="Company name"
                value={userCompany}
                onChange={(e) => {
                  setUserCompany(e.target.value);
                }}
              />
              <input
                type="text"
                class={`form-control form-control-lg my-2 w-100 ${
                  formError?.item === "userEmail" && "border-2-red-1"
                }`}
                placeholder="Business email"
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!demoData?.success && <hr size={1} />}

      <div class="d-flex flex-row align-items-center justify-content-center">
        {currentPagePos === 1 && !demoData?.success && (
          <button
            class="btn btn-lg btn-link btn-link-demo-back mx-2 gray-1"
            onClick={() => {
              setCurrentPageMode("");
            }}
          >
            Back
          </button>
        )}
        {currentPagePos > 1 && !demoData?.success && (
          <button
            class="btn btn-lg btn-link btn-link-demo-back mx-2 gray-1"
            onClick={() => {
              setCurrentPagePos(currentPagePos - 1);
              setFormError({});
            }}
          >
            Back
          </button>
        )}
        {currentPagePos === 3 && !demoData?.success ? (
          <>
            <button
              class="btn btn-lg btn-primary mx-2"
              onClick={() => {
                validateStep(currentPagePos);
              }}
            >
              Create DPP
            </button>
          </>
        ) : !demoData?.success ? (
          <button
            class="btn btn-lg btn-sky mx-2"
            onClick={() => {
              // setCurrentPagePos(currentPagePos + 1);
              validateStep(currentPagePos);
            }}
          >
            Next &rsaquo;
          </button>
        ) : null}
        {isLoadingDemo ? (
          <div class="px-2 mx-1 d-flex align-items-center justify-content-center">
            <Loading class="small" />
          </div>
        ) : null}
      </div>

      {currentPagePos === 3 && !demoData?.success && <PrivacyCallout />}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
