//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Actions
//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";
import PostTemplateCtaStatic from "../../components/marketing/postTemplateCtaStatic";

//Icons

const About = (props) => (
  <Layout>
    <div class=" gradient-callout-tropical py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Learn about how public ledgers have modernized for
                sustainability
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h1 class="fw-light black-1 display-2">
              Blockchain sustainability
            </h1>

            <p class="lead">
              Public ledgers have reduced electricity consumption and carbon
              emissions in recent years, making them a sustainable option for
              marketing programs and customer communication
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container container-page mt-0 mb-4 pt-4 pb-2">
        <div class="row">
          <div class="col-8 offset-2 d-flex flex-column justify-content-center text-center text-lg-center">
            <StaticImage
              src="../../images/stock/unsplash/shubham-s-web3-MBfYGVsDEp8-unsplash.jpg"
              alt="PicoNext"
              loading="lazy"
              placeholder="blurred"
              layout="constrained"
              width={1200}
              height={200}
              // height="200"
              transformOptions={{ cropFocus: "center" }}
              quality="100"
              // style={{ position: `absolute` }}
              style={{
                // position: `absolute`,
                // // top: 0,
                // // left: 0,
                // // right: 0,
                // // bottom: 0,
                // inset: 'auto',
                width: "100%",
                // height: 200,
                // zIndex: 0,
              }}
            />
            <h3 class="fw-light black-1 display-3 mt-3 pt-3">
              Modernized sustainability
            </h3>
            <p class="lead text-start">
              Public ledgers have modernized their own sustainability in recent
              years, with new technology changes in how they confirm
              transactions resolving concerns about resource usage and
              electricity consumption.
            </p>
            <h4 class="fw-light text-start black-1 mt-3">
              Reduction in electricity consumption &amp; carbon emissions by
              99.99%+
            </h4>
            <p class="lead text-start">
              The result of the new consensus mechanism by which these networks
              use to confirm their transactions (called proof-of-stake) is a
              reduction in annualized electricity consumption by more than
              99.988% by the blockchain network, and a reduction of its carbon
              footprint by approximately 99.992%, according to Ethereum.org.
            </p>
            <p class="lead text-start">
              Further, the Polygon network (an Ethereum-based network) is
              eliminating all of the network’s carbon debt back to inception,
              and then going carbon negative in its operations from 2022 on.
            </p>
            <hr size={1} />

            <StaticImage
              src="../../images/blog/20230411-energy-consumption-comparison.png"
              alt="PicoNext"
              loading="lazy"
              placeholder="blurred"
              layout="fullWidth"
              // width={800}
              // height={400}
              // style={{ position: `absolute` }}
              // style={{
              //   position: `absolute`,
              //   // top: 0,
              //   // left: 0,
              //   // right: 0,
              //   // bottom: 0,
              //   inset: 'auto',
              //   width: '100%',
              //   height: 400,
              //   zIndex: 0,
              // }}
            />
            <hr size={1} />
            <h4 class="fw-light text-start black-1 mt-3">
              Change in transaction confirmation mechanism
            </h4>
            <p class="lead text-start">
              In September 2022, one of the primary blockchain networks,
              Ethereum, migrated from proof-of-work consensus validation to a
              more eco-sustainable proof-of-stake methodology. Previously, under
              the proof-of-work paradigm, blockchain verifiers had to solve
              brute-force, energy-intensive, cryptographic puzzles to confirm a
              blockchain transaction.
            </p>
            <p class="lead text-start">
              This brute-force work resulted in electricity consumption. Now,
              under proof-of-stake, fewer validators are involved with less
              energy consumption because those verifiers pledge a quantity of
              cryptocurrency as an economic stake in system.
            </p>
            <hr size={1} />

            <div class="gray-5 small text-start">
              Sources: “Ethereum’s energy expenditure”,{" "}
              <a
                href="https://ethereum.org/en/energy-consumption/"
                target="_blank"
                rel="noreferrer"
              >
                Ethereum.org
              </a>
              ; "How much electricity is used for cooling in the United States?"{" "}
              <a
                href="https://www.eia.gov/tools/faqs/faq.php?id=1174&t=1"
                target="_blank"
                rel="noreferrer"
              >
                U.S. Energy Information Administration
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr size={1} />
    <div class="mx-3 mx-lg-5">
      <div class="my-2 d-flex flex-column ">
        <PostTemplateCtaStatic
          title="Learn more about the PicoNext Sustainability Transparency solution"
          link="/solutions/web3-climate-sustainability/"
          cta="Learn more"
          gradient="seatosky"
        />
      </div>
    </div>
    <hr size={1} />

    {/* <CalloutBrandExampleDatabase /> */}

    {/* <div class="my-3">
      <FeatureQuad featureData={featureData} />
    </div> */}
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Blockchain Sustainability"
      description="Learn about the sustainability of public ledgers for Digital Product Passport and marketing   programs"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "stock/unsplash/shubham-s-web3-MBfYGVsDEp8-unsplash.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
