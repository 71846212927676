//React
import React from "react";

//Gatsby

//Icons

const Snippet = ({ containerClass }) => (
  <div
    class={`dpp-invite-freetier mb-4 rounded-corners border-1-gray-6 d-flex flex-column ${containerClass}`}
  >
    <div class="p-2 gradient-callout-warm fw-bold rounded-corners white-1 text-uppercase">
      Free DPP Starter Plan
    </div>
    <div class="d-flex flex-column align-items-start px-4 py-2">
      <div class="mt-2">
        The free PicoNext DPP Starter Plan includes the following:
      </div>
      <ul class="text-start fw-light">
        <li>3 products</li>
        <li>
          DPP Planner access:
          <ul>
            <li>Pre-built DPP templates</li>
            <li>Sustainability attribute library</li>
            <li>Published date support</li>
            <li>Text-based input</li>
          </ul>
        </li>
        <li>Cloud-based token publishing</li>
        <li>Token media support for images</li>
        <li>
          DPP Data Events:
          <ul>
            <li>5 per token</li>
            <li>1000 characters each</li>
            <li>Text-based input</li>
          </ul>
        </li>
        <li>QR code downloads</li>
        <li>Support articles &amp; getting started guides</li>
      </ul>
    </div>
  </div>
);
export default Snippet;
