//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

//Assets
import Logo from "../../images/svg/piconext-logo-04.svg";

const Nav = () => (
  <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-minimal">
    <div class="container">
      <div class="row w-100">
        <div class="col-12">
          <div class="w-100 d-flex flex-row align-items-center justify-content-start">
            <Link
              class="navbar-brand"
              aria-label="PicoNext Home"
              activeClassName="navbar-brand"
              to="/"
            >
              <Logo />
            </Link>
            <Link
              class="btn btn-link btn-morearticles-link ml-3"
              to="/resources"
            >
              View all articles
            </Link>
            <Link
              to="/demo/video/"
              class="demo-link ms-auto"
              activeClassName="demo-link active"
            >
              <div class="btn btn-primary btn-cta">See demo</div>
            </Link>
            {/* <div class="collapse navbar-collapse navbar-main" id="navbarText" /> */}
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default Nav;
