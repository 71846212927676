//React
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Gatsby
import { useStaticQuery, graphql, Link } from "gatsby";

import moment from "moment";

// import { FaExternalLinkAlt } from "react-icons/fa";

const uppercaseString = (string) => {
  return string[0].toUpperCase() + string.substring(1);
};

const Snippet = () => {
  const data = useStaticQuery(graphql`
    query {
      allAirtable(sort: { order: DESC, fields: data___Date }) {
        edges {
          node {
            data {
              Blockchain
              Brand
              Category
              Description
              Imagename
              Image {
                url
                thumbnails {
                  large {
                    url
                  }
                  small {
                    url
                  }
                }
              }
              Industry
              Link
              Date
              id
            }
          }
        }
      }
    }
  `);
  console.log("dppExamplesBlog data--");
  console.log(data);

  return (
    <>
      <h4 class="mt-4 mb-2">
        {data && data.allAirtable && data.allAirtable.edges
          ? data && data.allAirtable && data.allAirtable.edges.length
          : ""}{" "}
        Examples of Brand Marketing NFTs
      </h4>
      <hr size={1} class="bg-gray-4" />

      <div class="container">
        {/* {JSON.stringify(data.allAirtable.edges)} */}
        {data && data.allAirtable && data.allAirtable.edges
          ? data.allAirtable.edges.map((nft, i) => (
              <div class={`row mb-4 ${i % 2 !== 0 ? "bg-gray-7" : ""}`}>
                <div class="col-md-4 p-0 d-flex align-items-center justify-content-center">
                  {nft &&
                  nft.node &&
                  nft.node.data &&
                  nft.node.data.Imagename ? (
                    // <div>{`/blog/nft-example-logos/${nft.node.data.Imagename}`}</div>
                    <img
                      width="200"
                      src={`/blog/nft-example-logos/${nft.node.data.Imagename}`}
                      // alt={`${nft.node.data.Brand} NFT for brand marketing`}
                    />
                  ) : (
                    ""
                  )}
                  {/* {nft &&
                      nft.node &&
                      nft.node.data &&
                      nft.node.data.Image &&
                      nft.node.data.Image[0] &&
                      nft.node.data.Image[0].thumbnails &&
                      nft.node.data.Image[0].thumbnails.large &&
                      nft.node.data.Image[0].thumbnails.large.url ? (
                        <img
                          width="200"
                          src={nft.node.data.Image[0].thumbnails.large.url}
                          alt={`${nft.node.data.Brand} NFT for brand marketing`}
                        />
                      ) : (
                        ''
                      )} */}
                </div>
                <div class="col-md-8 pb-2 text-center text-md-start d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start">
                  {nft && nft.node && nft.node.data && nft.node.data.Brand ? (
                    <>
                      {nft.node.data.Date ? (
                        <div class="small gray-1 mt-2">
                          {moment(nft.node.data.Date).format("MMMM D, YYYY")}
                        </div>
                      ) : (
                        ""
                      )}
                      <h5 class="listing-title">
                        <div class="me-1 listing-title-brand">
                          {nft.node.data.Brand}
                        </div>
                        {nft.node.data.Industry ? (
                          <span class="mx-1 badge badge-outline-dkorange rounded-corners fw-light">
                            {nft.node.data.Industry}
                          </span>
                        ) : (
                          ""
                        )}
                        {nft.node.data.Category &&
                        nft.node.data.Category.length > 0
                          ? nft.node.data.Category.map((category) => (
                              <span class="mx-1 badge badge-outline-green rounded-corners fw-light">
                                {uppercaseString(category)}
                              </span>
                            ))
                          : ""}
                        {nft.node.data.Blockchain ? (
                          <span class="mx-1 badge badge-outline-sky rounded-corners fw-light">
                            {nft.node.data.Blockchain}
                          </span>
                        ) : (
                          ""
                        )}
                      </h5>
                      {nft.node.data.Description ? (
                        <p class="fw-light line-height-2 mb-3">
                          {nft.node.data.Description}
                        </p>
                      ) : (
                        ""
                      )}
                      {/* {nft.node.data.Link ? (
                        <a
                          class="btn btn-sm btn-outline-primary"
                          rel="nofollow noreferrer"
                          href={nft.node.data.Link}
                          target="_blank"
                        >
                          More info{" "}
                        </a>
                      ) : (
                        ""
                      )} */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          : null}
      </div>
      <hr size={1} class="bg-gray-4" />
    </>
  );
};
export default Snippet;
