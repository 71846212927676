//React
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Gatsby
import { Link } from "gatsby";

//Actions
import { activateUserFreetier } from "../../actions/activateUser";

//Packages
import parse from "url-parse";

//Components
import Layout from "../../components/layoutLanding";
import Loading from "../../components/loading";
import PurchaseSuccess from "../../components/purchase/purchaseSuccess";

//Icons

//Redux
const mapStateToProps = ({
  isLoading,
  userActivationDetails,
  userActivationErrorDetails,
}) => {
  return {
    isLoading,
    userActivationDetails,
    userActivationErrorDetails,
  };
};
const mapDispatchToProps = (dispatch) => ({
  activateUserFreetier: (dc, id) => {
    dispatch(activateUserFreetier(dc, id));
  },
});

const Snippet = (props) => {
  const {
    activateUserFreetier,
    isLoading,
    userActivationDetails,
    userActivationErrorDetails,
  } = props;

  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    const url = parse(window.location.href, true);

    console.log("activate - url");
    console.log(url);
    if (url.pathname) {
      const split = url.pathname.split("/");
      console.log("activate - split");
      console.log(split);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activateUserFreetier(split[3], split[4]);
    }
  }, []);

  return (
    <Layout>
      <div class="container container-purchase">
        <div class="row">
          <div class="col-md-12">
            {userActivationErrorDetails &&
            userActivationErrorDetails.data &&
            userActivationErrorDetails.data.message ? (
              <div class="mt-3 border-1-red-1 red-1 mb-2 p-2 rounded-corners">
                {userActivationErrorDetails.data.message}
              </div>
            ) : null}
            <h1 class="mt-4 h2">Activate PicoNext DPP Starter Plan Account</h1>
            {isLoading ? (
              <div class="w-100 p-4">
                <Loading class="small d-flex align-self-center" />
              </div>
            ) : userActivationDetails && userActivationDetails.success ? (
              // <div class="border-1-green-1 green-1 mb-2 p-2 rounded-corners">
              //   Success! You've activated your PicoNext account. We've sent a
              //   copy of the agreement to your email.
              // </div>
              <PurchaseSuccess
                emailInput={userActivationDetails.email}
                provisionedUsersNumber={1}
                outboundReferralCode={""}
                copiedToClipboard={copiedToClipboard}
                setCopiedToClipboard={setCopiedToClipboard}
              />
            ) : (
              <>
                <p>
                  To activate your account, please tap on the link provided to
                  you.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
