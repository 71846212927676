//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import BlogCallout from "../../components/marketing/blogCallout";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Memorable experiences",
    contextColor: "yellow-1",
    title: "Drive loyalty with offers & experiences",
    description:
      "Provide value-added access to unique local experiences (either yours or a partner's) like admission to a special event, discount at a restaurant, or special amenity at a hotel",
    image: "localAccessPass1",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Activity access",
    contextColor: "pink-1",
    title: "Grant access to value-add benefits",
    description:
      "Let customers access benefits of your local access pass simply by scanning a QR code with any smartphone - no special apps or downloads required",
    image: "localAccessPass2",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "pink",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Drive customer loyalty",
    contextColor: "sky-1",
    title: "Differentiate your brand offering",
    description:
      "Showcase your brand differentiation by providing customers access to unique, local experiences and benefits not found anywhere else",
    image: "localAccessPass3",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },

  {
    context: "Programmatic value",
    contextColor: "green-1",
    title: "Unlock value over time",
    description:
      "Keep your local access pass fresh by pushing new rewards and benefits to it over time, enabling additional experiences for - and connection with - customers",
    image: "localAccessPass4",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'gray-5',
    anchor: "mobile",
    // videosource: 'console-3',
  },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: true,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-greensky py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Unlock local experiences with tokens to drive brand
                  differentiation and customer loyalty
                  {/* Rally your community and raise funds for social causes */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Local Access Pass</h1>

              <p class="lead">
                Increase loyalty and drive incremental revenue by providing
                customers access to curated local offers & experiences
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />

      <BlogCallout
        context="loyalty"
        title="Loyalty Resources"
        desc="Articles to help you engage customers using tokenized loyalty
        programs"
        showMoreButton={true}
      />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Local Access Pass"
      description="Unlock local experiences to drive brand differentiation and customer loyalty"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/accesspass-wallet-rewardlist-short-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    blogs: allMdx(
      sort: { frontmatter: { date: DESC } }
      filter: {
        frontmatter: { path: { glob: "/article/*" }, publish: { eq: 1 } }
        id: {
          in: [
            "b491e71f-12da-596d-8319-416ac261bb91"
            "3a8c6ace-c218-5b22-98a5-b61e3ae42888"
          ]
        }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
