//React
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Actions
import { saveSubscribe } from "../../actions/saveSubscribe";

//Packages
import parse from "url-parse";

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";

import Loading from "../../components/loading";
import SignalIcon from "../../images/svg/newsletter/piconext-prism-01.svg";

//Icons

//Redux
const mapStateToProps = ({
  subSubmitted,
  subSubmittedData,
  isLoading,
  isSubscribeLoading,
}) => {
  return { subSubmitted, subSubmittedData, isLoading, isSubscribeLoading };
};
const mapDispatchToProps = (dispatch) => ({
  saveSubscribe: (email, mode, subscription, access, dc) => {
    dispatch(saveSubscribe(email, mode, subscription, access, dc));
  },
});

const Snippet = (props) => {
  const { saveSubscribe, subSubmittedData, isSubscribeLoading } = props;
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [pageMode, setPageMode] = useState("confirm");

  useEffect(() => {
    const url = parse(window.location.href, true);

    if (url && url.query && url.query.m && url.query.m === "unsub") {
      console.log("url.query.m");
      console.log(url.query.m);
      setPageMode("unsub");
    }
    if (url.query.i && url.query.a && url.query.s && url.query.d) {
      console.log("url.query.i");
      console.log(url.query.i);
      saveSubscribe(
        url.query.i, //email
        url.query.m ? url.query.m : "confirm", //mode
        url.query.s, //subscription name
        url.query.a, //access key
        url.query.d //datacenter
      );
    }
  }, []);

  // saveSubscribe(email) {
  //   this.props.saveSubscribe(email)
  // }

  // handleUpdate = (event) => {
  //   if (event.target.type === 'checkbox') {
  //     this.setState({
  //       [event.target.name]: event.target.checked,
  //     })
  //   } else {
  //     if (event.target.name === 'unsubEmail') {
  //       this.setState({
  //         [event.target.name]: encodeURIComponent(event.target.value),
  //       })
  //     } else {
  //       this.setState({
  //         [event.target.name]: event.target.value,
  //       })
  //     }
  //   }
  // }

  // handleSubmit() {
  //   if (!this.state.unsubEmail) {
  //     this.setState({ errorText: 'Please enter your e-mail address' })
  //     return
  //   } else {
  //     this.sendUnsub(this.state.unsubEmail)
  //   }
  // }

  return (
    <Layout>
      {/* <Helmet>
          <script
            src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
            async
            defer
          ></script>
        </Helmet> */}
      <div class="container container-unsub flex-grow-1">
        <div class="row">
          <div class="col-lg-12">
            {/* <div class="sub-header gradient-callout-eaglequarter p-3 mb-3 round-corners  w-100">
                <SignalIcon />
              </div> */}
            {subSubmittedData && subSubmittedData.success ? (
              <>
                <h2>Success</h2>
                <p class="lead">
                  {pageMode === "unsub"
                    ? "We've submitted your unsubscription request. We're sorry to see you go!"
                    : "We've confirmed your subscription"}
                </p>
              </>
            ) : subSubmittedData && subSubmittedData.error ? (
              <>
                <h2>
                  Confirm {pageMode === "unsub" ? "Request" : "Subscription"}
                </h2>
                <p class="lead">
                  {pageMode === "unsub"
                    ? "We weren't able to process your request. Please contact support@piconext.com for help"
                    : "We weren't able to confirm your subscription."}
                </p>
              </>
            ) : isSubscribeLoading ? (
              <>
                <h2>
                  Confirm {pageMode === "unsub" ? "Request" : "Subscription"}
                </h2>
                <div class="d-flex align-items-start justify-content-start m-4 p-4">
                  <Loading class="small" />
                </div>
              </>
            ) : (
              <>
                <h2>
                  Confirm {pageMode === "unsub" ? "Request" : "Subscription"}
                </h2>
                <div class="d-flex align-items-start justify-content-start">
                  <p>
                    We weren't able to confirm your request. Please email
                    support@piconext.com for assistance
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Snippet);

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Manage Subscription"
      description="Manage your email subscription with PicoNext"
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};
