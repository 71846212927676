//React
import React from "react";

//Gatsby
import { Link, graphql } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import IndustryImages from "../../components/marketing/industryImages";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-tropical">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-start">
              <h1 class="display-2 fw-medium white-1">Industries</h1>
              <p class="display-4 white-4">
                Discover how you can increase customer trust and comply with new
                regulations using Digital Product Passports for your industry
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container container-page">
        <div class="row my-4">
          {props?.data?.industries?.edges?.map((industry) => (
            <div class="col-lg-6">
              <div class="h-100 d-flex flex-row align-items-center justify-content-start bg-gray-7 border-2-white-1 p-3 rounded-corners">
                <div class="p-2 w-75">
                  <h4 class="text-start">
                    {industry?.node?.pageContext?.title}
                  </h4>
                  <p class="text-start fw-light line-height-2">
                    {industry?.node?.pageContext?.data?.node?.mkt?.desc}
                  </p>
                  <Link
                    to={industry?.node?.path}
                    class="btn btn-primary btn-cta mb-1 "
                    activeClassName="btn btn-primary btn-cta"
                  >
                    More &rarr;
                    {/* <FaArrowRight class="btn-arrow" /> */}
                  </Link>
                </div>
                <IndustryImages
                  industry={industry?.node?.pageContext?.title
                    ?.toLowerCase()
                    ?.replaceAll(" ", "")}
                  size=""
                  output="image"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport - Industries"
      description="See industries expected to require Digital Product Passports, including fashion, clothing, textiles, and more"
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    industries: allSitePage(
      filter: { path: { glob: "/(industry)/*" } }
      sort: { path: ASC }
    ) {
      edges {
        node {
          path
          pageContext
        }
      }
    }
  }
`;
