//React
import React from "react";

//Gatsby

//Components
import PostSidebarCalloutFeatureLogo from "./postSidebarCalloutFeatureLogo";

//Icons

const Snippet = ({ title, content, logo, containerClass }) => {
  let logoImageSource = `../../images/customers/${logo}/${logo}-logo.png`;

  console.log("logoImageSource");
  console.log(logoImageSource);
  return (
    <div
      class={`card card-callout card-callout-border ${
        containerClass ? containerClass : ""
      }`}
    >
      <div class="card-body d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
        <div>
          <PostSidebarCalloutFeatureLogo title={title} logo={logo} />
          {/* <Logo /> */}
        </div>
        <hr />
        <div class="mt-1 callout-name">{title}</div>
        {content && Array.isArray(content) ? (
          content.map((item) => <p>{item}</p>)
        ) : typeof content === "string" ? (
          <div>{content}</div>
        ) : null}
      </div>
    </div>
  );
};
export default Snippet;
