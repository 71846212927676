//React
import React from "react";
import { Link } from "gatsby";
// import { FaArrowRight } from "react-icons/fa";

const Snippet = (props) => {
  const { section, article, title, color } = props;

  let gradientColor;
  switch (color) {
    case "gray":
      gradientColor = "slate";
      break;
    case "dkorange":
      gradientColor = "fire";
      break;
    case "purple":
      gradientColor = "tropical";
      break;
    case "pink":
      gradientColor = "sunset";
      break;
    case "dkblue":
      gradientColor = "cousteau";
      break;
    case "blue":
      gradientColor = "bluesky";
      break;
    case "yellow":
      gradientColor = "warm";
      break;
    case "bluegreen":
      gradientColor = "deepsea";
      break;
    case "dkgreen":
      gradientColor = "keywest";
      break;
    case "seafoam":
      gradientColor = "seatosky";
      break;
    default:
      gradientColor = "cumulus";
  }
  return (
    <>
      <div class="mt-1 mb-2 mx-2 mx-lg-5">
        <div
          class={`post-template-link my-3 px-3 py-4 rounded-corners gradient-callout-${gradientColor} d-flex flex-column`}
        >
          <h4 class={`white-1 fw-normal text-center`}>
            Support and help for {title}
          </h4>
          <p class="fw-light white-1 line-height-3 text-center">
            Get started quickly with support articles, tips, and professional
            services for {title}
          </p>
          <div class="d-flex flex-row align-items-center justify-content-center">
            {section && (
              <Link
                to={`${process.env.SUPPORT_DOMAIN}${
                  section && section === "all"
                    ? ""
                    : section
                    ? `section/${section}`
                    : ""
                }`}
                class={`btn btn-primary btn-cta-outline mb-1`}
                activeClassName={`btn btn-primary btn-cta-outline`}
              >
                See articles &rarr;
                {/* <FaArrowRight />{" "} */}
              </Link>
            )}
            {article && (
              <Link
                to={`${process.env.SUPPORT_DOMAIN}${
                  article ? `article/${article}` : ""
                }`}
                class={`btn btn-primary btn-cta-outline mb-1`}
                activeClassName={`btn btn-primary btn-cta-outline`}
              >
                See article &rarr;
                {/* <FaArrowRight />{" "} */}
              </Link>
            )}
          </div>
        </div>
      </div>
      <hr size={1} />
    </>
  );
};
export default Snippet;
