//React
import React, { useEffect, useState } from "react";

//Gatsby
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../components/layout";
import { Metatags } from "../components/marketing/metatags";
// // import { SEO } from "../components/marketing/SEO";

import FeaturePreviewSectionPlatform from "../components/marketing/featurePreviewSectionPlatform";
// import PromoSurveyDPP from "../components/marketing/promoSurveyDPP";
import BlogCallout from "../components/marketing/blogCallout";

// //Icons
// import { FaArrowRight } from "react-icons/fa";

const Snippet = (props) => {
  console.log("props");
  console.log(props);

  const [allowVideo, setAllowVideo] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setAllowVideo(true);
  }, []);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Layout>
      <div class="callout-home d-flex align-items-center justify-content-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-9 d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start">
              <h1 class="text-center text-md-start home-title">
                {/* Web3 for brands */}
                {/* Attract &amp; engage new customers */}
                Deliver Digital Product Passports using AI
              </h1>
              <p class="lead text-center text-md-start display-4 white-1">
                {/* Attract customers &amp; protect your brand with a Web3 platform
                for experience companies */}
                {/* Attract customers &amp; drive loyalty with a Web3 platform for
                experience brands */}
                {/* Attract &amp; engage customers with a Web3 platform for
                experience brands */}
                {/* Use public ledgers to engage customers with sustainability,
                loyalty &amp; brand experiences */}
                Drive sustainability transparency with DPPs &mdash; using AI,
                blockchains, and the cloud
              </p>
              <Link
                to="/demo/video/"
                class="btn btn-primary btn-cta-outline mt-4"
              >
                <div>
                  See demo &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </div>
              </Link>
              {/* <Link
              to="/start/?c=sd-trial--home"
              class="btn btn-primary btn-cta-outline mt-4"
            >
              <div>
                Start free trial <FaArrowRight class="btn-arrow" />
              </div>
            </Link> */}
            </div>
          </div>
        </div>
        <div class="overlay"></div>
        {allowVideo && (
          <>
            <video
              muted
              loop
              autoPlay
              playsInline
              onCanPlayThrough={() => {
                videoLoaded();
              }}
              className={`${isVideoLoaded ? "video-show" : "video-hide"}`}
              style={{ objectFit: "cover" }}
            >
              <source src="/video/shoe-hero8B.mp4" type="video/mp4" />
            </video>
            <StaticImage
              style={{ position: "absolute" }}
              className={`${isVideoLoaded ? "video-hide" : "video-show"}`}
              src="../images/featurevideo/shoe-hero-poster.jpg"
              width={"100%"}
            />
          </>
        )}
      </div>

      <div class="callout-home-overview">
        <div class="container">
          <div class="row">
            <div class="callout-home-overview-text col-md-7 offset-md-1 rounded-corners text-center my-4 p-2 py-4 d-flex flex-column align-items-center justify-content-center">
              <h2 class="text-center fw-bold mt-4 black-1">
                {/* Authenticate content with blockchains */}
                {/* Increase trust in sustainability strategies */}
                Prepare Digital Product Passports with AI
              </h2>
              <p class="lead text-center display-4 black-5">
                {/* Reach new Gen Z and Millennial customers with NFTs that capture
                experience, build loyalty, and create inclusive community */}
                {/* Protect your brand's reputation from deepfake content and
                AI-generated misinformation */}
                {/* Drive forward corporate social responsibility initiatives around
                climate mitigation using public ledgers */}
                {/* Deliver sustainability transparency, avoid greenwashing, and
                comply with new regulations */}
                Increase customer trust, avoid greenwashing, and comply with new
                regulations
              </p>

              {/* <FeaturePreviewSectionPlatform short={true} /> */}

              {/* <Link
                to="/solutions/authentication-ai-generated-content/"
                class="btn btn-primary btn-cta-outline mt-3"
              >
                See content authentication solutions{' '}
                <FaArrowRight class="btn-arrow" />
              </Link> */}

              <div class="d-flex flex-row">
                <Link
                  // to="/solutions/"
                  to="/solutions/digital-product-passport/"
                  class="btn btn-primary btn-cta mt-3 me-2 mb-2"
                >
                  See solutions &rarr;
                  {/* <FaArrowRight class="btn-arrow d-none d-md-inline-block" /> */}
                </Link>
                <Link
                  // to="/solutions/"
                  to="/article/digital-product-passport/"
                  class="btn btn-primary btn-cta mt-3 me-2 mb-2"
                >
                  Learn how to prepare &rarr;
                  {/* <FaArrowRight class="btn-arrow d-none d-md-inline-block" /> */}
                </Link>
              </div>
            </div>
            <div
              class="col-md-4 mt-3 p-2 py-4 d-flex align-items-start justify-content-center"
              // style={{ minHeight: 640 }}
            >
              <StaticImage
                src="../images/featureperspective/passport-usecases/fashionnetwork-passport-shoe-L.png"
                // src="../images/featureperspective/resortsplus-usecases/resortsplus-carbon-capture-generic-L.jpg"
                alt="PicoNext"
                loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={250}
                height={380}
                // aspectRatio={4 / 3}
                style={{ zIndex: 1 }}
                transformOptions={{ fit: "cover", cropFocus: "north" }}
              />
            </div>
          </div>
        </div>
      </div>

      <BlogCallout
        context="homecustomers"
        title="Customers"
        desc="See how customers are using PicoNext DPP solutions"
        containerClass="gradient-callout-cousteau-reverse"
        textTheme="light"
        showMoreButton={false}
      />

      <div class="col-md-12 mt-4 mb-1 pt-2 pb-4">
        <h2 class="text-center fw-bold mt-4">PicoNext DPP Platform</h2>

        <p class="lead text-center display-4 black-5 px-lg-5">
          {/* Find new customers and protect your brand using Web3 &mdash; all with
          an end-to-end, no-code platform */}
          Engage customers with Digital Product Passports, using an end-to-end,
          no-code platform
        </p>
        <FeaturePreviewSectionPlatform short={true} />

        <div class="d-flex align-items-center justify-content-center mb-3">
          <Link
            to="/platform/"
            class="btn btn-primary btn-cta mb-1 "
            activeClassName="btn btn-primary btn-cta"
          >
            See PicoNext platform &rarr;
            {/* <FaArrowRight class="btn-arrow" /> */}
          </Link>
        </div>
      </div>

      {/* <hr size={1} /> */}
      <BlogCallout
        context="home"
        title="Resources"
        desc="Articles to help you attract & engage new customers using public ledgers"
        showMoreButton={true}
      />
      {/* <PromoSurveyDPP containerClass="mt-3 mx-2 mx-lg-5" /> */}
      {/* <PromoSurveyWeb3 containerClass="mt-3 mx-2 mx-lg-5" /> */}
      <hr size={1} />
    </Layout>
  );
};
export default Snippet;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Create Digital Product Passports with AI"
      description="Use Digital Product Passports to drive sustainability transparency — using AI, blockchains, and the cloud"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-passport-shoe-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    customers: allMdx(
      sort: { frontmatter: { sortOrder: ASC } }
      filter: {
        frontmatter: {
          path: { glob: "/customers/*" }
          publish: { eq: 1 }
          sortOrder: { gt: 0 }
        }
      }
      limit: 4
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            dateVal: date(formatString: "YYYY-MM-DD")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
            tileTitle
            tileTag
            tileImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
