import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
  if (action.type === "LOADING_START") {
    return { ...state, isLoading: true };
  }
  if (action.type === "LOADING_END") {
    return { ...state, isLoading: false };
  }
  if (action.type === "FIELD_CHANGE") {
    return { ...state, [action.data.name]: action.data.val };
  }
  if (action.type === "LEAD_SUBMITTED") {
    return { ...state, isLoading: false, leadsubmitted: true };
  }
  if (action.type === "FORM_SUBMITTED") {
    return { ...state, isLoading: false, formsubmitted: true };
  }
  if (action.type === "LEAD_SUBMITTED_RESET") {
    return { ...state, isLoading: false, leadsubmitted: false };
  }
  if (action.type === "DEMO_VIDEO_ACCESS") {
    return {
      ...state,
      isLoading: false,
      isLoadingDemo: false,
      demoVideoAccess: true,
    };
  }
  if (action.type === "DEMO_VIDEO_SENT") {
    return {
      ...state,
      isLoading: false,
      isLoadingDemo: false,
      demoVideoSent: true,
    };
  }
  if (action.type === "DEMO_VIDEO_DENIED") {
    return {
      ...state,
      isLoading: false,
      isLoadingDemo: false,
      demoVideoAccess: false,
    };
  }
  if (action.type === "SUBSCRIBE_LOADING_START") {
    return { ...state, isSubscribeLoading: true };
  }
  if (action.type === "SUBSCRIBE_SUBMITTED") {
    return {
      ...state,
      isSubscribeLoading: false,
      subSubmitted: true,
      subSubmittedData: action.data,
    };
  }
  if (action.type === "CAMPAIGNID_UPDATE") {
    return { ...state, campaignid: action.data };
  }
  if (action.type === "DESIGNER_PROFILE_START") {
    return {
      ...state,
      isDesignerTemplatesLoading: true,
    };
  }
  if (action.type === "DESIGNER_PROFILE_UPDATE") {
    return {
      ...state,
      designprofile: action.data,
      isDesignerTemplatesLoading: false,
    };
  }
  if (action.type === "PURCHASE_ERRORS_UPDATE") {
    return {
      ...state,
      purchaseErrors: action.data,
    };
  }
  if (action.type === "PURCHASE_PLAN_UPDATE") {
    return {
      ...state,
      purchasePlan: action.data,
    };
  }
  if (action.type === "PURCHASE_LOADING_UPDATE") {
    return {
      ...state,
      isLoadingPurchase: action.data,
    };
  }
  if (action.type === "PURCHASE_POSITION_UPDATE") {
    return {
      ...state,
      purchasePositionNumber: action.data,
    };
  }
  if (action.type === "PURCHASE_USERS_UPDATE") {
    return {
      ...state,
      provisionedUsersNumber: action.data,
    };
  }
  if (action.type === "BLOG_FILTERTAG_UPDATE") {
    return {
      ...state,
      blogFilterTag: action.data,
    };
  }
  if (action.type === "LOADING_START_ACTIVATION") {
    return { ...state, isLoadingAgreementActivation: true };
  }
  if (action.type === "AGREEMENT_ORDER_UPDATE") {
    return {
      ...state,
      agreementOrderDetails: action.data,
      isLoading: false,
    };
  }
  if (action.type === "AGREEMENT_ACTIVATION_UPDATE") {
    return {
      ...state,
      agreementActivationDetails: action.data,
      isLoadingAgreementActivation: false,
    };
  }
  if (action.type === "AGREEMENT_ERROR_UPDATE") {
    return {
      ...state,
      agreementErrorDetails: action.data,
      isLoading: false,
      isLoadingAgreementActivation: false,
    };
  }
  if (action.type === "USER_ACTIVATION_UPDATE") {
    return {
      ...state,
      userActivationDetails: action.data,
      isLoading: false,
    };
  }
  if (action.type === "USER_ACTIVATION_ERROR_UPDATE") {
    return {
      ...state,
      userActivationErrorDetails: action.data,
      isLoading: false,
    };
  }
  if (action.type === "LOADING_START_DEMO") {
    return { ...state, isLoadingDemo: true };
  }
  if (action.type === "DEMO_REPLACE") {
    return { ...state, isLoadingDemo: false, demoData: action.data };
  }

  return state;
};

const initialState = {
  isDesignerTemplatesLoading: false,
  isSubscribeLoading: false,
  isLoading: false,
  isLoadingPurchase: false,
  isLoadingAgreementActivation: false,
  isLoadingDemo: false,
  leadsubmitted: false,
  formsubmitted: false,
  subSubmitted: false,
  subSubmittedData: {},
  designprofile: {},
  purchaseErrors: {},
  purchasePlan: "standard",
  provisionedUsersNumber: 1,
  purchasePositionNumber: 1,
  blogFilterTag: "all",
  agreementOrderDetails: {},
  agreementActivationDetails: {},
  agreementErrorDetails: "",
  userActivationDetails: {},
  userActivationErrorDetails: "",
  demoData: {},
  demoVideoSent: false,
  demoVideoAccess: false,
};

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
