//React
import React from 'react'

export function formatText(text) {
  const search = 'COMING SOON'
  //SEE https://github.com/facebook/react/issues/3386#issuecomment-78605760
  let matcher = new RegExp(`(\\b${search}+\\b)`, 'gi')
  let rawText = text.split(matcher)
  if (rawText && rawText.length) {
    for (var i = 1; i < rawText.length; i += 2) {
      rawText[i] = (
        <sup className="caveat" key={i}>
          {rawText[i]}
        </sup>
      )
    }
    return rawText
  } else {
    return text
  }
}
