//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Components
import Layout from "../../../components/layout";
import { Metatags } from "../../../components/marketing/metatags";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const accordionData = [
  {
    id: 1,
    slug: "blockchain",
    title:
      "Why use blockchain for a Digital Product Passport over other solutions?",
  },
  {
    id: 2,
    slug: "media",
    title: "What kind of visual media can I use in a Digital Product Passport?",
  },
  {
    id: 3,
    slug: "token-holder",
    title: "Who holds the Digital Product Passport tokens?",
  },
  {
    id: 4,
    slug: "token-transfer",
    title: "Can my Digital Product Passport tokens be transferred or sold?",
  },
  {
    id: 5,
    slug: "data-points",

    title: "How are the sustainability data points associated with a token?",
  },
  {
    id: 6,
    slug: "editing-data",

    title:
      "Can I edit Digital Product Passport data points once they’ve been pushed to the blockchain?",
  },
  {
    id: 7,
    slug: "dates",
    title: "How do the dates work in a Digital Product Passport?",
  },
  {
    id: 8,
    slug: "customization",

    title:
      "Can I customize the layout / fonts / colors of the Digital Product Passport page?",
  },
  { id: 9, slug: "blockchain-network", title: "What blockchain should I use?" },
  {
    id: 10,
    slug: "blockchain-sustainability",
    title: "Are blockchains sustainable themselves?",
  },
];

const accordionContent = (id) => {
  switch (id) {
    case 1:
      return (
        <>
          <p>
            In a blockchain model, data for the Digital Product Passport is
            registered on a public ledger, and cryptographically confirmed on
            the blockchain. Once entered into a blockchain, it is immutable and
            cannot be changed, thus providing a high degree of confidence that
            the data is secure and valid. Further, data on a blockchain has a
            traceable provenance, allowing stakeholders to view what product
            attributes were added by whom, and when – including any updates or
            amendments. In this sense, it facilitates a high degree of
            transparency, because any stakeholder can use the blockchain to
            inspect the data.
          </p>
          <p>
            Because data on a blockchain is decentralized and replicated across
            multiple network-connected nodes, it provides reliability and
            mitigates network failure. Finally, an important attribute of
            blockchain-based data storage is its permanence. Because the data is
            independently stored and replicated across nodes, if the originating
            company or its technology vendor were to become insolvent, it would
            have no bearing on the core product attributes and data stored on
            the blockchain. This data persistence is a key advantage for
            compliance with EU regulation (for applicable companies).
          </p>
          <table>
            <tr>
              <th>
                <strong>Centralized storage</strong>
              </th>
              <th>
                <strong>Decentralized storage (blockchain)</strong>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>
                    <strong>Database.</strong> Stored in a traditional database
                  </li>
                  <li>
                    <strong>Cloud-based.</strong> Generally accessed via the
                    cloud
                  </li>
                  <li>
                    <strong>Mature.</strong> Established models for pricing and
                    usage
                  </li>
                  <li>
                    <strong>Security.</strong> Centralized systems provide
                    greater risk of security issues
                  </li>
                  <li>
                    <strong>Reliability.</strong> All cloud systems incur
                    downtime in which the network is inaccessible
                  </li>
                  <li>
                    <strong>Persistence.</strong> Data in centralized systems is
                    not independently persistent
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <strong>Transparency.</strong> Transactions are publicly
                    available and inspectable
                  </li>
                  <li>
                    <strong>Secure.</strong> Data is cryptographically
                    registered on a blockchain
                  </li>
                  <li>
                    <strong>Immutable.</strong> Data cannot be altered
                  </li>
                  <li>
                    <strong>Traceable.</strong> Data provenance can be traced
                  </li>
                  <li>
                    <strong>Redundant.</strong> Data replicated and stored on
                    multiple nodes, mitigating network failure
                  </li>
                  <li>
                    <strong>Persistent.</strong> Data persists on-chain
                    regardless of vendor status or company insolvency{" "}
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </>
      );
    case 2:
      return (
        <>
          <p>
            PicoNext supports images and videos in the main Digital Product
            Passport token. In individual sustainability data points, PicoNext
            takes markdown image URLs stored on the blockchain and renders them
            for the user.
          </p>
        </>
      );
    case 3:
      return (
        <>
          <p>
            When you create the Digital Product Passport tokens, you can choose
            to send them to your own digital wallet, or to the address of
            another wallet if desired.
          </p>
          <p>
            Holding a token in your own wallet provides end-users the ability to
            view the Digital Product Passport token in the PicoNext Token
            Explorer, along with related sustainability data points.
          </p>
          <p>
            Alternatively, some brands want to distribute tokens to customers as
            proof of product ownership, enabling them to not only view the token
            (and sustainability data points), but also accumulate loyalty
            rewards, participate in on-chain social impact programs, access
            token-gated content and more.
          </p>
        </>
      );
    case 4:
      return (
        <>
          <p>
            By default, the tokens will be sent to your wallet (or the wallet
            address you provide during token creation. They can only be
            transferred or sold if the token holder explicitly authorizes the
            action.
          </p>
        </>
      );
    case 5:
      return (
        <>
          <p>
            Sustainability data points (such as carbon capture transaction IDs,
            materials sourcing info, recyclability info and the like) are
            broadcast to the blockchain and associated with the originating
            contract address and ID of the corresponding Digital Product
            Passport token. PicoNext then queries the blockchain to retrieve
            this data, and visualizes it for end-consumers.
          </p>
        </>
      );
    case 6:
      return (
        <>
          <p>
            Blockchains are an immutable ledger, and as such, once data is
            pushed to it, it cannot be changed, which provides a high degree of
            confidence that the data is secure and valid. Data can be appended
            to a blockchain, however. In the case of erroneous information
            pushed to a Digital Product Passport token inadvertently, an
            organization can correct the record by appending the correct data to
            a token. This is analogous to a newspaper printing a correction
            statement, in order to set the record straight.
          </p>
        </>
      );
    case 7:
      return (
        <>
          <p>
            By default, PicoNext associates a <em>recorded date</em> with a
            sustainability data point pushed to a Digital Product Passport
            token. This <em>recorded date</em> is the date and time the data
            point is sent to a blockchain.
          </p>
          <p>
            For events that are non-linear, or events that pre-date the creation
            of a token, PicoNext allows organizations to define an{" "}
            <em>attested date</em> for a sustainability data point. This{" "}
            <em>attested date</em> is the date and time the organization asserts
            the event took place.
          </p>
          <p>
            In the PicoNext Token Explorer pages, end-users can choose whether
            to display and sort by either <em>recorded </em>or{" "}
            <em>attested </em>dates. For Digital Product Passport tokens
            containing attested dates, the Token Explorer page defaults to
            showing the attested dates, sorting by the attested dates to display
            the data point with the most recent attested data at the top.
          </p>
        </>
      );
    case 8:
      return (
        <>
          <p>
            The default PicoNext{" "}
            <a href="https://connect.piconext.com/">Token Explorer</a> renders a
            standard layout of token information, including the token title,
            description, media, attributes, and associated sustainability data
            points in a timeline-style format. For organizations requiring
            custom layouts, fonts, colors, or brand identity, the{" "}
            <a href="/article/digital-product-passport-loyalty-apis">
              PicoNext APIs
            </a>{" "}
            provide the ability to display token information with advanced
            customization.
          </p>
        </>
      );
    case 9:
      return (
        <>
          <p>
            PicoNext suggests using the Polygon blockchain. The Polygon
            blockchain is based on the well-known Ethereum blockchain, but it is
            built to enable faster blockchain transactions, at a more economical
            price. The price for a token creation or token event transaction on
            the Polygon blockchain is a fraction of a cent, resulting in better
            affordability for brands using the blockchain in a scaled
            environment. Additionally, the Polygon network has committed to
            sustainability, eliminating all of the network’s carbon debt back to
            inception, and then going carbon negative in its operations from
            2022 on.
          </p>
        </>
      );
    case 10:
      return (
        <>
          <p>
            Public ledgers have modernized their own sustainability in recent
            years, with new technology changes in how they confirm transactions
            resolving concerns about resource usage and electricity
            consumption.The result of the new consensus mechanism by which these
            networks use to confirm their transactions (called proof-of-stake)
            is a reduction in annualized electricity consumption by more than
            99.988% by the blockchain network, and a reduction of its carbon
            footprint by approximately 99.992%, according to Ethereum.org.
            Further, the Polygon network (an Ethereum-based network) is
            eliminating all of the network’s carbon debt back to inception, and
            then going carbon negative in its operations from 2022 on. For more
            information, see{" "}
            <a href="/about/blockchain-sustainability/">
              https://piconext.com/about/blockchain-sustainability/
            </a>
          </p>
        </>
      );
    default:
      return <></>;
  }
};

const About = (props) => {
  return (
    <Layout>
      <hr size={1} />

      <div>
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Digital Product Passport FAQs</h1>

              <p class="lead">
                Learn technical details about how sustainability transparency
                works using public ledgers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-blog mt-3 mx-2 mx-lg-5">
        <div class="row">
          <div class="col-sm-8 offset-sm-2">
            <div id="accordion">
              {accordionData.map((item) => (
                <div class="card">
                  <div class="card-header" id={item.slug}>
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${item.id}`}
                        aria-expanded={item.id === 1 ? "true" : "false"}
                        aria-controls={`collapse-${item.id}`}
                        aria-label={`Expand FAQ for ${item.title}`}
                      >
                        <div class="caret"></div>
                        {item.title}
                      </button>
                    </h5>
                  </div>

                  <div
                    id={`collapse-${item.id}`}
                    class={`collapse ${item.id === 1 ? "show" : ""}`}
                    aria-labelledby={`heading-${item.id}`}
                    data-parent="#accordion"
                  >
                    <div class="card-body">{accordionContent(item.id)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 mx-2 mx-lg-5 ">
        <div class="my-2 d-flex flex-column ">
          <div class="gradient-callout-algae rounded-corners p-3 py-5 d-flex flex-column align-items-center justify-content-center">
            <h2 class="fw-bold white-1">Deliver Digital Product Passports</h2>
            <p class="lead white-1">
              PicoNext can help you prepare to deliver Digital Product Passports
              to your customers
            </p>
            <div class="d-flex flex-row flex-wrap white-1 mt-3 mb-4"></div>
            <div class="d-flex flex-row">
              <Link
                to="/article/digital-product-passport/"
                class="btn btn-cta-outline me-2"
              >
                See Digital Product Passport guide &rarr;
                {/* <FaArrowRight /> */}
              </Link>
              <Link to="/demo/video/" class="btn btn-cta-outline">
                See DPP Demo &rarr;
                {/* <FaArrowRight /> */}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr size={1} />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport FAQs"
      description="Learn technical details about how sustainability transparency works using public ledgers."
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "stock/unsplash/arthur-poulin-NhU0nUR7920-unsplash.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
