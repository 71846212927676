const axios = require("axios");

export function getOfferAccess(accessId, dc) {
  let apiUrl = process.env.GATSBY_API_URL_GBL;

  return async (dispatch) => {
    dispatch({
      type: "LOADING_START_DEMO",
    });

    let options = {
      accessId,
      dc,
    };

    try {
      const response = await axios.post(`${apiUrl}offer/access`, options);

      console.log("((actions - getOfferAccess - response))");
      console.log(response);

      const data = response?.data || {};
      console.log("((actions - getOfferAccess - data))");
      console.log(data);

      //Lazy. Should re-do to check database for accesskey
      if (data?.success === true) {
        dispatch({
          type: "DEMO_VIDEO_ACCESS",
        });
      } else {
        dispatch({
          type: "DEMO_VIDEO_DENIED",
        });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: "DEMO_VIDEO_DENIED",
      });
    }
  };
}
export function saveLead(userData) {
  let apiUrl = process.env.GATSBY_API_URL_GBL;

  // let apiName = 'Picovideogbl'
  // let path = 'submitlead'
  // let options = {
  //   body: userData,
  // }
  return async (dispatch) => {
    dispatch({
      type: "LOADING_START",
    });

    let options = {
      ...userData,
    };

    try {
      const response = await axios.post(`${apiUrl}submitlead`, options);

      console.log("((actions - saveLead - response))");
      console.log(response);

      const data = response?.data || {};
      console.log("((actions - saveLead - data))");
      console.log(data);

      dispatch({
        type: "LEAD_SUBMITTED",
        data: data,
      });

      if (userData.leadSource === "video-demo") {
        dispatch({
          type: "DEMO_VIDEO_SENT",
        });
        //If want to revert to sending email only of video demo, comment the dispatch action immediately below.
        dispatch({
          type: "DEMO_VIDEO_ACCESS",
        });
      }
      setTimeout(() => {
        dispatch({
          type: "LEAD_SUBMITTED_RESET",
        });
      }, 7000);
    } catch (error) {
      console.log(error.response);
    }

    // new Promise((resolve) => {
    //   API.post(apiName, path, options)
    //     .then((response) => {
    //       const data = response || {};
    //       console.log("((actions - saveLead))");
    //       console.log(data);
    //       dispatch({
    //         type: "LEAD_SUBMITTED",
    //         data: data,
    //       });
    //       return resolve(
    //         setTimeout(() => {
    //           dispatch({
    //             type: "LEAD_SUBMITTED_RESET",
    //           });
    //         }, 7000)
    //       );
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // }); //end promise
  };
}
