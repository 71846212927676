//React
import React from "react";

//Gatsby
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

//Icons

const Header = (post) => (
  <>
    {post?.frontmatter?.tileImage?.childImageSharp?.gatsbyImageData ? (
      <div class="resources-tile-image d-flex align-items-center justify-content-center rounded-corners bg-white-1 border-2-gray-7">
        <GatsbyImage
          className="rounded-corners"
          imgClassName="rounded-corners"
          image={post.frontmatter.tileImage.childImageSharp.gatsbyImageData}
        />
      </div>
    ) : (
      <GatsbyImage
        className="rounded-corners"
        imgClassName="rounded-corners"
        image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
      />
    )}
  </>
);
const Body = (post, home) => (
  <>
    <div class="post-text-date small line-height-2 mb-2">
      {!home ? post.frontmatter.date : null}{" "}
      {post.frontmatter.date &&
      post.frontmatter.updated &&
      post.frontmatter.date !== post.frontmatter.updated &&
      !home &&
      !post.frontmatter.updated.includes("Invalid") ? (
        <div class="post-text-date pb-2 dkgreen-1 small">
          Updated {post.frontmatter.updated}
        </div>
      ) : null}
    </div>
    {post.frontmatter.path.includes("customers") ? (
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="small fw-bold sky-1 text-uppercase resources-tile-label">
          {post?.frontmatter?.tileTitle || "Customer success"}
        </div>

        {post?.frontmatter?.tileTag && (
          <div
            class={`small fw-medium ${
              post?.frontmatter?.tileTag === "Climate"
                ? "border-1-purple-1 purple-1"
                : "border-1-dkgreen-1 dkgreen-1"
            } text-uppercase resources-tiletag-label`}
          >
            {post?.frontmatter?.tileTag}
          </div>
        )}
      </div>
    ) : null}
    <div>
      <span class="post-text">{post.frontmatter.title}</span>
      {/* <FaArrowRight size={18} class="black-4 ms-2" /> */}
    </div>
  </>
);
const Snippet = ({ home, post }) => (
  <div
    class={`${
      home ? "post-home" : ""
    } post-item round-corners-top round-corners-bottom m-2 d-flex flex-column align-items-center`}
  >
    {post?.frontmatter?.externalLink ? (
      <>
        <a
          href={post.frontmatter.externalLink}
          title={post.frontmatter.title}
          aria-label={post.frontmatter.title}
          target="_blank"
          rel="noopener noreferrer"
        >
          {Header(post)}
        </a>

        <a
          className={`post-text-link d-flex flex-grow-1 flex-column ${
            home
              ? "align-items-center justify-content-center"
              : "align-items-start justify-content-start"
          }
  `}
          title={post.frontmatter.title}
          href={post.frontmatter.externalLink}
          aria-label={post.frontmatter.title}
          target="_blank"
          rel="noopener noreferrer"
        >
          {Body(post, home)}{" "}
          <span class="gray-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-external-link"
            >
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
              <polyline points="15 3 21 3 21 9"></polyline>
              <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
          </span>
        </a>
      </>
    ) : (
      <>
        <Link
          to={post.frontmatter.path}
          title={post.frontmatter.title}
          aria-label={post.frontmatter.title}
        >
          {Header(post)}
        </Link>

        <Link
          className={`post-text-link d-flex flex-grow-1 flex-column ${
            home
              ? "align-items-center justify-content-center"
              : "align-items-start justify-content-start"
          }
      `}
          title={post.frontmatter.title}
          to={post.frontmatter.path}
          aria-label={post.frontmatter.title}
        >
          {Body(post, home)}
        </Link>
      </>
    )}
  </div>
);
export default Snippet;
