//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const Snippet = ({ context }) => (
  <>
    {context && context === "about" ? (
      <div class="post-template-link my-3 px-3 py-4 rounded-corners gradient-callout-huskies d-flex flex-column">
        <h4 class="white-1 fw-light text-center">
          See how PicoNext can help you engage customers with branded NFTs
        </h4>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <Link class="btn btn-cta-outline mt-3" to="/nft-creator/">
            Learn more &rarr;
            {/* <FaArrowRight /> */}
          </Link>
        </div>
      </div>
    ) : (
      <div class="post-template-link my-3 px-3 py-4 rounded-corners gradient-callout-tropical d-flex flex-column">
        <h4 class="white-1 fw-light text-center">
          Request an demo for PicoNext
        </h4>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <Link class="btn btn-cta-outline mt-3" to="/demo/video/">
            See demo &rarr;
            {/* <FaArrowRight /> */}
          </Link>
        </div>
      </div>
    )}
  </>
);
export default Snippet;
