//React
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//Gatsby
import { Link, graphql } from "gatsby";

//Packages
import parse from "url-parse";

//Actions

import { getOfferAccess } from "../../actions/saveLead";
//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";
// import InstantDemo from "../../components/demo/instantDemo";
import VideoDemo from "../../components/demo/videoDemo";

//Icons
// import { FaArrowRight } from "react-icons/fa";

// Redux
// const mapStateToProps = ({ isLoadingDemo, demoData }) => {
//   return {
//     isLoadingDemo,
//     demoData,
//   };
// };
const mapDispatchToProps = (dispatch) => ({
  getOfferAccess: (accessId, dc) => {
    dispatch(getOfferAccess(accessId, dc));
  },
});

const Snippet = (props) => {
  const [campaignId, setCampaignId] = useState("");
  const { getOfferAccess } = props;
  useEffect(() => {
    const url = parse(window.location.href, true);

    console.log("demo/video - url");
    console.log(url);

    if (url.query.c) {
      console.log("demo/video - url.query.c");
      console.log(url.query.c);

      setCampaignId(url.query.c);
    }
    if (url.query.a) {
      console.log("demo/video - url.query.a");
      console.log(url.query.a);
      console.log("demo/video - url.query.dc");
      console.log(url.query.dc);
      getOfferAccess(url.query.a, url?.query?.dc ? url.query.dc : "na");
    }
  }, []);

  return (
    <Layout>
      <div class="gradient-callout-cousteau">
        <div class="container container-page container-landing-minimal">
          <div class="row">
            <div class="col-lg-10 d-flex flex-column justify-content-center text-center text-lg-start">
              <h1 class="display-2 fw-medium white-1">
                Digital Product Passport - Demo
              </h1>
              {/* <p class="display-4 white-4">
                Use AI to create an instant, sample DPP for one of your
                products, at no charge
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div class={`container container-page container-page-demo-video`}>
        <VideoDemo
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          // data={data}
        />
        <hr size="1" />

        <div class="text-center w-100 d-flex flex-row align-items-center justify-content-center mt-3">
          <Link
            class="btn btn-link btn-link-plain black-5 small"
            to="/request-consultation/"
          >
            Request a consultation
          </Link>
        </div>
      </div>
    </Layout>
  );
};

// export default Snippet;
// export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
export default connect(null, mapDispatchToProps)(Snippet);

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport - Video Demo"
      description="View a video tour of the PicoNext Digital Product Passport platform"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
