//React
import React from "react";
import { Link } from "gatsby";

//Components
// import FeatureIcon from "./featureIcon";

//Icons
// import { FaArrowRight } from "react-icons/fa";

// const formatDescription = (descriptionText) => (
//   <React.Fragment>
//     {descriptionText.split("\n").map((line, i) => (
//       <span key={i}>
//         {line}
//         <br />
//       </span>
//     ))}
//   </React.Fragment>
// );

const QuadItem = (item, element, props) => (
  <div
    // style={{ margin: 2 }}
    class={`w-100 round-corners ${
      element.colbggradient ? element.colbggradient : ""
    } bg-${element.colbgcolor ? element.colbgcolor : "white-1"} border-1-${
      element.colbordercolor ? element.colbordercolor : "white-1"
    }`}
  >
    <div
      class={`about-feature-body d-flex align-items-center justify-content-start`}
    >
      {/* <div class="my-2">
        <FeatureIcon
          context={element.iconname}
          title="PicoNext"
          simple
          color={
            item.rowbgcolor
              ? item.rowbgcolor
              : element.iconcolor
              ? element.iconcolor
              : "white"
          }
        />
      </div> */}

      {/* <FaLayerGroup
                  style={{
                    color: "#ffffff",
                    fontSize: 34,
                    opacity: 0.8,
                  }}
                /> */}
      {element.title ? (
        <h4
          class={`pt-2 text-center line-height-4 d-flex align-items-center justify-content-center ${
            element.titlecolor ? element.titlecolor : ""
          }`}
        >
          {element.title}
        </h4>
      ) : null}
      <p class={`p-2 ${element.desccolor ? element.desccolor : ""}`}>
        {element.description}
      </p>
    </div>
  </div>
);
const Snippet = (props) => (
  <div
    class={`${
      props.featureData.bgcolor ? `bg-${props.featureData.bgcolor}` : ""
    } ${props.featureData.bggradient ? `${props.featureData.bggradient}` : ""}`}
  >
    <div
      class={`container container-feature-quad ${
        props.featureData.direction
          ? `container-feature-${props.featureData.direction}`
          : ""
      }`}
    >
      {props.featureData.titleelement === "h2" && props.featureData.title ? (
        <h2
          class={`text-center mb-4 fw-light black-1 display-2 ${
            props.featureData.titlecolor
              ? props.featureData.titlecolor
              : "white-1"
          }`}
        >
          {props.featureData.title}
        </h2>
      ) : props.featureData.title ? (
        <h3
          class={`text-center mb-4 ${
            props.featureData.titlecolor
              ? props.featureData.titlecolor
              : "white-1"
          }`}
        >
          {props.featureData.title}
        </h3>
      ) : null}
      {props.featureData.subtitle ? (
        <p
          class={`lead text-center line-height-3 px-5${
            props.featureData.subtitlecolor
              ? props.featureData.subtitlecolor
              : ""
          }`}
        >
          {props.featureData.subtitle}
        </p>
      ) : null}
      {/* TRIO MODE  */}
      {props.featureData.mode === "trio" ||
      props.featureData.mode === "triovertical" ? (
        <div
          class={`row d-flex ${
            props.featureData.mode === "triovertical"
              ? "flex-column align-items-center"
              : "flex-row align-items-stretch"
          }  justify-content-center`}
        >
          {props.featureData.rowdata.map((item, i) =>
            item.rowdata.map((element, j) => (
              <div
                class={`${
                  props.featureData.mode === "triovertical"
                    ? "col-lg-6"
                    : "col-md-3"
                } about-feature ${
                  element.colbggradient ? element.colbggradient : ""
                } bg-${
                  element.colbgcolor ? element.colbgcolor : "white-1"
                } border-1-${
                  element.colbordercolor ? element.colbordercolor : "white-1"
                }`}
              >
                <div class="about-feature-body d-flex align-items-center justify-content-start">
                  {/* <div class="my-2">
                    <FeatureIcon
                      context={element.iconname}
                      title="Chatbot"
                      simple
                      color={
                        item.rowbgcolor
                          ? item.rowbgcolor
                          : element.iconcolor
                          ? element.iconcolor
                          : "white"
                      }
                    />
                  </div> */}

                  {/* <FaLayerGroup
                          style={{
                            color: "#ffffff",
                            fontSize: 34,
                            opacity: 0.8,
                          }}
                        /> */}
                  {element.title ? (
                    <h4
                      class={`pt-2 text-center ${
                        element.titlecolor ? element.titlecolor : "white-1"
                      }`}
                    >
                      {element.title}
                    </h4>
                  ) : null}

                  <p
                    class={`p-2 text-center ${
                      element.desccolor ? element.desccolor : "white-1"
                    }`}
                  >
                    {element.description}{" "}
                  </p>
                  {element.href ? (
                    <Link
                      to={element.href}
                      style={{ marginTop: -10, marginBottom: 10 }}
                      class={`mt-auto btn btn-sm ${
                        item.rowbgcolor
                          ? `btn-${item.rowbgcolor}`
                          : element.iconcolor
                          ? `btn-${element.iconcolor}`
                          : ""
                      }`}
                    >
                      More &rarr;
                      {/* <FaArrowRight class="btn-arrow" /> */}
                    </Link>
                  ) : null}
                </div>
              </div>
            ))
          )}
        </div>
      ) : null}

      {/* QUAD MODE  */}

      {!props.featureData.mode || props.featureData.mode === "quad"
        ? props.featureData.rowdata.map((item, i) => (
            <div
              class={`row row-container d-flex ${
                props.featureData.direction
                  ? `flex-${props.featureData.direction}`
                  : "flex-row"
              } align-items-stretch justify-content-center text-center`}
            >
              {item.rowdata.map((element, j) => (
                <>
                  {element.href ? (
                    <Link
                      class={`${
                        props.featureData.direction &&
                        props.featureData.direction === "column"
                          ? `mb-3`
                          : "col-lg-3"
                      } feature-element-quad d-flex`}
                      to={element.href ? element.href : ""}
                    >
                      {QuadItem(item, element, props)}
                    </Link>
                  ) : (
                    <div
                      class={`${
                        props.featureData.direction &&
                        props.featureData.direction === "column"
                          ? `mb-3`
                          : "col-lg-3"
                      }  feature-element-quad d-flex`}
                    >
                      {QuadItem(item, element, props)}
                    </div>
                  )}
                </>
              ))}
            </div>
          ))
        : null}
    </div>
  </div>
);

export default Snippet;
