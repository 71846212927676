//React
import React from "react";

//Packages

//Components
import Layout from "../components/layoutLanding";
import { Metatags } from "../components/marketing/metatags";

import ContactLeadForm from "../components/marketing/contactLeadForm";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div class="flex-grow-1">
          <ContactLeadForm
            leadSource="request-invite"
            title="Request invite"
            subtitle="PicoNext is currently available with an invite. Get yours below."

            // subtitle="Stay in-the-know when an invitation for the PicoNext visual + voice content creation platform is available"
          />
        </div>
      </Layout>
    );
  }
}

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Request Invite"
      description="Request access to see how PicoNext can help you can help you drive your business forward"
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};
