import React from "react";

// import FeatureIcon from '../marketing/featureIcon'

const Snippet = (props) => {
  const { posNumber, updatePosNumber } = props;

  const activeColor = "sky-1";
  const inactiveColor = "gray-4";
  return (
    <div class="purchase-position d-flex flex-row align-items-center justify-content-center mt-0">
      <div
        class={`btn-slim purchase-position-item d-flex flex-column align-items-center justify-content-center mx-2`}
      >
        <button
          style={{ width: 30, height: 30, borderRadius: 30, fontSize: 11 }}
          class={`d-flex align-items-center justify-content-center white-1 ${
            posNumber === 1 ? `bg-${activeColor}` : `bg-${inactiveColor}`
          }`}
          onClick={() => {
            updatePosNumber(1);
          }}
        >
          1
        </button>
        {/* <FeatureIcon color={active === 1 ? 'blue' : 'gray'} text="1" /> */}
        <button
          class={`purchase-position-label text-center ${
            posNumber === 1 ? activeColor : inactiveColor
          }`}
          onClick={() => {
            updatePosNumber(1);
          }}
        >
          Industry
        </button>
      </div>
      <div
        style={{
          height: 8,
          width: 130,
          position: "absolute",
          zIndex: -10,
          marginTop: -15,
        }}
        class={`gradient-callout-demo-${posNumber}`}
      ></div>
      <div
        class={`btn-slim purchase-position-item d-flex flex-column align-items-center justify-content-center mx-2`}
      >
        <button
          style={{ width: 30, height: 30, borderRadius: 30, fontSize: 11 }}
          class={`d-flex align-items-center justify-content-center white-1 ${
            posNumber === 2 ? `bg-${activeColor}` : `bg-${inactiveColor}`
          }`}
          onClick={() => {
            updatePosNumber(2);
          }}
        >
          2
        </button>
        <button
          class={`purchase-position-label text-center ${
            posNumber === 2 ? activeColor : inactiveColor
          }`}
          onClick={() => {
            updatePosNumber(2);
          }}
        >
          Product
        </button>
      </div>
      <div
        style={{
          height: 8,
          width: 130,
          position: "absolute",
          zIndex: -10,
          marginTop: -15,
        }}
        class={`gradient-callout-demo-${posNumber}`}
      ></div>
      <div
        class={`btn-slim purchase-position-item d-flex flex-column align-items-center justify-content-center mx-2`}
      >
        <button
          style={{ width: 30, height: 30, borderRadius: 30, fontSize: 11 }}
          class={`d-flex align-items-center justify-content-center white-1 ${
            posNumber === 3 ? `bg-${activeColor}` : `bg-${inactiveColor}`
          }`}
          onClick={() => {
            updatePosNumber(3);
          }}
        >
          3
        </button>
        <button
          class={`purchase-position-label text-center ${
            posNumber === 3 ? activeColor : inactiveColor
          }`}
          onClick={() => {
            updatePosNumber(3);
          }}
        >
          Info
        </button>
      </div>
    </div>
  );
};

export default Snippet;
