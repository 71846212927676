//React
import React from "react";

//Gatsby

//Components
import StackedIcon from "../../images/svg/piconext-icon-stacked.svg";

//Icons
// import { FiMessageCircle, FiAward, FiStar } from "react-icons/fi";
// import { BiBullseye } from "react-icons/bi";

const Snippet = ({
  title,
  icon,
  bgtitlecolor,
  bgcolor,
  content,
  display,
  containerClass,
}) => (
  <div
    class={`d-flex flex-column align-items-start justify-content-start w-100 ${
      containerClass ? containerClass : ""
    }`}
  >
    <div class={`card card-callout bg-${bgcolor}`}>
      <h5
        class={`card-title bg-${bgtitlecolor} d-flex flex-row align-items-center`}
      >
        {/* {icon && icon === "bullseye" ? (
          <BiBullseye
            style={{
              // color: '#ffffff',
              fontSize: "20px",
              opacity: 1,
              // strokeWidth: 2,
              width: 30,
            }}
          />
        ) : null} */}
        {/* {icon && icon === "message" ? (
          <FiMessageCircle
            style={{
              color: "#ffffff",
              fontSize: "20px",
              opacity: 1,
              strokeWidth: 2,
              width: 30,
            }}
          />
        ) : null}
        {icon && icon === "award" ? (
          <FiAward
            style={{
              color: "#ffffff",
              fontSize: "20px",
              opacity: 1,
              strokeWidth: 2,
              width: 30,
            }}
          />
        ) : null}
        {icon && icon === "star" ? (
          <FiStar
            style={{
              color: "#ffffff",
              fontSize: "20px",
              opacity: 1,
              strokeWidth: 2,
              width: 30,
            }}
          />
        ) : null} */}
        {icon && icon === "pico" ? (
          <>
            <div class="capabilities-icon">
              <StackedIcon />
            </div>
            {/* <StaticImage
            src="../../images/piconext-icon-stacked.png"
            alt="PicoNext"
            loading="lazy"
            placeholder="blurred"
            // layout="fullWidth"
            width={30}
            height={30}
          /> */}
          </>
        ) : null}
        <div class="m-0 ms-1 card-title-solo">{title}</div>
      </h5>
      <div class="card-body fw-light">
        {content && Array.isArray(content) && display && display === "list" ? (
          <ul>
            {content.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        ) : content && Array.isArray(content) ? (
          content.map((item) => <p>{item}</p>)
        ) : typeof content === "string" ? (
          <p>{content}</p>
        ) : null}
      </div>
    </div>
  </div>
);
export default Snippet;
