//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";

import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Strategy guide</div>
            <h1 class="h2">
              <span class="fw-normal">Catching the Web3 wave:</span>

              <br />
              <span class="fw-light">
                Attracting the next-generation consumer
              </span>
            </h1>
            <p class="lead line-height-4 py-3">
              In this free guide, get the latest trends on Web3 from new survey
              research, along with ways that brands can transform their customer
              acquisition and retention strategies to take advantage.
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              <StaticImage
                src="../../images/landing/202209-web3-survey/report-web3-survey-cover.png"
                alt="Catching the Web3 wave: Attracting the next-generation consumer
      for Web3"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={190}
                className="mx-3"
                // height={400}
                // style={{ position: `absolute` }}
              />
              <StaticImage
                src="../../images/blog/20221005-01-Web3-Interest.jpg"
                alt="Web3: A practical on-ramp for brands"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={260}
                className="mx-3"
                // height={400}
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>In this free guide, you'll find:</strong>
              <ul>
                <li>An overview of Web3 technologies for brands</li>
                <li>How to develop a Web3 entry strategy for your company</li>
                <li>
                  8 branded use cases for Web3 tokens that you should consider
                  to engage your customers
                </li>
                <li>Data and infographics from our Web3 survey research</li>
                <li>
                  And more ideas and strategies to use Web3 to engage customers
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
                
                </div> */}

            <p class="lead line-height-4 mt-4">
              Receive your free copy of "Catching the Web3 wave: Attracting the
              next-generation consumer" by filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="offer-Web3-guide"
              hideTitle={true}
              title="Request invite"
              subtitle="Please fill out the form below to receive your free guide"
              cta="Submit"
              context="landing"
              offerAttachment="PicoNext--Report-Catching-the-Web3-Wave.pdf"
              offerTitle="Catching the Web3 wave: Attracting the next-generation consumer"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Catching the Web3 wave: Attracting the next-generation consumer"
      description="Get access to the strategy guide Catching the Web3 wave: Attracting the next-generation consumer"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "landing/202209-web3-survey/report-web3-survey-cover-2up-blog.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
