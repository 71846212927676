//React
import React from "react";
import { connect } from "react-redux";

//Actions
import { saveLead } from "../../actions/saveLead";

//Packages
// import parse from "url-parse";

//Components
import Loading from "../loading";
import FreeTierDetail from "./freeTierDetail";
import PrivacyCallout from "./privacyCallout";

//Icons

//Redux
const mapStateToProps = ({ leadsubmitted, isLoading, campaignid }) => {
  return { leadsubmitted, isLoading, campaignid };
};
const mapDispatchToProps = (dispatch) => ({
  saveLead: (userData) => {
    dispatch(saveLead(userData));
  },
});

class Snippet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userLastName: "",
      userTitle: "",
      userCompany: "",
      userEmail: "",
      userPhone: "",
      userEmployees: "",
      userCountry: "",
      leadSource: "",
      campaignId: "",
      initialLoad: true,
      submitted: false,
      submitComplete: false,
      error: {
        userName: false,
        userLastName: false,
        userCompany: false,
        userEmail: false,
        userPhone: false,
        userEmployees: false,
      },
    };
  }

  componentDidMount() {
    if (this.props.leadSource) {
      console.log(`**leadsource ${this.props.leadSource}`);
      this.setState({
        leadSource: `${this.props.leadSource}`,
      });
    }
    // const url = parse(window.location.href, true);

    if (this.props.campaignid) {
      this.setState({ campaignId: this.props.campaignid });
    }
    // if (url.query && url.query.c) {
    //   this.setState({ campaignId: url.query.c })
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.leadSource !== this.props.leadSource) {
      this.setState({
        leadSource: `${this.props.leadSource}`,
      });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      initialLoad: false,
    });
  };

  sendConversionEvent() {
    // if (
    //   typeof window !== `undefined` &&
    //   process.env.STAGE !== 'dev' &&
    //   process.env.STAGE !== 'staging'
    // ) {
    //   window.gtag('event', 'conversion', {
    //     send_to: 'AW-615742954/GdUSCK7N9NYBEOr7zaUC',
    //   })
    // }
  }

  handleValidation(event) {
    event.preventDefault();
    event.stopPropagation();

    const formData = this.state;
    const context = this.props;

    console.log("**formData");
    console.log(formData);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const validEmail = emailRegex.test(formData.userEmail);

    let errorObj = {
      userName: false,
      userLastName: false,
      userCompany: false,
      userCountry: false,
      userEmail: false,
      userPhone: false,
      userEmployees: false,
    };
    if (!formData.userName) {
      // this.setError('userName')
      console.log("no name");
      errorObj.userName = true;
    }
    // if (!formData.userLastName && !context) {
    //   // this.setError('userLastName')
    //   errorObj.userLastName = true

    //   // return
    // }
    if (!formData.userCompany) {
      // this.setError('userCompany')
      errorObj.userCompany = true;

      // return
    }
    if (!formData.userCountry) {
      // this.setError('userCompany')
      errorObj.userCountry = true;

      // return
    }
    if (!formData.userEmail || validEmail !== true) {
      // this.setError('userEmail')
      errorObj.userEmail = true;

      // return
    }
    if (!formData.userPhone && !context) {
      // this.setError('userPhone')
      errorObj.userPhone = true;
      // return
    }
    // if (!formData.userEmployees && !context) {
    //   // this.setError('userEmployees')
    //   errorObj.userEmployees = true
    //   // return
    // }
    console.log("errorObj");
    console.log(errorObj);
    console.log("this.state.error");
    console.log(this.state.error);

    this.setState({ error: errorObj });

    if (
      this.state.initialLoad === false &&
      Object.values(errorObj).every((value) => value === false)
    ) {
      console.log("**handline submit**");
      this.handleSubmit();
    }
  }
  handleSubmit = async () => {
    const { context, offerAttachment, offerTitle } = this.props;
    let formData = this.state;
    if (context === "landing") {
      formData = { ...formData, offerAttachment, offerTitle };
    }

    console.log("formData");
    console.log(formData);

    this.sendConversionEvent();
    await this.props.saveLead(formData);
    setTimeout(() => {
      this.setState({
        userName: "",
        userLastName: "",
        userTitle: "",
        userCompany: "",
        userEmail: "",
        userPhone: "",
        userEmployees: "",
        userCountry: "",
        userComments: "",
        leadSource: "",
        campaignId: "",
        initialLoad: true,
        submitted: false,
        submitComplete: false,
        error: {
          userName: false,
          userLastName: false,
          userCompany: false,
          userCountry: false,
          userEmail: false,
          userPhone: false,
          userEmployees: false,
        },
      });
    }, 5000);
  };
  render() {
    const {
      isLoading,
      leadsubmitted,
      context,
      image,
      title,
      subtitle,
      hideTitle,
      formCallout,
    } = this.props;

    return (
      <>
        {hideTitle ? null : (
          <div
            class={`gradient-callout-algae ${
              context && context === "creative" ? "rounded-corners" : ""
            }`}
          >
            <div class="container p-4">
              <div class="row">
                <div class="col-lg-12 d-flex align-items-center justify-content-center text-center">
                  <h1 class="display-2 fw-normal white-1 mt-1">
                    {title ? title : ""}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )}

        <div class="container pb-5">
          <div
            class={`row text-center ${
              formCallout
                ? "border-1-blue-4 bg-blue-5 rounded corners mt-3"
                : ""
            }`}
          >
            <div
              class={
                (context && context === "creative") ||
                context === "landing" ||
                context === "accesspass"
                  ? "col-lg-12"
                  : context === "freetier"
                  ? "col-lg-8"
                  : "col-lg-8 offset-lg-2"
              }
            >
              {leadsubmitted === false ? (
                <>
                  <div
                    class={
                      context === "landing" || context === "accesspass"
                        ? ""
                        : `p-4`
                    }
                  >
                    {image ? (
                      <img src={image} class="" width="200" alt="" />
                    ) : null}
                    <h2 class="display-4 my-3 p-2">
                      {subtitle
                        ? subtitle
                        : "We're sending invites to PicoNext. Request yours below."}
                    </h2>

                    <div class="form-group">
                      <label for="userName" class="d-none">
                        {context === "creative" ? "Name" : "Your name"}
                      </label>

                      <input
                        type="text"
                        class={`form-control form-control-lg ${
                          this.state.error.userName ? "is-invalid" : ""
                        }`}
                        name="userName"
                        id="userName"
                        placeholder={
                          context === "creative" ? "Name" : "Your name"
                        }
                        value={this.state.userName}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleValidation(e);
                          }
                        }}
                      />
                    </div>
                    {/* {!context ? (
                    <div class="form-group">
                      <label for="userLastName" class="d-none">
                        Last name
                      </label>
                      <input
                        type="text"
                        class={`form-control form-control-lg ${
                          this.state.error.userLastName ? 'is-invalid' : ''
                        }`}
                        name="userLastName"
                        id="userLastName"
                        placeholder="Last name"
                        value={this.state.userLastName}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleValidation(e)
                          }
                        }}
                      />
                    </div>
                  ) : null} */}
                    {/* <div class="form-group">
                      <label for="userTitle" class="d-none">
                        Job title
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="userTitle"
                        id="userTitle"
                        placeholder="Job title"
                        value={this.state.userTitle}
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                    <div class="form-group">
                      <label for="userCompany" class="d-none">
                        Company name
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userCompany ? "is-invalid" : ""
                        }`}
                        name="userCompany"
                        id="userCompany"
                        placeholder="Company name"
                        value={this.state.userCompany}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleValidation(e);
                          }
                        }}
                      />
                    </div>
                    <div class="form-group">
                      <label for="userEmail" class="d-none">
                        {context === "creative" ? "Email" : "Business email"}
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control  form-control-lg ${
                          this.state.error.userEmail ? "is-invalid" : ""
                        }`}
                        name="userEmail"
                        id="userEmail"
                        placeholder={
                          context === "creative" ? "Email" : "Business email"
                        }
                        value={this.state.userEmail}
                        onChange={this.handleInputChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleValidation(e);
                          }
                        }}
                      />
                    </div>

                    {!context ? (
                      <div class="form-group">
                        <label for="userPhone" class="d-none">
                          Business phone{" "}
                        </label>
                        <input
                          type="text"
                          // class="form-control form-control-lg"
                          class={`form-control form-control-lg ${
                            this.state.error.userPhone ? "is-invalid" : ""
                          }`}
                          name="userPhone"
                          id="userPhone"
                          placeholder="Business phone"
                          value={this.state.userPhone}
                          onChange={this.handleInputChange}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.handleValidation(e);
                            }
                          }}
                        />
                      </div>
                    ) : null}
                    <div class="form-group">
                      {/* <label
                        for="userCountry"
                        class="d-block small text-start gray-3 mb-0"
                      >
                        Country
                      </label> */}
                      <select
                        class={`form-control form-control-lg ${
                          this.state.error.userCountry ? "is-invalid" : ""
                        }`}
                        id="userCountry"
                        name="userCountry"
                        value={this.state.userCountry}
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select your country...</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Aland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">
                          Bonaire, Sint Eustatius and Saba
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">
                          Congo, Democratic Republic of the Congo
                        </option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote D'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curacao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">
                          Lao People's Democratic Republic
                        </option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">
                          Macedonia, the Former Yugoslav Republic of
                        </option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">
                          Micronesia, Federated States of
                        </option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestinian Territory</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthelemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="CS">Serbia and Montenegro</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                    {/* {!context ? (
                    <div class="form-group">
                      <label for="userEmployees" class="d-none">
                        Number of employees{' '}
                      </label>
                      <input
                        type="text"
                        // class="form-control form-control-lg"
                        class={`form-control form-control-lg ${
                          this.state.error.userEmployees ? 'is-invalid' : ''
                        }`}
                        name="userEmployees"
                        id="userEmployees"
                        placeholder="# of employees"
                        value={this.state.userEmployees}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  ) : null} */}
                    {context !== "landing" && context !== "climate-content" ? (
                      <div class="form-group">
                        <label for="userComments" class="d-none">
                          {context === "accesspass"
                            ? "What offer would you like to provide?"
                            : "What is your use case or project?"}
                        </label>
                        <textarea
                          class="form-control form-control-lg"
                          name="userComments"
                          id="userComments"
                          rows="3"
                          placeholder={
                            context === "accesspass"
                              ? "What offer would you like to provide?"
                              : context === "auth-content" ||
                                context === "accesspass-content"
                              ? "What is your use case or project?"
                              : context === "shopify-access"
                              ? "Tell us about your store"
                              : "What is your use case or project?"
                          }
                          value={this.state.userComments}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    ) : null}

                    <div class="py-3">
                      <button
                        type="button"
                        class="btn btn-primary btn-lg btn-block"
                        onClick={(e) => this.handleValidation(e)}
                      >
                        {this.props.cta ? this.props.cta : "Send"}
                        {isLoading ? <Loading class="small" /> : null}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div class="p-4">
                  <h2 class="display-3 my-3 p-2">
                    {context === "landing" ? (
                      <>Thanks! We've sent the guide to your email address.</>
                    ) : context === "dpp-planner" ? (
                      <>
                        Thanks! We'll send an invite to you when one is
                        available.
                      </>
                    ) : (
                      <>Thanks! We'll be in touch shortly with you.</>
                    )}
                  </h2>
                </div>
              )}
            </div>

            {context && context === "freetier" ? (
              <div class="col-lg-4 py-5 d-flex">
                <FreeTierDetail containerClass="small" />
              </div>
            ) : null}
          </div>
          {leadsubmitted === false && <PrivacyCallout />}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet);
