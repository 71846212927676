//React
import React, { useEffect, useState } from "react";

//Gatsby
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Actions

//Packages
import parse from "url-parse";

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";
import PostTemplateCtaStatic from "../../components/marketing/postTemplateCtaStatic";
import FreeTierDetail from "../../components/marketing/freeTierDetail";

//Icons

const featureData = [
  {
    title: "Prove value to your organization",
    desc: "With the PicoNext DPP Starter Plan, you can get started creating proof-of-concept Digital Product Passports to show to your colleagues and prove the value of sustainability transparency",
  },
  {
    title: "Get experience creating DPPs",
    desc: "Gain experience creating Digital Product Passports, including by using sustainability templates and publishing DPPs to the cloud",
  },
  {
    title: "Stay ahead of legal regulations",
    desc: "Get a head start preparing your Digital Product Passports ahead of important regulations in the European Union and other regions.",
  },
];
const About = (props) => {
  useEffect(() => {
    const url = parse(window.location.href, true);

    console.log("activate - url");
    console.log(url);
    if (url.query && url.query.sb) {
      setUserSub(url.query.sb);
    }
    if (url.query && url.query.dc) {
      setUserDc(url.query.dc);
    }
  }, []);

  const [userSub, setUserSub] = useState("");
  const [userDc, setUserDc] = useState("");

  return (
    <Layout>
      <div class="gradient-callout-cousteau py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  See how DPPs can help drive your business, at no charge
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-light black-1 display-2">
                Create Digital Product Passports for free
              </h1>

              <p class="lead">
                Get started with Digital Product Passports using the PicoNext
                DPP Starter Plan
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page mt-0 mb-4 pt-4 pb-2">
          <div class="row">
            <div class="col-8 offset-2 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
              {userDc && userSub && (
                <>
                  <Link
                    to={`/activate/freetier/${userDc}/${userSub}`}
                    class="btn btn-outline-dkblue btn-lg"
                  >
                    Activate your account
                  </Link>
                  <hr size={1} class="my-5" />
                </>
              )}

              <StaticImage
                src="../../images/featureperspective/passport-usecases/passport-wallet-explorer-L.png"
                alt="Learn more about Digital Product Passports with the PicoNext DPP Starter Plan"
                loading="lazy"
                placeholder="none"
                layout="fixed"
                width={220}
                height={280}
                transformOptions={{ cropFocus: "north" }}
              />

              <hr size={1} class="my-5" />
              <div class="gradient-callout-silver-reverse mb-3 rounded-corners">
                <h2 class="h3 text-center text-lg-center mt-2 mb-5 black-4">
                  Do more with Digital Product Passports
                </h2>
                <div class="row mb-4 pb-2">
                  <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                    {featureData.map((featureItem) => (
                      <div class="p-2">
                        <div class="h4 green-1">{featureItem.title}</div>
                        <p class="lead line-height-3">{featureItem.desc}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr size={1} class="my-5" />
              <FreeTierDetail />
              <hr size={1} />
              <div class="w-100">
                {userSub && userDc ? (
                  <PostTemplateCtaStatic
                    title="Activate your PicoNext DPP Starter Plan account"
                    link={`/activate/freetier/${userDc}/${userSub}`}
                    cta="Activate account"
                    gradient="algae"
                  />
                ) : (
                  <PostTemplateCtaStatic
                    title="Request an invite to the PicoNext DPP Starter Plan"
                    link="/request-invite-free-tier"
                    cta="Request invite"
                    gradient="algae"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr size={1} />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Create Digital Product Passports for Free"
      description="Learn how to create Digital Product Passports with the PicoNext DPP Starter Plan"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-wallet-explorer-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
