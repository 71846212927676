//React
import React from "react";

//Packages
import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import CookieConsent from "react-cookie-consent";

//Components
import NavMinimal from "./nav/navMinimal";
import FooterMinimal from "./nav/footerMinimal";

//Assets
// import favicon from "../images/piconext-icon.png";
// import './../scss/main.scss'
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/main.scss";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  };

  componentDidMount() {}

  render() {
    const { children } = this.props;

    return (
      <div class="min-vh-100 d-flex flex-column">
        {/* <Helmet
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
          ]}
        /> */}

        <NavMinimal />
        {children}
        <FooterMinimal />
        <CookieConsent
          location="bottom"
          buttonText="OK"
          // enableDeclineButton={true}
          // declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          buttonWrapperClasses="ms-2"
          buttonClasses="btn btn-sm btn-outline-success m-2 m-lg-0 me-lg-2"
          // declineButtonClasses="btn btn-outline-sky m-2 m-lg-0 me-lg-2"
          containerClasses="d-flex align-items-center justify-content-start bg-black-4 white-1 py-2 px-4 fw-light"
          disableButtonStyles={true}
          disableStyles={true}
          style={{
            left: 0,
            position: "fixed",
            width: "100%",
            zIndex: 999,
            bottom: 0,
          }}
        >
          This site uses cookies 🍪
        </CookieConsent>
      </div>
    );
  }
}

export default IndexPage;
