//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";
import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Gain new customers</div>
            <h1 class="h2">
              <span class="fw-bold">Local Access Pass</span>

              {/* <br /> */}
              {/* <span class="fw-normal">
              Attracting the next-generation consumer
            </span> */}
            </h1>
            <p class="lead line-height-4">
              Local Access Pass is an electronic coupon designed to drive
              customers to your business. Sign up today to attract new customers
              with a promotional offer to your business.
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              {/* <StaticImage
              src="../../images/landing/202209-web3-survey/report-web3-survey-cover.png"
              alt="Catching the Web3 wave: Attracting the next-generation consumer
for Web3"
              loading="lazy"
              placeholder="blurred"
              // layout="fullWidth"
              width={190}
              className="mx-3"
              // height={400}
              // style={{ position: `absolute` }}
            /> */}
              <StaticImage
                src="../../images/landing/2022120-accesspass/accesspass-L.png"
                alt="Web3 tokens: A practical on-ramp for brands"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={260}
                className="mx-3"
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>How it works:</strong>
              <ul>
                <li>
                  You can grant an electronic coupon to local guests when they
                  make a reservation to an area hotel or engage with a local
                  business
                </li>
                <li>
                  You set the number of times an offer can be redeemed, and the
                  expiration date.
                </li>
                <li>
                  When the guest is ready to redeem their offer, they show you a
                  QR code on their smartphone
                </li>
                <li>
                  You scan the QR code with your smartphone camera to redeem the
                  offer &ndash; easy and fast!
                </li>
                <li>
                  We'll provide you an getting started kit to get off the ground
                  and answer any question you may have.
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
          
          </div> */}

            <p class="lead line-height-4 mt-4">
              Request more information about being part of the Local Access Pass
              by filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="access-pass"
              hideTitle={true}
              title="Access pass"
              subtitle="Please fill out the form below to receive more info about the Local Access Pass"
              cta="Submit"
              context="accesspass"
              // offerAttachment="PicoNext--Report-Catching-the-Web3-Wave.pdf"
              // offerTitle="Catching the Web3 wave: Attracting the next-generation consumer"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Local Access Pass"
      description="Gain new customers by providing promotional e-coupons using blockchain and public ledgers"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: { eq: "landing/2022120-accesspass/accesspass-L.png" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
