import GATSBY_COMPILED_MDX from "/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/20220207-nfts-for-brands.mdx";
import React from "react";
import {graphql, Link} from "gatsby";
import loadable from "@loadable/component";
import NavBlog from "../components/nav/navBlog";
import FooterMinimal from "../components/nav/footerMinimal";
const PostCta = loadable(() => import("../components/marketing/postCta"));
const PromoSurveyDPP = loadable(() => import("../components/marketing/promoSurveyDPP"));
function Template({data, children}) {
  const {mdx} = data;
  console.log("blog-data");
  console.log(data);
  console.log("blog-mdx");
  console.log(mdx);
  console.log("blog-children");
  console.log(children);
  const {frontmatter} = mdx;
  console.log("blog-frontmatter");
  console.log(frontmatter);
  return React.createElement(React.Fragment, null, React.createElement(NavBlog), React.createElement("div", {
    class: `container container-blog container-blog-${mdx.id} ${frontmatter.path.includes("customers") ? "container-blog-customers" : ""}`
  }, React.createElement("div", {
    class: "row"
  }, React.createElement("div", {
    class: frontmatter.path.includes("customers") ? "col-lg-12" : "col-lg-10 offset-lg-1 "
  }, React.createElement("div", {
    className: "blog-subhead mt-3 mb-2"
  }, React.createElement(Link, {
    to: "/resources/",
    className: "fw-bold"
  }, frontmatter.path.includes("customers") ? "Customer Success" : "Resources"), " ", "/ ", frontmatter.date, " ", frontmatter.date && frontmatter.updated && frontmatter.updated !== frontmatter.date && !frontmatter.updated.includes("Invalid") ? React.createElement("span", {
    class: "ps-2 dkgreen-1"
  }, "Updated ", frontmatter.updated) : null), React.createElement("div", {
    class: "mt-1 px-5 py-5 gradient-callout-silver rounded-corners"
  }, React.createElement("h1", {
    class: "black-1 mt-2 text-center "
  }, frontmatter.title), frontmatter.subtitle ? React.createElement("h2", {
    class: "blog-subtitle text-center mt-0 gray-1"
  }, frontmatter.subtitle) : null), React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, children))))), React.createElement(PromoSurveyDPP, {
    mode: "short",
    containerClass: "container",
    subClass: frontmatter.path.includes("customers") ? "col-lg-12" : "col-lg-10 offset-lg-1"
  }), React.createElement(PostCta, {
    containerClass: "container",
    subClass: frontmatter.path.includes("customers") ? "col-lg-12" : "col-lg-10 offset-lg-1"
  }), React.createElement(PostCta, {
    context: "emailSub",
    containerClass: "mt-1 pt-4 pb-4 border-top-1-gray-5 container",
    subClass: frontmatter.path.includes("customers") ? "col-lg-12" : "col-lg-10 offset-lg-1"
  }), React.createElement(FooterMinimal));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => {
  const {mdx} = data;
  const {frontmatter} = mdx;
  return React.createElement(React.Fragment, null, React.createElement("title", null, frontmatter.title, " - PicoNext"), React.createElement("meta", {
    name: "description",
    content: frontmatter.metatitle
  }), React.createElement("meta", {
    name: "keywords",
    content: frontmatter.metakeywords
  }), React.createElement("meta", {
    name: "author",
    content: "PicoNext"
  }), React.createElement("meta", {
    property: "og:site_name",
    content: "PicoNext"
  }), React.createElement("meta", {
    property: "og:title",
    content: `${frontmatter.title} - PicoNext`
  }), React.createElement("meta", {
    property: "og:type",
    content: "article"
  }), React.createElement("meta", {
    property: "og:image",
    content: `http://${process.env.HOSTNAME}${frontmatter?.tileImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`
  }), React.createElement("meta", {
    property: "og:image:secure_url",
    content: `http://${process.env.HOSTNAME}${frontmatter?.tileImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`
  }), React.createElement("meta", {
    name: "twitter:card",
    content: `summary_large_image`
  }), React.createElement("meta", {
    name: "twitter:site",
    content: `@piconexthq`
  }), React.createElement("meta", {
    name: "twitter:title",
    content: `${frontmatter.title} - PicoNext`
  }), React.createElement("meta", {
    name: "twitter:description",
    content: frontmatter.metadesc
  }), React.createElement("meta", {
    property: "twitter:image",
    content: `http://${process.env.HOSTNAME}${frontmatter?.tileImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`
  }), React.createElement("script", {
    type: "application/ld+json"
  }, `
        {
          "@context": "http://schema.org",
          "@graph": [{
            "@type": "NewsArticle",
            "headline": "${frontmatter.title}",
            "image": [
              "${`http://${process.env.HOSTNAME}${frontmatter?.tileImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}"
             ],
            "datePublished": "${frontmatter.date}T08:00:00+08:00",
            "dateModified": "${frontmatter.updated}T08:00:00+08:00",
            "author": [{
                "@type": "Organization",
                "name": "PicoNext",
                "url": "https://piconext.com"
              }]
          },
          {
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "http${process.env.STAGE === "dev" ? "" : "s"}://${process.env.HOSTNAME}"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "Resources",
              "item": "http${process.env.STAGE === "dev" ? "" : "s"}://${process.env.HOSTNAME}/resources"
            },{
              "@type": "ListItem",
              "position": 3,
              "name": "${frontmatter.title}"
            }]
          }
        ]
        }
        `));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      headings {
        value
        depth
      }
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        updated(formatString: "MMMM D, YYYY")
        metatitle
        metadesc
        metakeywords
        metaimage
        path
        title
        subtitle
        cta
        toc
        tocOffset
        tocTitle
        tocHeadingMaxDepth
        featuredImage {
          childImageSharp {
            gatsbyImageData(transformOptions: { cropFocus: CENTER })
          }
        }
        tileImage {
          childImageSharp {
            gatsbyImageData(transformOptions: { cropFocus: CENTER })
          }
        }
      }
    }
  }
`;
