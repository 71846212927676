import GATSBY_COMPILED_MDX from "/Users/dave/projects/active/pico/sites/piconextweb/src/markdown/privacy.mdx";
import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Metatags} from "../components/marketing/metatags";
function Template({data, children}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  return React.createElement(Layout, null, React.createElement("div", {
    class: "container container-legal"
  }, React.createElement("div", {
    class: "row"
  }, React.createElement("div", {
    class: "col-lg-12 pt-4 pb-5"
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => {
  const {mdx} = data;
  const {frontmatter} = mdx;
  return React.createElement(Metatags, {
    title: `PicoNext  - ${frontmatter.metatitle}`,
    description: frontmatter.metatitle,
    keywords: frontmatter.metakeywords
  });
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        metatitle
        metadesc
        metakeywords
        path
        title
        subtitle
        featuredImage {
          childImageSharp {
            gatsbyImageData(transformOptions: { cropFocus: CENTER })
          }
        }
      }
    }
  }
`;
