//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";
//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Create multimedia experience tokens",
          description:
            "Capture once-in-a-lifetime customer moments using experience tokens, including with video experience mementos",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'orange-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "video",
          iconcolor: "orange",
          image: "calloutTokenDesign",
        },
        // {
        //   title: 'Mint',
        //   colbggradient: 'gradient-callout-algae',
        //   // colbordercolor: 'green-1',
        //   titlecolor: 'black-4',

        //   iconname: 'lightning',
        //   iconcolor: 'white',

        //   description:
        //     'Mint NFTs on the blockchain using a no-code tool, directly from within PicoNext',
        //   desccolor: 'white-1',
        // },
        {
          title: "Customize artwork from templates",
          description:
            "Quickly customize token template content with your own videos, images, stickers, and text - no design team needed",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'orange-1',
          titlecolor: "black-4",
          desccolor: "black-3",

          iconname: "easel",
          iconcolor: "orange",
          image: "calloutTemplates",
        },
        {
          title: "Import custom brand assets",
          description:
            "Import custom assets from other design tools and use as a base for your token artwork",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'orange-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "orange",

          iconname: "imagespread",
          image: "calloutLibraryVideo",
        },
        // {
        //   title: 'Use integrated stock libraries',
        //   description:
        //     'Use integrated stock image, video, and audio libraries to rapidly create your token media',
        //   // colbgcolor: 'dkblue-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'orange-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'orange',

        //   iconname: 'touchapp',
        // },
      ],
    },
  ],
};

const extraFeatureData = {
  title: "More Token Design capabilities",
  titlecolor: "black-4",
  mode: "trio",
  bggradient: "gradient-callout-silver-reverse",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Use integrated stock libraries",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "orange-1",
          iconname: "touchapp",
          iconcolor: "orange",
          desccolor: "gray-1",
          description:
            "Use integrated stock image, video, and audio libraries to rapidly create your token media",
          image: "calloutStock",
          // href: '/platform/digital-product-passport-data-events/',
        },
        {
          title: "Manage template access",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "orange-1",
          iconname: "people",
          iconcolor: "orange",
          desccolor: "gray-1",
          description:
            "Enable template sharing across your organization, or keep private to individual users",
          // href: '/platform/token-explorer/',
          image: "calloutTemplateAccess",
        },
        {
          title: "Reuse template elements",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "orange-1",
          iconname: "template",
          iconcolor: "orange",
          desccolor: "gray-1",
          description:
            "Save and reuse template elements to accelerate your workflow - including custom designs and stickers",
          // href: '/platform/at-a-glance/',
          image: "calloutStickers",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Rapidly design engaging experience tokens from templates
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/feature-loyalty-design-clean-alt-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Design token experiences
            </h1>

            <p class="display-4 line-height-4 p-2">
              Scale digital token creation with a no-code design tool that lets
              you craft beautiful experiences from templates, including with
              video
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3"></div>
    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">Token Design features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
              {extraFeatureData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

    <CalloutSupportSection
      title="designing token experiences"
      section="mint"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Design Web3 experience tokens"
      description="Design Web3 experience tokens using an end-to-end, no-code tool"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/feature-loyalty-design-clean-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
