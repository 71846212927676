//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";
import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Strategy guide</div>
            <h1 class="h2">
              <span class="fw-normal">Eight Web3 Loyalty Use Cases</span>

              <br />
              <span class="fw-light">
                To attract and retain Gen Z + Millennial customers
              </span>
            </h1>
            <p class="lead line-height-4 py-3">
              In this free guide, see 8 real-world ways you can implement Web3
              digital tokens to attract and retain new Gen Z and Millennial
              customers.
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              {/* <StaticImage
                    src="../../images/featureperspective/resortsplus-usecases/resortsplus-maldives-R.png"
                    alt="Eight Web3 Loyalty Use Cases"
                    loading="lazy"
                    placeholder="blurred"
                    // layout="fullWidth"
                    width={200}
                    height={260}
                    className="mx-3"
                    transformOptions={{ cropFocus: `north` }}
                    // height={400}
                    // style={{ position: `absolute` }}
                  /> */}
              <StaticImage
                src="../../images/featureperspective/resortsplus-usecases/resortsplus-rome-cookingclass-R.png"
                alt="Lightweight loyalty programs and digital access passes"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={200}
                height={260}
                className="mx-3"
                transformOptions={{ cropFocus: `north` }}
                // height={400}
                // style={{ position: `absolute` }}
              />
              <StaticImage
                src="../../images/landing/202302-web3-eight-usecases/report-web3-usecases-cover.png"
                alt="Eight Web3 Loyalty Use Cases"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                // width={210}
                height={260}
                className="mx-3 "
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>In this free guide, you'll discover:</strong>
              <ul>
                <li>Three core elements of Web3 loyalty experiences</li>
                <li>
                  How Web3 experience tokens are moving from novelty to utility
                </li>
                <li>
                  8 branded loyalty use cases for Web3 that you should consider
                  to engage your customers &mdash; including:
                  <ul>
                    <li>Lightweight loyalty programs</li>
                    <li>Digital postcards</li>
                    <li>Local access passes</li>
                    <li>Carbon removal</li>
                  </ul>
                </li>
                <li>
                  And more ideas and strategies to use Web3 to engage Gen Z and
                  Millennial customers
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
                
                </div> */}

            <p class="lead line-height-4 mt-4">
              Receive your free copy of "Eight Web3 Loyalty Use Cases" by
              filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="offer-Web3-EightUseCases"
              hideTitle={true}
              title="Request invite"
              subtitle="Please fill out the form below to receive your free guide"
              cta="Submit"
              context="landing"
              offerAttachment="PicoNext--Report-Web3-Loyalty-Usecases.pdf"
              offerTitle="Eight Web3 Loyalty Use Cases"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Eight Web3 Loyalty Use Cases"
      description="Get access to the strategy guide Eight Web3 Loyalty Use Cases"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "landing/202302-web3-eight-usecases/report-web3-usecases-cover-2up-blog.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
