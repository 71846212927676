//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";

//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Engage customers with token-gated communities",
          description:
            "Engage customers with exclusive content & offers in private, brand-safe communities you curate",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'dkblue-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "recentactors",
          iconcolor: "dkblue",
          image: "calloutCommunity6",
        },

        {
          title: "Create turnkey, branded landing pages",
          description:
            "Use a turnkey community builder to get your community up-and-running quickly - and include your brand identity and assets",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'dkblue-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "webpage",
          iconcolor: "dkblue",
          image: "calloutCommunity4",
        },
        {
          title: "Deliver token-gated content",
          description:
            "Restrict access to your community to customers who hold selected tokens from your collection",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'dkblue-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "shielduser",
          iconcolor: "dkblue",
          image: "calloutCommunity7",
        },
        // {
        //   title: 'Curate community content',
        //   description:
        //     'Enable your team members to post brand-safe, curated content that engages your users, including text, images and video',
        //   // colbgcolor: 'dkblue-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'dkblue-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',

        //   iconname: 'lightning',
        //   iconcolor: 'dkblue',
        // },
      ],
    },
  ],
};

const extraFeatureData = {
  title: "More Web3 Community capabilities",
  titlecolor: "black-4",
  mode: "quad",
  bggradient: "gradient-callout-silver-reverse",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Curate community content",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "dkblue-1",
          iconname: "megaphone",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description:
            "Enable your team members to post brand-safe, curated content that engages your users, including text, images and video",
          // href: '/platform/digital-product-passport-data-events/',
          image: "calloutCommunity1",
        },
        {
          title: "Text message community members",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "dkblue-1",
          iconname: "chatbot",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description:
            "Let members opt-in to SMS messages to receive the latest community content and offers",
          // href: '/platform/token-explorer/',
          image: "calloutCommunity5",
        },
        {
          title: "Feature external Web links",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "dkblue-1",
          iconname: "link",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description:
            "Feature important Web links prominently for your community members",
          // href: '/platform/at-a-glance/',
          image: "calloutCommunity2",
        },
        {
          title: "Custom-branded pages",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "dkblue-1",
          iconname: "lightning",
          iconcolor: "dkblue",
          desccolor: "gray-1",
          description:
            "Brand community pages with your own look-and-feel, and run as a hosted solution under your own domain name",
          // href: '/platform/at-a-glance/',
          image: "calloutCommunity3",
        },
      ],
    },
  ],
};
const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Engage token holders with curated, brand-safe content in branded
                communities
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/trailblazer-usecases/galapagos-community-short-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Drive community engagement with your DPP
            </h1>

            <p class="display-4 line-height-4 p-2">
              Enhance your Digital Product Passport with curated, token-gated
              community content
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>
    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">Branded Community features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
              {extraFeatureData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CalloutSupportSection
      title="creating token-gated communities"
      section="community"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Branded communities for Digital Product Passports"
      description="Use curated community content to enhance Digital Product Passports and engage customers"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/feature-overview-nft-connect-3.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
