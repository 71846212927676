//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

const Nav = ({ sections }) => (
  <nav class="navbar navbar-sub navbar-expand-lg bg-gray-7  border-top-1-gray-7 border-bottom-1-gray-7 ">
    <div class="container">
      <div
        class="d-flex flex-row align-items-center justify-content-center"
        id="navbarIndustries"
      >
        <ul class="navbar-nav nav-subusecase me-auto d-flex flex-row align-items-center justify-content-center flex-wrap">
          {sections && sections.length > 0
            ? sections.map((section) => (
                <li class="nav-item pe-3">
                  <Link
                    class="nav-link fw-light black-4"
                    activeClassName="nav-link black-4 fw-regular active"
                    to={section.path}
                  >
                    {section.name}
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  </nav>
);

export default Nav;
