const axios = require("axios");

export function initiateDppDemo(userData) {
  let apiUrl = process.env.GATSBY_API_URL_GBL;

  // let apiName = 'Picovideogbl'
  // let path = 'submitlead'
  // let options = {
  //   body: userData,
  // }
  return async (dispatch) => {
    dispatch({
      type: "LOADING_START_DEMO",
    });

    let options = {
      ...userData,
    };

    try {
      const response = await axios.post(`${apiUrl}demo/dpp/initiate`, options);

      console.log("((actions - initiateDppDemo - response))");
      console.log(response);

      const data = response?.data || {};
      console.log("((actions - initiateDppDemo - data))");
      console.log(data);

      dispatch({
        type: "DEMO_REPLACE",
        data: data,
      });
      // setTimeout(() => {
      //   dispatch({
      //     type: "LEAD_SUBMITTED_RESET",
      //   });
      // }, 7000);
    } catch (error) {
      console.log("error.response");
      console.log(error.response);
      console.log("error.response.data");
      console.log(error.response.data);
      dispatch({
        type: "DEMO_REPLACE",
        data: error.response.data,
      });
    }
  };
}
