//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

export default class Nav extends React.Component {
  render() {
    return (
      <footer class="footer mt-auto">
        <div class="container">
          <div class="row row-copyright d-flex flex-row justify-content-center align-items-center text-center">
            <div class="col-12">
              <span>&copy; 2024 PicoNext, Inc.</span>
              <Link to="/about/terms/">Terms</Link>
              <Link to="/about/privacy/">Privacy</Link>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
