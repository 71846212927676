//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";

//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Create custom-branded DPPs",
          description:
            "Deliver branded experiences for customers by integrating DPP data in customized Web sites or mobile apps",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPermissions5",
        },
        {
          title: "Store user data in regional datacenters",
          description:
            "Comply with European Union requirements by storing user data in E.U.-based data centers, if applicable",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPermissions3",
        },
        {
          title: "Customize workflows with APIs",
          description:
            "Use APIs to automate DPP Data Event, DPP Data Retrieval, and Token Collection workflows",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPermissions4",
        },
        {
          title: "Manage user permissions",
          description:
            "Give team members role-based permissions to capabilities in the PicoNext console, including minting, DPP Planner, DPP Data Events, loyalty features and more",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPermissions1",
        },
        {
          title: "Manage blockchain smart contract permissions",
          description:
            "Use an extra layer of security by authorizing wallet addresses to initiate specific actions on your blockchain smart contract",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPermissions2",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Create custom-branded DPPs, manage sustainability data, and
                administer user permissions
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/customers/weartek/weartek-landing-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Digital Product Passport APIs &amp; Data Management
            </h1>

            <p class="display-4 line-height-4 p-2">
              Create custom-branded DPPs, retrieve DPP data using APIs, and
              administer user permissions
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>

    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Data management &amp; user permissions features
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CalloutSupportSection
      title="getting started with Permissions & Data Management"
      section="other"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport APIs and Data Management"
      description="Manage users, blockchain permissions, and data workflows for Digital Product Passports"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: { eq: "featureperspective/console-home-tiles-R.png" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
