//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";

//Icons

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Issue loyalty tokens",
          description:
            "Give loyalty rewards to customers - including discounts, offers, and exclusive access - and run alongside existing loyalty programs",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'purple-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "purple",

          iconname: "award",
          image: "calloutLoyalty8",
        },
        {
          title: "Redeem loyalty tokens",
          description:
            "Redeem rewards using rules you define, and verify redemption across a token's blockchain history",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'purple-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "purple",

          iconname: "bullseye",
          image: "calloutLoyalty7",
        },
        {
          title: "Quickly scan QR reward codes",
          description:
            "Give customers fast access to loyalty rewards with easily scannable QR codes - no extra hardware required",

          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'purple-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconname: "ticket",
          iconcolor: "purple",
          image: "calloutLoyalty6",
        },
        // {
        //   title: 'Mint',
        //   colbggradient: 'gradient-callout-algae',
        //   // colbordercolor: 'green-1',
        //   titlecolor: 'black-4',

        //   iconname: 'lightning',
        //   iconcolor: 'white',

        //   description:
        //     'Mint NFTs on the blockchain using a no-code tool, directly from within PicoNext',
        //   desccolor: 'white-1',
        // },
        {
          title: "Deliver social impact",
          description:
            "Donate a portion of blockchain transactions to cause-based initiatives that resonate with your customers",
          // colbgcolor: 'dkblue-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'purple-1',
          titlecolor: "black-4",
          desccolor: "black-3",

          iconname: "grow",
          iconcolor: "purple",
          image: "calloutLoyalty9",
        },
      ],
    },
  ],
};

const extraFeatureData = {
  title: "More Loyalty capabilities",
  titlecolor: "black-4",
  mode: "trio",
  bggradient: "gradient-callout-silver-reverse",
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Add reward expiration",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "purple-1",
          iconname: "calendar",
          iconcolor: "purple",
          desccolor: "gray-1",
          description:
            "Add expiration dates to token rewards for time-sensitive offers & events",
          // href: '/platform/digital-product-passport-data-events/',
          image: "calloutLoyalty3",
        },
        {
          title: "Display coupon codes",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "purple-1",
          iconname: "moneycheck",
          iconcolor: "purple",
          desccolor: "gray-1",
          description:
            "Provide coupon codes to customers instead of using QR code redemption",
          // href: '/platform/token-explorer/',
          image: "calloutLoyalty1",
        },

        // {
        //   title: 'Add direct external links',
        //   // colbgcolor: 'blue-1',
        //   titlecolor: 'black-4',
        //   colbordercolor: 'purple-1',
        //   iconname: 'link',
        //   iconcolor: 'purple',
        //   desccolor: 'gray-1',
        //   description:
        //     'Link directly to external Web sites or e-commerce portals for customers to claim rewards',
        //   // href: '/platform/at-a-glance/',
        // },
        {
          title: "Redeem rewards manually",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "purple-1",
          iconname: "stickynote",
          iconcolor: "purple",
          desccolor: "gray-1",
          description:
            "Enable manual redemption of loyalty rewards from an admin console",
          // href: '/platform/at-a-glance/',
          image: "calloutLoyalty4",
        },
        {
          title: "Off-chain loyalty tokens",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "purple-1",
          iconname: "linkoff",
          iconcolor: "purple",
          desccolor: "gray-1",
          description:
            "Allow users without digital wallets to redeem tokens as part of your loyalty program",
          // href: '/platform/at-a-glance/',
          image: "calloutLoyalty5",
        },
        {
          title: "Custom-branded pages",
          // colbgcolor: 'blue-1',
          titlecolor: "black-4",
          colbordercolor: "purple-1",
          iconname: "lightning",
          iconcolor: "purple",
          desccolor: "gray-1",
          description:
            "Brand rewards pages with your own look-and-feel, and run as a hosted solution under your own domain name",
          // href: '/platform/at-a-glance/',
          image: "calloutLoyalty2",
        },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Attract new Gen Z and Millennial customers through experience
                tokens that drive loyalty
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/resortsplus-usecases/resortsplus-apresski-loyalty-short-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Use loyalty tokens to attract new audiences
            </h1>

            <p class="display-4 line-height-4 p-2">
              Use experience tokens as part of modern loyalty programs that
              attract new Gen Z / Millennial customers
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3"></div>
    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4"> Loyalty features</h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
              {extraFeatureData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CalloutSupportSection
      title="loyalty programs"
      section="loyalty"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Blockchain/Web3 loyalty"
      description="Enhance Digital Product Passports with blockchain / Web3 loyalty programs"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: { eq: "featureperspective/feature-loyalty-impact-L.png" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
