/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import NftBrandDatabase from "../components/marketing/nftBrandDatabase";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "NFT projects for brand marketing"), "\n", React.createElement(_components.p, null, "Many brands are using non-fungible tokens (NFTs) to engage their customers and build community. From NFT use cases including collectibles and social impact, to customer loyalty and physical product redemption, we've collected examples of how brands are using this new technology below."), "\n", React.createElement(NftBrandDatabase));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
