//React
import React, { useState } from "react";

//Gatsby
import { Link } from "gatsby";

//Assets
import Logo from "../../images/svg/piconext-logo-04.svg";

const pageStructure = [
  {
    section: "platform",
    pages: [
      // {
      //   title: 'Overview',
      //   content: 'Get an overview of the PicoNext platform',
      //   url: '/platform/',
      // },
      // {
      //   title: 'At-a-glance',
      //   content: 'See how the PicoNext capabilities work together',
      //   url: '/platform/at-a-glance/',
      //   rulebelow: true,
      // },
      // {
      //   title: 'Design',
      //   content: 'Rapidly design engaging experience tokens from templates',
      //   url: '/platform/create-web3/',
      // },

      // {
      //   title: 'Onboarding',
      //   content:
      //     'Quickly ramp customers into your program with digital wallets',
      //   url: '/platform/web3-onboarding/',
      // },

      // {
      //   title: 'Communities',
      //   content:
      //     'Create brand-safe, token-gated communities of engaged customers',
      //   url: '/platform/communities/',
      // },
      // {
      //   title: 'Marketplaces',
      //   content: 'Distribute tokens with a branded marketplace',
      //   url: '/platform/branded-web3-marketplaces/',
      // },

      {
        title: "DPP Planner",
        content: "Gather DPP info using templates",
        url: "/platform/digital-product-passport-planner/",
      },
      {
        title: "Generative AI",
        content: "Use AI to summarize complex sustainability data",
        url: "/platform/generative-ai/",
      },

      {
        title: "Publish DPPs",
        content: "Publish DPPs in the cloud or on a blockchain",
        url: "/platform/create-dpp/",
      },

      {
        title: "DPP Data Events",
        content: "Attach data to your blockchain DPP",
        url: "/platform/digital-product-passport-data-events/",
      },
      {
        title: "Shopify Integration",
        content: "Create DPPs from within a Shopify store",
        url: "/platform/digital-product-passport-ecommerce/",
      },

      // {
      //   title: 'Loyalty',
      //   content: 'Enhance DPPs with loyalty rewards',
      //   url: '/platform/loyalty/',
      // },
      {
        title: "Token Explorer",
        content: "Explore DPP data & loyalty rewards",
        url: "/platform/token-explorer/",
        rulebelow: true,
      },
      {
        title: "All capabilities",
        content: "See all PicoNext DPP capabilities",
        url: "/platform/",
      },
      // {
      //   title: 'At-a-glance',
      //   content: 'See how the PicoNext capabilities work together',
      //   url: '/platform/at-a-glance/',
      //   // rulebelow: true,
      // },
    ],
  },

  {
    section: "solutions",
    pages: [
      // {
      //   title: 'Overview',
      //   content: 'See how PicoNext can help you drive business impact',
      //   url: '/solutions/',
      //   rulebelow: true,
      // },
      {
        title: "Digital Product Passport",
        content: "Drive transparency & comply with regulations",
        url: "/solutions/digital-product-passport/",
        rulebelow: true,
      },
      // {
      //   title: 'Sustainability Transparency',
      //   content:
      //     'Increase trust in sustainability strategies & avoid greenwashing',
      //   url: '/solutions/web3-climate-sustainability/',
      // },

      // {
      //   title: "Customer Loyalty",
      //   content: "Enhance DPPs with loyalty programs",
      //   url: "/solutions/web3-customer-loyalty/",
      //   rulebelow: true,
      // },
      // {
      //   title: 'Local Access Pass',
      //   content: 'Unlock local experiences to drive brand differentiation',
      //   url: '/solutions/web3-local-access-pass/',
      // },
      // {
      //   title: 'Social Impact',
      //   content: 'Build sustainable communities that drive social impact',
      //   url: '/solutions/web3-social-impact/',
      // },
      // {
      //   title: 'Events',
      //   content:
      //     'Deliver engaging, branded event experiences using blockchains',
      //   url: '/solutions/web3-events/',
      // },
      // {
      //   title: 'Content Authentication',
      //   content: 'Protect your brand from AI-generated deepfakes',
      //   url: '/solutions/authentication-ai-generated-content/',
      // },
      {
        title: "All solutions",
        content: "See all PicoNext public ledger solutions",
        url: "/solutions/",
      },
    ],
  },
  // {
  //   section: 'resources',
  //   pages: [
  //     {
  //       title: 'Overview',
  //       content: 'Get an overview Web3 solutions from PicoNext',
  //       url: '/solutions/',
  //     },
  //   ],
  // },
];
const renderMenuItem = (page) => {
  return (
    <>
      <Link
        class="dropdown-item"
        activeClassName="dropdown-item active"
        to={page.url}
      >
        <div class="dropdown-item-desc-title fw-regular">{page.title}</div>
        <div class="dropdown-item-desc-content small fw-light">
          {page.content}
        </div>
      </Link>
      {page.rulebelow ? (
        <hr size={1} style={{ margin: "4px 0px 0px 0px" }} />
      ) : null}
    </>
  );
};
const Nav = (props) => {
  const [openContext, setOpenContext] = useState(false);
  return (
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <Link
          class="navbar-brand"
          aria-label="PicoNext Home"
          activeClassName="navbar-brand"
          to="/"
        >
          <Logo />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          // data-bs-toggle="collapse"
          // data-bs-target="#navbarText"
          // aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenContext(!openContext)}
        >
          <i class="navbar-toggler-icon"></i>
        </button>
        <div
          class={`navbar-main navbar-collapse w-100 ${
            openContext ? "" : "collapse "
          }`}
          id="navbarText"
        >
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <button
                class="nav-link dropdown dropdown-toggle"
                activeClassName="nav-link active dropdown dropdown-toggle"
                id="dropdownPlatform"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Platform
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownPlatform">
                {pageStructure
                  .filter((item) => item.section === "platform")?.[0]
                  ?.pages.map((page) => renderMenuItem(page))}
              </div>
            </li>
            {/* <li class="nav-item">
              <Link
                to="/about/how-works/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                How it works
              </Link>
            </li> */}
            <li class="nav-item">
              <button
                class="nav-link dropdown dropdown-toggle"
                activeClassName="nav-link active dropdown dropdown-toggle"
                id="dropdownSolutions"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Solutions
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownSolutions">
                {pageStructure
                  .filter((item) => item.section === "solutions")?.[0]
                  ?.pages.map((page) => renderMenuItem(page))}
              </div>
            </li>

            {/* <li class="nav-item">
              <Link
                to="/about/examples/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Examples
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link
                to="/about/features/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Features
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link
                to="/about/tour/brands/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Tour
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link
                to="/design/featured/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Designers
              </Link>
            </li> */}
            <li class="nav-item">
              <Link
                to="/industry/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Industries
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/pricing/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Pricing
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/resources/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Resources
              </Link>
            </li>
            <li class="nav-item">
              <a
                href={process.env.SUPPORT_DOMAIN}
                class="nav-link"
                // target="_blank"
                activeClassName="nav-link active"
                // rel="noopener noreferrer"
              >
                Support
              </a>
            </li>

            {/* <li class="nav-item">
              <Link
                to="/use-cases/retail/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Use cases
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/resources/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Resources
              </Link>
            </li> */}
          </ul>
          <div class="navbar-text ms-auto d-flex flex-row align-items-center justify-content-center">
            <Link
              to="/request-consultation/"
              class="contact-link me-1 text-center border-right-1-gray-4"
              activeClassName="contact-link"
            >
              Contact us
            </Link>
            <a
              href={process.env.CONSOLE_DOMAIN}
              class="console-link me-1 text-center"
              activeClassName="console-link me-2 text-center active"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
            <Link
              to="/demo/video/"
              class="demo-link"
              activeClassName="demo-link active"
            >
              <div class="btn btn-primary btn-cta">See demo</div>
            </Link>
            {/* <Link
              to="/start/?c=sd-trial--navmain"
              class="nav-link"
              activeClassName="nav-link active"
            >
              <div class="btn btn-primary btn-cta">Free trial</div>
            </Link> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
