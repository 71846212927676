//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";

import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Strategy guide</div>
            <h1 class="h2">
              <span class="fw-normal">
                Using Web3 to address climate change
              </span>

              {/* <br />
                  <span class="fw-light">
                    To attract and retain Gen Z + Millennial customers
                  </span> */}
            </h1>
            <p class="lead line-height-4 py-3">
              In this free guide, see how you can use Web3 as part of your
              sustainability strategy to validate climate actions, coordinate
              sustainability efforts, and help drive transparency
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              {/* <StaticImage
                    src="../../images/featureperspective/resortsplus-usecases/resortsplus-maldives-R.png"
                    alt="Eight Web3 Loyalty Use Cases"
                    loading="lazy"
                    placeholder="blurred"
                    // layout="fullWidth"
                    width={200}
                    height={260}
                    className="mx-3"
                    transformOptions={{ cropFocus: `north` }}
                    // height={400}
                    // style={{ position: `absolute` }}
                  /> */}
              <StaticImage
                src="../../images/featureperspective/resortsplus-usecases/resortsplus-carbon-capture-generic-R.png"
                alt="Web3 climate sustainability"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={200}
                height={260}
                className="mx-3"
                transformOptions={{ cropFocus: `north` }}
                // height={400}
                // style={{ position: `absolute` }}
              />
              <StaticImage
                src="../../images/landing/202304-web3-climate/sustainability2.jpg"
                alt="Using Web3 to address climate change"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                // width={210}
                height={260}
                className="mx-3 "
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>In this free guide, you'll discover:</strong>
              <ul>
                <li>How blockchain / Web3 can address climate change</li>

                <li>
                  4 steps to help mitigate climate impact using Web3 tokens
                </li>
                <li>
                  Benefits of combining global climate programs with local
                  social impact
                </li>
                <li>If blockchains themselves are sustainable</li>
                <li>
                  And more ideas and strategies to use Web3 to drive climate
                  sustainability
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
                
                </div> */}

            <p class="lead line-height-4 mt-4">
              Receive your free copy of "Using Web3 to address climate change"
              by filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="offer-Web3-Climate-Sustainability"
              hideTitle={true}
              title="Request invite"
              subtitle="Please fill out the form below to receive your free guide"
              cta="Submit"
              context="landing"
              offerAttachment="PicoNext--Report-Web3-Climate-Sustainability.pdf"
              offerTitle="Using Web3 to address climate change"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Using Web3 to address climate change"
      description="Get access to the strategy guide Using Web3 to address climate change"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "landing/202304-web3-climate/report-web3-climate-cover.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
