export function updateCampaign(campaignId) {
  return (dispatch) => {
    dispatch({
      type: 'CAMPAIGNID_UPDATE',
      data: campaignId,
    })
  }
}
export function updateBlogFilterTag(tag) {
  return (dispatch) => {
    dispatch({
      type: 'BLOG_FILTERTAG_UPDATE',
      data: tag,
    })
  }
}
