//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

//Actions

//Components
import { formatText } from "../../utils/constants";

// import FeatureIcon from "../marketing/featureIcon";
import IndustryImages from "../marketing/industryImages";

// import PreviewVideo from "../elements/previewVideo";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const formatDescription = (descriptionText) => {
  let workingText = formatText(descriptionText);
  console.log("workingText - == - ");

  console.log(workingText);
  return (
    <React.Fragment>
      {descriptionText.split("\n").map((line, i) => {
        console.log("descriptionText - == - line");
        console.log(line);
        return (
          <span key={i}>
            {formatText(line)}
            <br />
          </span>
        );
      })}
    </React.Fragment>
  );
};

// const getVideoSource = (videosource) => {
//   switch (videosource) {
//     default:
//       return "https://picohealth.s3.amazonaws.com/static/web/picohealth-overview-video.mp4";
//   }
// };
const Snippet = (props) => (
  <>
    {props.featureData.map((item, i) => {
      let shiftVal = props.shift ? 1 : 0;
      return (
        <>
          {i % 2 !== shiftVal ? (
            <>
              {item.anchor ? <a id={item.anchor}></a> : null}
              <div
                class={`py-3 ${item.bgcolor ? `bg-${item.bgcolor}` : ""} ${
                  item.border ? item.border : ""
                } ${
                  item.bggradient ? `gradient-callout-${item.bggradient}` : ""
                }`}
              >
                <div
                  class={`container container-alt-callout d-flex align-items-center justify-content-center ${
                    item.bgcolor ? `m-0` : ""
                  }`}
                >
                  <div class={`row ${!item.bgcolor ? `alt-callout` : ""}`}>
                    <div
                      class={`${
                        item.expandcol ? `col-lg-${item.expandcol}` : "col-lg-4"
                      } offset-xs-0 offset-lg-2 mb-3 d-flex align-items-center justify-content-center ${
                        item.image ? "alt-image-sizer" : ""
                      }`}
                    >
                      {item.image ? (
                        <IndustryImages
                          industry={item.image}
                          size=""
                          output="image"
                          className={`rounded-corners ${
                            item.imageShadow ? "shadow" : ""
                          } ${
                            item.imageBorderColor
                              ? `border-1-${item.imageBorderColor}`
                              : ""
                          }`}
                        />
                      ) : null}
                      {/* {item.videosource ? (
                    <div
                      class="d-flex align-items-center justify-content-center justify-content-lg-end p-4"
                      style={{ display: "flex" }}
                    >
                      <PreviewVideo
                        videosource={getVideoSource(item.videosource)}
                      />
                    </div>
                  ) : null} */}
                    </div>

                    <div
                      class={`${
                        item.fullWidth ? "col-lg-12" : "col-lg-6"
                      } d-flex`}
                    >
                      <div
                        class={`feature-${i} ms-0 ms-lg-1 d-flex flex-column align-items-center justify-content-center flex-grow-1 ${
                          !item.fullWidth ? "align-items-lg-start" : ""
                        }`}
                      >
                        {/* {item.iconShow ? (
                          <div class="feature-icon-container feature-icon-container-left">
                            {item.iconContext ? (
                              <FeatureIcon
                                context={item.iconContext}
                                color={item.iconColor}
                                iconfill={item.iconfill}
                                iconcolor={item.iconcolor}
                              />
                            ) : (
                              <FeatureIcon
                                text={i + 1}
                                color={item.iconColor}
                                iconfill={item.iconfill}
                                iconcolor={item.iconcolor}
                              />
                            )}
                          </div>
                        ) : null} */}
                        <div
                          class={`d-flex flex-column align-items-center justify-content-lg-center text-center ${
                            !item.fullWidth
                              ? "align-items-lg-start text-lg-start"
                              : ""
                          }`}
                        >
                          {item.context ? (
                            <div
                              class={`small text-uppercase fw-bold ${
                                item.contextColor
                                  ? item.contextColor
                                  : "black-5"
                              }`}
                            >
                              {item.context}
                            </div>
                          ) : null}
                          <h3
                            class={`w-100 fw-${
                              item.titleWeight ? item.titleWeight : "light"
                            } ${item.titleColor ? item.titleColor : "black-1"}`}
                          >
                            {item.title}
                          </h3>
                          <p
                            class={`lead ${
                              item.descriptionColor
                                ? item.descriptionColor
                                : "black-1"
                            }`}
                          >
                            {formatDescription(item.description)}
                          </p>

                          {item.profileDisplayName ? (
                            <a
                              href={`/profile/${item.profileDisplayName}`}
                              target="_blank"
                              class="btn btn-outline-primary"
                              rel="noreferrer"
                            >
                              View profile &rarr;
                              {/* <FaArrowRight class="btn-arrow" /> */}
                            </a>
                          ) : null}
                          {item.buttonText && item.buttonPath ? (
                            <Link
                              href={item.buttonPath}
                              class={`btn ${
                                item.buttonClass
                                  ? item.buttonClass
                                  : "btn-outline-primary"
                              }`}
                            >
                              {item.buttonText} &rarr;
                              {/* <FaArrowRight class="btn-arrow" /> */}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {item.bullets ? (
                <div class={`row mb-3`}>
                  <div
                    class={`col-md-10 offset-md-1 ${
                      item.bulletclass ? item.bulletclass : ""
                    }`}
                  >
                    <ul class="w-100 mt-4 mx-2 p-2 list-unstyled">
                      {item.bullets.map((bullet) => (
                        <li class="text-center fw-light mb-3 line-height-2">
                          {bullet.title ? (
                            <div class="d-flex flex-row align-items-center justify-content-center">
                              <div class="alt-callout-bullet-title fw-bold">
                                {bullet.title}
                              </div>
                              {bullet.new ? (
                                <sup class="dkorange-1 outline">NEW</sup>
                              ) : null}
                            </div>
                          ) : null}
                          <div class="alt-callout-bullet-title">
                            {bullet.body}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {/* {item.lineClass ? (
            <DividerMid
              class={item.lineClass ? item.lineClass : "transparent-1"}
              line={true}
              // class="transparent-1"
              // bottomclass="transparent-4"
              // flip={false}
              // lineclass={item.lineClass}
              // lineoffset={4}
              // lineflip={true}
            />
          ) : null} */}
            </>
          ) : (
            <>
              {item.anchor ? <a id={item.anchor}></a> : null}
              <div
                class={`py-3 ${item.bgcolor ? `bg-${item.bgcolor}` : ""} ${
                  item.border ? item.border : ""
                } ${
                  item.bggradient ? `gradient-callout-${item.bggradient}` : ""
                }  d-flex align-items-center justify-content-center `}
              >
                <div
                  class={`container container-alt-callout d-flex align-items-center justify-content-center ${
                    item.bgcolor ? `m-0` : ""
                  }`}
                >
                  <div
                    class={`row feature-detail-reverse ${
                      !item.bgcolor ? `alt-callout` : ""
                    }`}
                  >
                    <div
                      class={`${
                        item.fullWidth ? "col-lg-12" : "col-lg-6"
                      } d-flex`}
                    >
                      <div
                        class={`feature-${i} me-0 me-lg-1 d-flex flex-column align-items-center justify-content-center flex-grow-1 ${
                          !item.fullWidth ? "align-items-lg-end" : ""
                        }`}
                      >
                        {/* {item.iconShow ? (
                          <div class="feature-icon-container feature-icon-container-right">
                            {item.iconContext ? (
                              <FeatureIcon
                                context={item.iconContext}
                                color={item.iconColor}
                                iconfill={item.iconfill}
                                iconcolor={item.iconcolor}
                              />
                            ) : (
                              <FeatureIcon
                                text={i + 1}
                                color={item.iconColor}
                                iconfill={item.iconfill}
                                iconcolor={item.iconcolor}
                              />
                            )}
                          </div>
                        ) : null} */}
                        <div
                          class={`d-flex flex-column align-items-center justify-content-center text-center ${
                            !item.fullWidth
                              ? "align-items-lg-end text-lg-end"
                              : ""
                          }`}
                        >
                          {item.context ? (
                            <div
                              class={`small text-uppercase fw-bold ${
                                item.contextColor
                                  ? item.contextColor
                                  : "black-5"
                              }`}
                            >
                              {" "}
                              {item.context}
                            </div>
                          ) : null}
                          <h3
                            class={`w-100 fw-${
                              item.titleWeight ? item.titleWeight : "light"
                            } ${item.titleColor ? item.titleColor : "black-1"}`}
                          >
                            {item.title}
                          </h3>
                          <p
                            class={`lead ${
                              item.descriptionColor
                                ? item.descriptionColor
                                : "black-1"
                            }`}
                          >
                            {formatDescription(item.description)}
                          </p>

                          {item.profileDisplayName ? (
                            <a
                              href={`/profile/${item.profileDisplayName}`}
                              target="_blank"
                              class="btn btn-outline-primary"
                              rel="noreferrer"
                            >
                              View profile
                              {/* <FaArrowRight class="btn-arrow" /> */}&rarr;
                            </a>
                          ) : null}
                          {item.buttonText && item.buttonPath ? (
                            <Link
                              href={item.buttonPath}
                              class={`btn ${
                                item.buttonClass
                                  ? item.buttonClass
                                  : "btn-outline-primary"
                              }`}
                            >
                              {item.buttonText} &rarr;
                              {/* <FaArrowRight class="btn-arrow" /> */}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      class={`${
                        item.expandcol ? `col-lg-${item.expandcol}` : "col-lg-4"
                      } d-flex align-items-center justify-content-center ${
                        item.image ? "alt-image-sizer" : ""
                      }`}
                    >
                      {item.image ? (
                        <IndustryImages
                          industry={item.image}
                          size=""
                          output="image"
                          className={`rounded-corners ${
                            item.imageShadow ? "shadow" : ""
                          } ${
                            item.imageBorderColor
                              ? `border-1-${item.imageBorderColor}`
                              : ""
                          }`}
                        />
                      ) : null}
                      {/* {item.videosource ? (
                    <div
                      class="d-flex align-items-center justify-content-center justify-content-lg-end p-4"
                      style={{ display: "flex" }}
                    >
                      <PreviewVideo
                        videosource={getVideoSource(item.videosource)}
                      />
                    </div>
                  ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
              {item.bullets ? (
                <div class={`row mb-3`}>
                  <div
                    class={`col-md-10 offset-md-1 ${
                      item.bulletclass ? item.bulletclass : ""
                    }`}
                  >
                    <ul class="w-100 mt-4 mx-2 p-2 list-unstyled">
                      {item.bullets.map((bullet) => (
                        <li class="text-center fw-light mb-3 line-height-2 Z">
                          {bullet.title ? (
                            <div class="d-flex flex-row align-items-center justify-content-center">
                              <div class="alt-callout-bullet-title fw-bold">
                                {bullet.title}
                              </div>
                              {bullet.new ? (
                                <sup class="dkorange-1 outline">NEW</sup>
                              ) : null}
                            </div>
                          ) : null}
                          <div class="alt-callout-bullet-title">
                            {bullet.body}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
              {/* {item.lineClass ? (
            <DividerMid
              class={item.lineClass ? item.lineClass : "transparent-1"}
              // bottomclass={item.bgcolor ? item.bgcolor : 'transparent-1'}
              line={true}
              // flip={false}
              // lineclass={item.lineClass}
              // lineoffset={4}
              // lineflip={false}
            />
          ) : null} */}
            </>
          )}
        </>
      );
    })}
  </>
);

export default Snippet;
