//React
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

//Packages
// import ReactHtmlParser from "react-html-parser";

//Components
// import RequestInviteModal from '../elements/requestInviteModal'

//Icons
// import FeatureIcon from './featureIcon'
import PriceComparisonFeaturesBase from "./priceComparisonFeaturesBase";
// import PricingStar from '../../images/svg/pricing/pricingicon-star.svg'
// import PricingRocket from '../../images/svg/pricing/pricingicon-rocket.svg'
// import PricingDiamond from '../../images/svg/pricing/pricingicon-diamond.svg'

// import { FaArrowRight } from "react-icons/fa";
// import { RiLayoutRight2Line, RiShareForwardFill } from "react-icons/ri";
// import { TiCloudStorage } from "react-icons/ti";

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const PricingCol = (props) => {
  const { item, pos, updatePurchasePlan, updatePurchasePositionNumber } = props;

  return (
    <div
      class={`pb-2 my-2 my-md-0 pricing-col pricing-col-${
        pos + 1
      } col-md-3 round-corners-top round-corners-bottom border-1-gray-5 d-flex flex-column justify-content-start align-items-center`}
    >
      {/* <div class="pricing-mobile-gradient round-corners-topX gradient-callout-fire"></div> */}
      {pos === 2 ? (
        <div
          class={`pricing-mobile-header pricing-mobile-gradient gradient-callout-cousteau white-1`}
        >
          Recommended
        </div>
      ) : null}

      <div class="pricing-package p-2 d-flex w-100 flex-row flex-md-column align-items-center align-items-md-stretch">
        <div class="pricing-package-container d-flex flex-column ">
          <div class="pricing-package-title text-center">{item.name}</div>
          <hr class="d-none d-md-block w-100" size="1" />
          <div class="pricing-package-label text-center">{item.desc}</div>
          <div class="pricing-price-container d-flex flex-column align-items-stretch justify-content-center">
            <div class={`pricing-price text-center ${item.color}-1`}>
              {item.price}
              {/* <div class="pricing-price-term gray-2">
                {item.package === "enterprise" ? "quote" : "per user/month"}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="pricing-cta mt-auto pb-4 w-100 d-flex align-items-center justify-content-center">
        <div class="d-flex flex-column">
          {item.package === "freeplanner" ? (
            <>
              <Link
                class={`btn btn-${item.color} mt-2`}
                to="/request-invite-free-tier/?c=pricing-freeplanner"
              >
                Request invite &rarr;
              </Link>
              {/* <div class="small gray-2 font-weight-light pt-3 text-center">
                Custom solution
              </div> */}
            </>
          ) : (
            <>
              <Link
                class={`btn btn-${item.color} mt-2`}
                to={`/request-consultation/?c=pricing-${item.package}`}
              >
                Contact us &rarr;
              </Link>
              {/* <div class="small gray-2 font-weight-light pt-3 text-center">
              Custom solution
            </div> */}
            </>
          )}
        </div>
      </div>

      {/* <hr class="w-100" size="1" /> */}

      {/* <div class="pricing-features d-flex flex-column align-items-start justify-content-start">
        {item.features.map((feature) => (
          <div class="pricing-feature">
            <div
              class={`pricing-feature-text ${
                feature.name.includes("+") ? "hangingindent" : ""
              }`}
            >
              {feature.name}
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};
const Snippet = (props) => {
  const size = useWindowSize();
  const { updatePurchasePlan, updatePurchasePositionNumber } = props;
  const pricingData = [
    {
      name: "Starter",
      package: "freeplanner",
      color: "sky",
      price: "Free",
      desc: "Gain experience and prove the value of DPPs",
      features: [
        { name: "10 GB asset storage" },
        { name: "Download rendered video files" },
        { name: "<b>Bronze</b> music library" },
        // { name: 'Emoji sticker library' },
        { name: "Email support" },
      ],
    },
    {
      name: "Essentials",
      package: "standard",
      color: "bluegreen",
      price: "Monthly subscription",
      // desc: 'Accelerate your workflow with expanded options',
      desc: "The essentials to create and publish cloud-based DPPs",

      features: [
        { name: "Industry-specific video templates" },
        { name: "<b>10 GB</b> asset storage" },
        { name: "<b>Download</b> rendered video files" },
        { name: "<b>Bronze</b> music library" },
        // { name: 'Emoji sticker library' },
        { name: "Email support" },
        // { name: '+ Custom brand stickers' },
      ],
    },
    {
      name: "Pro",
      package: "pro",
      color: "blue",
      price: "Monthly subscription",
      desc: "All you need to engage customers with AI-powered DPPs",
      features: [
        { name: "Industry-specific video templates" },

        { name: "<b>40 GB</b> asset storage" },
        { name: "<b>Download or host</b> rendered video files" },
        { name: "<b>Silver</b> music library" },
        // { name: 'Emoji sticker library' },
        { name: "Email support" },
        { name: "+ Custom brand stickers" },
        { name: "+ 3 hosted Watch Pages to drive action" },
        { name: "+ 30 GB/mo bandwidth for Watch Pages" },
      ],
    },
    {
      name: "Enterprise",
      package: "enterprise",
      color: "green",
      price: "Custom",
      desc: "Flexible, enterprise-grade options to accelerate your business with DPPs",
      features: [
        { name: "Industry-specific video templates" },

        { name: "Custom asset storage" },
        { name: "<b>Download or host</b> rendered video files" },
        { name: "<b>Gold</b> music library" },
        // { name: 'Emoji sticker library' },
        { name: "<b>Custom</b> support" },
        { name: "Custom brand stickers" },
        { name: "+ Hosted Watch Pages" },
        { name: "+ Bandwidth for Watch Pages" },
        { name: "+ Embed hosted videos in your site" },
        { name: "+ Add-on: Collect user-generated content" },
        { name: "+ Add-on: Collect SMS mobile numbers" },
        { name: "+ Add-on: Enable teams with private branded templates" },
        { name: "+ Add-on: Notify team members about template updates" },
      ],
    },
  ];

  return (
    <>
      <div class="container py-4">
        <div class="row d-flex flex-row justify-content-center align-items-stretch">
          {pricingData.map((item, i) => (
            // <div class="col-3">col-{item.pos}</div>
            <PricingCol
              item={item}
              pos={i}
              updatePurchasePlan={updatePurchasePlan}
              updatePurchasePositionNumber={updatePurchasePositionNumber}
            />
          ))}
        </div>
        <hr size={1} />

        <h2 class="text-center mt-4 py-3">
          Compare Digital Product Passport plans
        </h2>
        <div class="d-block d-sm-none small text-uppercase gray-1 text-center">
          &larr; Scroll &rarr;
        </div>
        <div class="pricing-features p-2 d-flex flex-row align-items-center justify-content-center">
          <PriceComparisonFeaturesBase />
        </div>
      </div>
    </>
  );
};

export default Snippet;
