//React
import React from "react";

//Gatsby

//Actions

//Components
import IndustryImages from "./industryImages";

//Icons

const Snippet = ({ image, title, desc, color, showArrow }) => (
  <div
    class={`post-item-featurecallout round-corners-top round-corners-bottom m-2 d-flex flex-column border-1-gray-7`}
  >
    <div class="post-link-image">
      {image ? (
        <IndustryImages industry={image} size="" output="image" />
      ) : null}
    </div>

    <div
      className={`post-text-link d-flex flex-grow-1 flex-column align-items-start justify-content-start
`}
    >
      <div>
        <span class={`post-text ${color ? color : ""}`}>{title}</span>
        {showArrow ? (
          <span class={`post-text ${color ? color : ""}`}>&rarr;</span>
        ) : null}
        {/* <FaArrowRight size={18} class="black-4 ms-2" /> */}
      </div>
      <div class={`post-text-desc mt-2 ${color ? color : ""}`}>{desc}</div>
    </div>
  </div>
);

export default Snippet;
