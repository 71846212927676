//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import PostTemplateCtaStatic from "../../components/marketing/postTemplateCtaStatic";
import BlogCallout from "../../components/marketing/blogCallout";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Experience creation",
    contextColor: "yellow-1",
    title: "Create branded loyalty tokens",
    description:
      "Design loyalty tokens with an integrated design tool and no-code minting - without having to hire external smart contract developers",
    image: "nft1LoyaltyB",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Loyalty benefits",
    contextColor: "pink-1",
    title: "Award and redeem loyalty benefits",
    description:
      "Attach benefits to your loyalty tokens, and allow customers to redeem them from their phones - through QR codes, coupon codes, and more",
    image: "nftLoyaltyRedeem",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "pink",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },
  {
    context: "Social impact",
    contextColor: "sky-1",
    title: "Engage customers with social impact",
    description:
      "Use loyalty tokens as part of modern programs that attract new Gen Z / Millennial customers - including by supporting social impact causes",
    image: "nft2LoyaltyImpactB",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },

  {
    context: "Token-powered communities",
    contextColor: "green-1",
    title: "Harness the power of community",
    description:
      "Extend the customer conversation with brand communities that provide exclusive content and perks in a brand-safe environment",
    image: "nft3LoyaltyA",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-3',
  },
  {
    context: "Direct customer interaction",
    contextColor: "dkblue-1",
    title: "Own your customer relationships",
    description:
      "Interact with your customers directly, without intermediaries, using token-gated communities, opt-in SMS text messaging, and other capabilities",
    image: "nft3LoyaltyC",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'gray-5',
    anchor: "mobile",
    // videosource: 'console-3',
  },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: true,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-tropical py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Find and engage new customers with blockchain loyalty programs
                  {/* Rally your community and raise funds for social causes */}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Customer Loyalty</h1>

              <p class="lead">
                Tap into new customer communities and engage your current
                audience with exclusive loyalty programs
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />

      <hr size={1} />
      <div class="mx-3 mx-lg-5">
        <div class="my-2 d-flex flex-column ">
          <PostTemplateCtaStatic
            title="Discover PicoNext Loyalty features"
            link="/platform/loyalty/"
            cta="See features"
            gradient="sunset"
          />
        </div>
      </div>
      {/* <hr size={1} /> */}

      <BlogCallout
        context="loyalty"
        title="Loyalty Resources"
        desc="Articles to help you engage customers using tokenized loyalty
        programs"
        showMoreButton={true}
      />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Branded Web3 tokens for Customer Loyalty"
      description="Attract Gen Z and Millennial customers with Web3 tokens for modern customer loyalty programs"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/resortsplus-usecases/francepass-wallet-chemoi-restaurant-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    blogs: allMdx(
      sort: { frontmatter: { date: DESC } }
      filter: {
        frontmatter: { path: { glob: "/article/*" }, publish: { eq: 1 } }
        id: {
          in: [
            "b491e71f-12da-596d-8319-416ac261bb91"
            "3a8c6ace-c218-5b22-98a5-b61e3ae42888"
          ]
        }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
