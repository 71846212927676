//React
import React, { useEffect, useState } from "react";

//Packages
import parse from "url-parse";

//Components
import Layout from "../components/layoutLanding";
import { Metatags } from "../components/marketing/metatags";

import ContactLeadForm from "../components/marketing/contactLeadForm";

const Snippet = (props) => {
  const [campaignId, setCampaignId] = useState("");
  useEffect(() => {
    const url = parse(window.location.href, true);

    console.log("request-demo/index - url");
    console.log(url);

    if (url.query.c) {
      console.log("request-demo/index - url.query.c");
      console.log(url.query.c);

      setCampaignId(url.query.c);
    }
  }, []);

  return (
    <Layout>
      <div class="flex-grow-1">
        <ContactLeadForm
          title="Request demo"
          subtitle="See how PicoNext can help you verify your content and protect your brand using blockchains"
          cta="Submit"
          leadSource={`request-demo-content-auth${
            campaignId ? `-${campaignId}` : ""
          }`}
          context="auth-content"
        />
      </div>
    </Layout>
  );
};

export default Snippet;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Request Demo"
      description="See how PicoNext can help you verify your content and protect your brand using blockchains"
      // image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};
