//React
import React from "react";
import { Link } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";

// import { FaArrowRight } from "react-icons/fa";

const Snippet = ({ mode, containerClass, subClass }) => (
  <div class={containerClass ? containerClass : ""}>
    <div
      class={`post-template-cta my-2 mb-4 px-3 pt-5 rounded-corners border-2-dkblue-1 d-flex flex-column ${
        subClass ? subClass : ""
      }`}
    >
      <div class="row d-flex flex-row align-self-center align-items-center justify-content-center">
        <div class="callout-home-overview-text col-lg-7 offset-lg-1 rounded-corners text-center my-4 p-2 py-4 d-flex flex-column align-items-center justify-content-center">
          <div class="small text-uppercase fw-bold dkblue-1 mt-4 mb-2">
            New Report
          </div>
          <h2 class="text-center fw-bold mb-4 black-1">
            Digital Product Passport
            <br />
            <span class="fw-light gray-1">Global Sustainability Study</span>
          </h2>
          {mode !== "short" ? (
            <p class="border-top-1-white-3 p-4 p-lg-0 pt-lg-3 lead text-center black-1 line-height-4">
              In this free report, see latest stats from our global research on
              how transparency in sustainability affects consumer attitudes
            </p>
          ) : null}
          {/* <p class="border-top-1-white-3 pt-3 lead text-center display-4 white-1">
              See the latest survey research on engaging Gen Z + Millennials
              with Web3
            </p> */}
          {/* <FeaturePreviewSectionPlatform short={true} /> */}

          <Link
            to="/landing/digital-product-passport-sustainability"
            class="btn btn-primary btn-cta my-3 align-self-center"
          >
            Download {mode === "short" ? "free report " : ""}now &rarr;
            {/* <FaArrowRight class="btn-arrow" /> */}
          </Link>
        </div>
        <div
          class="col-lg-4 mt-3 p-2 py-4 d-none d-lg-flex align-items-start justify-content-center"
          // style={{ minHeight: 640 }}
        >
          <StaticImage
            src="../../images/landing/202311-dpp-sustainability/report-dpp-survey-cover.jpg"
            alt="PicoNext"
            loading="lazy"
            placeholder="blurred"
            layout="constrained"
            width={160}
            // className="shadow-sm"
          />
        </div>
      </div>
    </div>
  </div>
);
export default Snippet;
