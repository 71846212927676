//React
import React from "react";

//Gatsby
import { StaticImage } from "gatsby-plugin-image";

//Icons

const Snippet = ({ content, quotes, sub, contentClass, containerClass }) => (
  <div
    class={`post-callout round-corners-top round-corners-bottom mb-4 ${
      containerClass ? containerClass : ""
    }`}
  >
    <div class="card card-callout card-callout-text">
      <div
        class={`card-body d-flex flex-column align-items-lg-center justify-content-lg-center text-center text-lg-center ${
          quotes ? "" : "py-5"
        }`}
      >
        {quotes ? (
          <div class="callout-quote-icon-start">
            <StaticImage
              src="../../images/customers/quote-start.png"
              alt="Starting quote"
              loading="lazy"
              placeholder="blurred"
              // layout="fullWidth"
              width={25}
              height={20}
            />
          </div>
        ) : null}
        <div class={`mt-1 callout-quote ${contentClass ? contentClass : ""}`}>
          {content && Array.isArray(content)
            ? content.map((item) => <div class="callout-item mb-3">{item}</div>)
            : typeof content === "string"
            ? content
            : null}
        </div>
        {quotes ? (
          <div class="callout-quote-icon-end">
            <StaticImage
              src="../../images/customers/quote-end.png"
              alt="Ending quote"
              loading="lazy"
              placeholder="blurred"
              // layout="fullWidth"
              width={25}
              height={20}
            />
          </div>
        ) : null}

        {sub
          ? sub.map((item) => <div class="callout-quote-sub">{item}</div>)
          : null}
      </div>
    </div>
  </div>
);
export default Snippet;
