//React
import React from "react";

//Gatsby
import { Link } from "gatsby";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const Snippet = ({ title, cta, link, gradient }) => (
  <div
    class={`post-template-link my-3 px-3 py-4 rounded-corners gradient-callout-${
      gradient ? gradient : "algae"
    } d-flex flex-column`}
  >
    <h4 class="white-1 fw-light text-center">{title}</h4>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <Link class="btn btn-cta-outline mt-3" to={link}>
        {cta} &rarr;
        {/* <FaArrowRight /> */}
      </Link>
    </div>
  </div>
);
export default Snippet;
