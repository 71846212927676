//React
import React from "react";

//Gatsby
import { useStaticQuery, graphql } from "gatsby";

//Actions

//Components
import PostLink from "../marketing/postLink";

//Icons

export default function Snippet() {
  const data = useStaticQuery(graphql`
    query {
      customers: allMdx(
        sort: { frontmatter: { sortOrder: ASC } }
        filter: {
          frontmatter: {
            path: { regex: "/simplechic|themorphbag|bonandberg|tammam/" }
            publish: { eq: 1 }
            sortOrder: { gt: 0 }
          }
        }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              dateVal: date(formatString: "YYYY-MM-DD")
              updated(formatString: "MMMM DD, YYYY")
              path
              title
              publish
              featuredImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
              tileTitle
              tileTag
              tileImage {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 300)
                }
              }
            }
          }
        }
      }
    }
  `);
  console.log("dppExamplesBlog data--");
  console.log(data);

  let customerPosts;
  if (data && data.customers) {
    // let customerLimit = 1
    customerPosts = data.customers.edges;
    // customerPosts = props.data.customers.edges.slice(0, customerLimit)
    console.log("customerPosts");
    console.log(customerPosts);
    // let blogLimit = customerPosts.length > 0 ? 2 : 3
    // UNCOMMENT LINE BELOW WHEN NEEDING TO LIMIT POSTS (i.e. not hand selecting them)
    // also add this to graphql: limit: 3
    // blogPosts = props.data.blogs.edges.slice(0, blogLimit)
    // console.log('blogPosts')
    // console.log(blogPosts)
    // mergedPosts = [...blogPosts]
    // mergedPosts = [...customerPosts, ...blogPosts]
    // mergedPosts.sort((a, b) => (a.dateVal > b.dateVal ? -1 : 1))
  }

  const CustomerPosts = customerPosts
    // .filter(
    //   (edge) =>
    //     edge.node.frontmatter.title === 'The Morphbag' ||
    //     edge.node.frontmatter.title === 'Simple Chic' ||
    //     edge.node.frontmatter.title === "Tomorrow's Air"
    //   // ||
    //   // edge.node.frontmatter.date === 'March 14, 2023'
    //   // edge.node.frontmatter.date && edge.node.frontmatter.publish === 1
    // ) // You can filter your posts based on some criteria
    .map((edge) => (
      <PostLink home={true} key={edge.node.id} post={edge.node} />
    ));

  return (
    <div className="row d-flex flex-row align-items-stretch justify-content-center">
      {CustomerPosts}
    </div>
  );
}
