//React
import React from "react";
import { Link } from "gatsby";

// import { FaArrowRight } from "react-icons/fa";

// import { RiImageEditFill, RiCoinLine, RiHandHeartLine } from "react-icons/ri";
// import {
//   MdGroups,
//   MdEditCalendar,
//   MdOutlinePublic,
//   MdDataSaverOff,
// } from "react-icons/md";
// import { TbLayoutGridAdd } from "react-icons/tb";

const Snippet = (props) => (
  <div class="container my-4 d-block d-lg-flex justify-content-stretch d-xl-block">
    {/* <div class="row d-none d-xl-flex flex-row align-items-center justify-content-center">
      <div class="platform-col-header platform-col-header-1 d-flex flex-row small text-uppercase fw-bold gray-1">
        <div>Create</div>
        <div class="platform-col-header-line bg-gray-5 flex-grow-1"></div>
      </div>
      <div class="platform-col-header platform-col-header-2 d-flex flex-row small text-uppercase fw-bold gray-1">
        <div>Attract</div>
        <div class="platform-col-header-line bg-gray-5 flex-grow-1"></div>
      </div>
    </div> */}
    <div class="row-platform-link row d-flex flex-row align-items-stretch justify-content-center ">
      {/* <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/create-web3/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            <RiImageEditFill size={25} class="gray-3 m-2" />
            <div class="platform-element-container d-flex flex-row">
              Design
            </div>
          </div>
          <div class="platform-col-desc border-2-yellow-1 yellow-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Design multimedia experience mementos from templates
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Template library</li>
                  <li>Scenes &amp; layers</li>
                  <li>Asset libraries</li>
                  <li>Stock images + audio</li>
                  <li>Permissions</li>
                  <li>Stickers</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/create-web3/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link> */}

      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/digital-product-passport-planner/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <RiImageEditFill size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              DPP Planner
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Gather DPP sustainability data using templates
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Sustainability attributes</li>
                  <li>Industry templates</li>
                  <li>Rich-text editor</li>
                  <li>Media management</li>
                  <li>Stock photos + icons</li>
                  <li>Blockchain integration</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/digital-product-passport-planner/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>

      {/* <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/web3-onboarding/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            <RiDashboard3Line size={25} class="gray-3 m-2" />
            <div class="platform-element-container d-flex flex-row">
              Onboarding
            </div>
          </div>
          <div class="platform-col-desc border-2-dkgreen-1 green-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Ramp users into your project and remove friction points
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Customized onboarding pages</li>
                  <li>Wallet download rapid links</li>
                  <li>Wallet address acquisition</li>
                  <li>User response prompts</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/web3-onboarding/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link> */}
      {/* <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/personalize-web3/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            <FaUserAstronaut size={25} class="gray-3 m-2" />
            <div class="platform-element-container d-flex flex-row">
              Personalize
            </div>
          </div>
          <div class="platform-col-desc border-2-green-1 green-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Create hyper-personalized Web3 experiences
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Personalized Web3 tokens with user media</li>
                  <li>Branded container templates</li>
                  <li>Content moderation</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/personalize-web3/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link> */}
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/generative-ai/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item gradient-callout-overcast d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <MdGroups size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              Generative AI
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Summarize sustainability data into DPPs using AI
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Load documents &amp; Web sites </li>
                  <li>Summarize data using AI</li>
                  <li>Fine-tune output</li>
                  <li>Customize AI settings</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/communities/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/create-dpp/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <RiCoinLine size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              {/* Minting */}
              {/* Blockchain/Cloud DPPs */}
              DPP Publishing
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Publish DPPs in the cloud or on a blockchain
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Ethereum + Polygon blockchains</li>
                  <li>Single & Multi copy</li>
                  <li>Smart contract: standard or custom</li>
                  <li>Direct address minting</li>
                  <li>Off-chain minting</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/create-dpp/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/digital-product-passport-data-events/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <MdEditCalendar size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              DPP Data Events
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Attach sustainability data to your blockchain DPP
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Send token data events</li>
                  <li>On-chain message customization</li>
                  <li>Event templates</li>
                  <li>Event aggregation &amp; accountability</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/digital-product-passport-data-events/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>
    </div>
    {/* <div class="row d-none d-xl-flex flex-row align-items-center justify-content-center">
      <div class="platform-col-header platform-col-header-3 d-flex flex-row small text-uppercase fw-bold gray-1">
        <div>Engage</div>
        <div class="platform-col-header-line bg-gray-5 flex-grow-1"></div>
      </div>
    </div> */}
    <div class="row-platform-link row d-flex flex-row align-items-stretch justify-content-center ">
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/token-collections/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <TbLayoutGridAdd size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              Token Collections
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Group, organize, and display DPPs for your customers
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Send token data events</li>
                  <li>On-chain message customization</li>
                  <li>Event templates</li>
                  <li>Event aggregation &amp; accountability</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/digital-product-passport-data-events/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/token-explorer/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <MdOutlinePublic size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              Token Explorer
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Explore DPP data &amp; loyalty rewards
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>In-wallet token exploration</li>
                  <li>Upcoming loyalty rewards</li>
                  <li>Reward redemption rapid links</li>
                  <li>Token events display</li>
                  <li>Off-chain token visualization</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/token-explorer/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>

      {/* <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/communities/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            <div class="platform-element-container blue-1 d-flex flex-row">
              Community
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 dkblue-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Curate gated brand content for DPP holders
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Turnkey, branded landing pages </li>
                  <li>Customer authentication</li>
                  <li>Content curation</li>
                  <li>Mobile SMS number collection</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/communities/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link> */}
      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/loyalty/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <RiHandHeartLine size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              Loyalty
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Enhance DPPs with loyalty rewards for customers
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  {/* <li>Customer onboarding</li> */}
                  <li>Loyalty token issuance</li>
                  <li>Loyalty token redemption</li>
                  <li>Cloud-based token issuance/redemption</li>
                  <li>Programmatic impact donations</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/loyalty/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>

      <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/permissions-data-management/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            {/* <MdDataSaverOff size={25} class="gray-3 m-2" /> */}
            <div class="platform-element-container blue-1 d-flex flex-row">
              {/* <CgFormatSlash
                size={32}
                style={{ marginRight: -8, marginTop: -2 }}
              /> */}
              APIs &amp; Data
            </div>
          </div>
          <div class="platform-col-desc border-top-1-gray-7 gray-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Create branded DPPs with data APIs
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Turnkey, branded landing pages </li>
                  <li>Customer authentication</li>
                  <li>Content curation</li>
                  <li>Mobile SMS number collection</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/communities/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More &rarr;
                  {/* <FaArrowRight class="btn-arrow" /> */}
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link>
      {/* <Link
        class="platform-col-link d-flex flex-column justify-content-stretch"
        to="/platform/branded-web3-marketplaces/"
      >
        <div class="platform-col d-flex flex-column flex-grow-1">
          <div class="gradient-callout-platform-item d-flex flex-column align-items-center justify-content-center round-corners-top px-2 py-3 h4 platform-element-title white-1 text-center mb-0">
            <IoBagHandleOutline size={25} class="gray-3 m-2" />
            <div class="platform-element-container d-flex flex-row">
              Marketplace
            </div>
          </div>
          <div class="platform-col-desc border-2-bluegreen-1 bluegreen-1 round-corners-bottom p-2 py-3 line-height-2 fw-normal text-center">
            Distribute utility tokens with a branded marketplace
          </div>
          {props.short !== true ? (
            <>
              <div class="platform-col-features my-3">
                <ul>
                  <li>Custom-branded marketplace</li>
                  <li>Token merchandising</li>
                  <li>Price &amp; supply customization</li>
                  <li>Token transfer via smart contract</li>
                </ul>
              </div>
              <div class="d-flex align-items-center justify-content-center mb-3 mt-auto">
                <Link
                  to="/platform/branded-web3-marketplaces/"
                  class="btn btn-primary btn-cta mb-1 "
                  activeClassName="btn btn-primary btn-cta"
                >
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </Link> */}
    </div>
  </div>
);

export default Snippet;
