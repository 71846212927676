const axios = require("axios");

export function updateAgreementErrors(agreementErrors) {
  return (dispatch) => {
    dispatch({
      type: "AGREEMENT_ERROR_UPDATE",
      data: agreementErrors,
    });
  };
}

export function activateUserFreetier(dc, id) {
  let apiUrl;
  if (dc === "eu") {
    console.log("activateAgreement - configure - eu");
    // Amplify.configure(amplifyconfig_eu)
    apiUrl = process.env.GATSBY_API_URL_GBL_EU;
  } else {
    // Amplify.configure(amplifyconfig)
    apiUrl = process.env.GATSBY_API_URL_GBL;
  }
  return async (dispatch) => {
    dispatch({
      type: "LOADING_START",
    });

    let options = {
      id,
    };

    try {
      const response = await axios.post(
        `${apiUrl}user/invite/activate`,
        options
      );

      console.log("((actions - activateUserFreetier - response))");
      console.log(response);

      const data = response?.data || {};
      console.log("((actions - activateUserFreetier - data))");
      console.log(data);
      dispatch({
        type: "USER_ACTIVATION_UPDATE",
        data: data,
      });
    } catch (error) {
      dispatch({
        type: "USER_ACTIVATION_ERROR_UPDATE",
        data: error.response,
      });
    }
  };
}
export function getActivationOrder(dc, id) {
  // let apiName = 'Picovideogbl'
  // let path = 'agreement/retrieve'
  // let options = {
  //   queryStringParameters: {
  //     id,
  //     dc,
  //   },
  // }
  let apiUrl;
  if (dc === "eu") {
    console.log("getActivationOrder - configure amplify - eu");
    // Amplify.configure(amplifyconfig_eu)
    apiUrl = process.env.GATSBY_API_URL_GBL_EU;
  } else {
    // Amplify.configure(amplifyconfig)
    apiUrl = process.env.GATSBY_API_URL_GBL;
  }
  return async (dispatch) => {
    dispatch({
      type: "LOADING_START",
    });

    let options = {
      queryStringParameters: {
        id,
        dc,
      },
    };

    try {
      const response = await axios.post(`${apiUrl}agreement/retrieve`, options);

      console.log("((actions - getActivationOrder - response))");
      console.log(response);

      const data = response?.data || {};
      console.log("((actions - getActivationOrder - data))");
      console.log(data);

      dispatch({
        type: "AGREEMENT_ORDER_UPDATE",
        data: data,
      });
    } catch (error) {
      window.scrollTo(0, 0);
      dispatch({
        type: "AGREEMENT_ERROR_UPDATE",
        data: error.response,
      });
    }
    // new Promise((resolve) => {
    //   console.log("getActivationOrder - get - start");
    //   API.get(apiName, path, options)
    //     .then((response) => {
    //       const data = response || {};
    //       console.log("((actions - getActivationOrder))");
    //       console.log(data);
    //       return resolve(
    //         dispatch({
    //           type: "AGREEMENT_ORDER_UPDATE",
    //           data: data,
    //         })
    //       );
    //     })
    //     .catch((error) => {
    //       console.log("getActivationOrder - get - error");
    //       console.log(error);
    //       window.scrollTo(0, 0);
    //       dispatch({
    //         type: "AGREEMENT_ERROR_UPDATE",
    //         data: error.response,
    //       });
    //     });
    // }); //end promise
  };
}
