import React from "react";

import { Link } from "gatsby";

//Components
// import SuccessAnimation from "../animation/successAnimation";

//Packages
// import { CopyToClipboard } from "react-copy-to-clipboard";

//Icons
// import { IoMailUnreadOutline, IoPeopleOutline } from "react-icons/io5";
// import { BsBookmarkHeart } from "react-icons/bs";
// import { FiLogIn } from "react-icons/fi";
// import { RiMessage2Line } from "react-icons/ri";
// import { HiOutlineClipboardCheck } from "react-icons/hi";

const Snippet = (props) => {
  const {
    emailInput,
    provisionedUsersNumber,
    outboundReferralCode,
    copiedToClipboard,
    // setCopiedToClipboard,
  } = props;

  console.log("outboundReferralCode");
  console.log(outboundReferralCode);
  return (
    <>
      {copiedToClipboard ? (
        <div class="copied-clipboard fade-in rounded-corners gradient-callout-algae white-1 small px-4 py-3">
          Copied code to clipboard
        </div>
      ) : null}

      <div class="row mt-1 d-flex align-items-center justify-content-center">
        <div class="col-md-7 col-lg-5">
          <hr size={1} />

          {/* <SuccessAnimation /> */}
          <div class="d-flex align-items-center justify-content-center w-100">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 53.3 53.3"
              class="fill-green-1"
              style={{ width: 70 }}
            >
              <path
                class="cls-1"
                d="M22.7,38.8l17.9-17.9-3.6-3.6-14.4,14.4-7.3-7.3-3.6,3.6,10.8,10.8ZM26.2,52.6c-3.5,0-6.8-.7-9.9-2-3.1-1.3-5.8-3.1-8.1-5.4s-4.1-5-5.4-8.1S.8,30.6.8,27.1s.7-6.8,2-9.9,3.1-5.8,5.4-8.1c2.3-2.3,5-4.1,8.1-5.4s6.4-2,9.9-2,6.8.7,9.9,2,5.8,3.1,8.1,5.4,4.1,5,5.4,8.1c1.3,3.1,2,6.4,2,9.9s-.7,6.8-2,9.9-3.1,5.8-5.4,8.1-5,4.1-8.1,5.4-6.4,2-9.9,2ZM26.2,47.5c5.7,0,10.5-2,14.4-5.9s5.9-8.8,5.9-14.4-2-10.5-5.9-14.4-8.8-5.9-14.4-5.9-10.5,2-14.4,5.9-5.9,8.8-5.9,14.4,2,10.5,5.9,14.4,8.8,5.9,14.4,5.9Z"
              />
            </svg>
          </div>
          <div class="h3 my-2 text-center">Congratulations!</div>

          <p class="lead text-center line-height-3 pb-2">
            You can now start engaging your customers using PicoNext.
          </p>
          <div class="purchase-success-next-steps mt-4 fw-light line-height-2">
            <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
              <div class="purchase-success-next-step-icon">
                {/* <IoMailUnreadOutline size={32} class="dkblue-1" /> */}
              </div>
              <div>
                We've sent a welcome email to{" "}
                <span class="fw-normal">{emailInput}</span> with your program
                details
                {/* with your
                account details and password */}
              </div>
            </div>

            <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
              <div class="purchase-success-next-step-icon">
                {/* <BsBookmarkHeart
                  size={32}
                  class="pink-1"
                  style={{ marginTop: 3 }}
                /> */}
              </div>
              <div>
                Learn more on how to get started with PicoNext on our{" "}
                <a
                  href={process.env.SUPPORT_DOMAIN}
                  target="_blank"
                  rel="noreferrer"
                >
                  Support &amp; Learning pages
                </a>
              </div>
            </div>
            {provisionedUsersNumber > 1 ? (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  {/* <IoPeopleOutline
                    size={32}
                    class="purple-1"
                    style={{ marginTop: 3 }}
                  /> */}
                </div>
                <div>
                  You can add other users to your account in the Account
                  Settings section of the{" "}
                  <a
                    href={process.env.CONSOLE_DOMAIN}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PicoNext console
                  </a>
                </div>
              </div>
            ) : outboundReferralCode ? (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  {/* <RiMessage2Line
                    size={32}
                    class="dkgreen-1"
                    style={{ marginTop: 3 }}
                  /> */}
                </div>
                <div>
                  Share your referral code below to earn special benefits and
                  perks with PicoNext!{" "}
                  <Link to="/about/referral/">Learn more</Link>
                  <br />
                  <br />
                  <span class="fw-normal">
                    {outboundReferralCode.replace(
                      /(.{3})(.{3})(.{3})/,
                      "$1-$2-$3"
                    )}
                  </span>
                  {/* <CopyToClipboard
                    text={`Create short-form videos to engage your audience at PicoNext - Use code: ${outboundReferralCode.replace(
                      /(.{3})(.{3})(.{3})/,
                      "$1-$2-$3"
                    )}`}
                    onCopy={() => {
                      setCopiedToClipboard(true);
                      setTimeout(() => {
                        setCopiedToClipboard(false);
                      }, 8000);
                    }}
                  >
                    <button class="ms-3 btn btn-sm btn-outline-success">
                      Copy code{" "}
                      <HiOutlineClipboardCheck
                        size={16}
                        style={{ marginTop: -4 }}
                        class="copy-icon"
                      />
                    </button>
                  </CopyToClipboard> */}
                </div>
              </div>
            ) : (
              <div class="purchase-success-next-step-item d-flex flex-row align-items-start justify-content-center">
                <div class="purchase-success-next-step-icon">
                  {/* <FiLogIn
                    size={32}
                    class="purple-1"
                    style={{ marginTop: 3 }}
                  /> */}
                </div>
                <div>
                  {/* Get started by signing in to the{' '} */}
                  You can access the{" "}
                  <a
                    href={process.env.CONSOLE_DOMAIN}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PicoNext console
                  </a>{" "}
                  to start engaging your customers with DPPs
                  {/* with the details in your welcome email */}
                </div>
              </div>
            )}
          </div>
          <hr size={1} />
        </div>
      </div>
    </>
  );
};

export default Snippet;
