import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/js/bootstrap.min";
// import "bootstrap/js/dropdown.js";

import wrapWithProvider from "./wrap-with-provider";
export const wrapRootElement = wrapWithProvider;

const initGTM = () => {
  if (window.isGTMLoaded) {
    return false;
  }

  window.isGTMLoaded = true;

  const script = document.createElement("script");

  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_TRACKING_ID}`;

  script.onload = () => {
    // window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", `${process.env.GATSBY_GA_TRACKING_ID}`);
  };

  document.head.appendChild(script);
};

const loadGTM = (event) => {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
};

export const onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      setTimeout(initGTM, 3500);
    }
  };

  document.addEventListener("scroll", loadGTM);
  document.addEventListener("mousemove", loadGTM);
  document.addEventListener("touchstart", loadGTM);
};
