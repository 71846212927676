const axios = require("axios");

export function saveSubscribe(email, mode, subscription, access, dc) {
  // let apiName = 'Picovideogbl'
  // let path = 'subscribe'
  // let options = {
  //   body: { email, mode, subscription, access, dc },
  // }
  // console.log('actions- saveSubscribe - email')
  // console.log(email)

  let apiUrl;

  if (dc && dc === "eu") {
    console.log("saveSubscribe - configure - eu");
    apiUrl = process.env.GATSBY_API_URL_GBL_EU;
    // Amplify.configure(amplifyconfig_eu)
  } else {
    // Amplify.configure(amplifyconfig)
    apiUrl = process.env.GATSBY_API_URL_GBL;
  }
  return async (dispatch) => {
    dispatch({
      type: "SUBSCRIBE_LOADING_START",
    });

    let options = { email, mode, subscription, access, dc };

    try {
      const response = await axios.post(`${apiUrl}subscribe`, options);

      console.log("((actions - saveSubscribe - response))");
      console.log(saveSubscribe);

      const data = response?.data || {};
      console.log("((actions - saveSubscribe - data))");
      console.log(data);
      dispatch({
        type: "SUBSCRIBE_SUBMITTED",
        data: data,
      });
      setTimeout(() => {
        dispatch({
          type: "FIELD_CHANGE",
          data: { name: "subSubmitted", val: false },
        });
      }, 12000);
    } catch (error) {
      console.log(error.response);
    }
    // new Promise((resolve) => {
    //   API.post(apiName, path, options)
    //     .then((response) => {
    //       const data = response || {}
    //       console.log('((actions - saveSubscribe))')
    //       console.log(data)
    //       dispatch({
    //         type: 'SUBSCRIBE_SUBMITTED',
    //         data: data,
    //       })
    //       return resolve(
    //         setTimeout(() => {
    //           dispatch({
    //             type: 'FIELD_CHANGE',
    //             data: { name: 'subSubmitted', val: false },
    //           })
    //         }, 12000)
    //       )
    //     })
    //     .catch((error) => {
    //       console.log(error.response)
    //     })
    // }) //end promise
  };
}
