//React
import React from "react";

//Gatsby

//Actions

//Components

//Icons

const Snippet = () => (
  <div class="d-flex flex-row align-items-center justify-content-center mt-3 ">
    <div class="w-75 gray-4 text-legal text-center line-height-1">
      We'll use your email to send you the information you request, as well as
      send occasional updates on Digital Product Passport innovations and
      regulations. You can unsubscribe at any time. For more information on how
      we process your personal data, view our{" "}
      <a href="/about/privacy" class="gray-4" target="_blank">
        Privacy Policy
      </a>
      .
    </div>
  </div>
);

export default Snippet;
