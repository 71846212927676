//React
import React from "react";

export const Metatags = ({ title, description, image, keywords }) => (
  <>
    {title ? (
      <>
        <title>{title} - PicoNext</title>
        <meta name="og:title" content={`${title} - PicoNext`} />
        <meta name="twitter:title" content={`${title} - PicoNext`} />
      </>
    ) : null}
    {description ? (
      <>
        <meta name="description" content={description} />
        <meta name="twitter:description" content={description} />
      </>
    ) : null}

    <meta
      name="keywords"
      content={
        keywords
          ? keywords
          : `Digital Product Passport, European Union, sustainability, blockchain, environment, social impact`
      }
    />
    <meta name="author" content={`PicoNext`} />
    {image ? (
      <>
        <meta name="og:image" content={image} />
        <meta name="twitter:image" content={image} />
      </>
    ) : null}

    <meta name="og:site_name" content={`PicoNext`} />
    <meta name="og:type" content={`website`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@piconexthq" />
  </>
);
// export default Snippet;
