//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeatureLink from "../../components/marketing/featureLink2";
import YoutubeComponent from "../../components/marketing/youtubeComponent";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const capabilityData = {
  // titleelement: 'h2',
  // title: 'PicoNext capabilities',
  // titlecolor: 'black-4',
  // subtitle:
  //   'PicoNext helps you attract new Gen Z + Millennial customers, and build branded communities with an end-to-end, no-code NFT platform',
  mode: "quad",
  direction: "column",
  stretch: true,
  // bggradient: 'gradient-callout-algae',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: "Gather product sustainability data",
          description:
            "Collect and save product sustainability data using an extensive attribute library and pre-formed DPP templates for fashion, batteries, UN Sustainability Goals and more",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner1",
        },
        {
          title: "Manage products",
          description:
            "Add and delete products associated with your Digital Product Passports, and easily clone and modify items to speed your workflow",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner2",
        },
        {
          title: "Categorize data points",
          description:
            "Group sustainability events by category, allowing customers to more easily view lengthy Digital Product Passports",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutWalletExplorer7",
        },
        {
          title: "Save custom DPP templates",
          description:
            "Speed DPP creation by saving custom sustainability data templates that align to your specific products and industry requirements",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner3",
        },

        {
          title: "Manage private DPP data access",
          description:
            "Make certain DPP data events available to stakeholders with approved access - like suppliers, distributors, or government authorities",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutEventAccess1",
        },
        {
          title: "Update DPP info on an item or batch level",
          description:
            "Attach item- or manufacturing batch-level data to your DPP, and define data schemas for managing granular information",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutItemBatch1",
        },

        {
          title: "DPP Planner Elements",
          description:
            "Use repeatable groups of sustainability attributes across products in DPP Planner to simplify data management",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutTransparency7",
        },

        {
          title: "Record event dates",
          description:
            "Add dates to your data points for when activities occured, and note those dates on the Digital Product Passport for customers",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutTransparency5",
        },
        {
          title: "Engage customers with media",
          description:
            "Bring your Digital Product Passport to life through images, links to external Web sites (including third-party certifications), text formatting, and more using an integrated rich-text editor",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner4",
        },
        {
          title: "Accelerate visual DPP workflows",
          description:
            "Use an integrated asset manager to insert brand-specific media, add stock photos, and insert visual icons to illustrate your DPP data",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner5",
        },
        {
          title: "Quickly publish with blockchain integration",
          description:
            "Quickly publish your DPP using integrated Minting and Data Events capabilities, directly from the Digital Product Passport Planner ",
          // colbgcolor: 'sky-1',
          colbggradient: "gradient-callout-silver",
          // colbordercolor: 'sky-1',
          titlecolor: "black-4",
          desccolor: "black-3",
          iconcolor: "sky",

          iconname: "collection",
          image: "calloutPlanner6",
        },
        // {
        //   title: 'Mitigate AI deepfake content',
        //   description:
        //     "Use public ledgers to authenticate your branded content, and protect your brand's reputation from deepfake content and AI-generated misinformation",
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'shield',
        //   image: 'calloutTransparency1',
        // },
        // {
        //   title: 'Engage users with easily consumable reports',
        //   description:
        //     'Deliver transparency reports to users in easily consumable, mobile-friendly formats with nothing to download or install',
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',

        //   iconname: 'calendar',
        //   iconcolor: 'sky',
        //   image: 'calloutTransparency3',
        // },
        // {
        //   title: 'Use data templates',
        //   description:
        //     'Accelerate your workflow for common Digital Product Passport data points by using templates to send repeatable events',
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'list',
        //   image: 'calloutTransparency4',
        // },
        // {
        //   title: 'Customize on-chain messages',
        //   description:
        //     'Send custom messages to the blockchain related to your token, and drive transparency with your customers',
        //   // colbgcolor: 'sky-1',
        //   colbggradient: 'gradient-callout-silver',
        //   // colbordercolor: 'sky-1',
        //   titlecolor: 'black-4',
        //   desccolor: 'black-3',
        //   iconcolor: 'sky',

        //   iconname: 'conversation',
        // },
      ],
    },
  ],
};

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Gather product data for your Digital Product Passport using
                templates for your industry
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <div
              class="d-flex align-items-start justify-content-center"
              // style={{ minHeight: 580 }}
            >
              <StaticImage
                src="../../images/featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
                alt="PicoNext"
                // loading="lazy"
                placeholder="blurred"
                layout="constrained"
                width={275}
                height={400}
                transformOptions={{ cropFocus: "north" }}
                // height={568}
              />
              {/* <StaticImage
                    style={{
                      position: 'absolute',
                      zIndex: 3,
                    }}
                    src="../../images/feature/iphone-frame.png"
                    alt="PicoNext"
                    // loading="lazy"
                    placeholder="blurred"
                    layout="constrained"

                    width={275}
                    // height={568}
                  />
                  <div
                    class="rounded-corners"
                    style={{ height: 545, overflow: 'hidden' }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      width="250"
                      style={{
                        zIndex: 2,
                        marginTop: 30,
                        // marginLeft: 12,
                      }}
                    >
                      <source
                        src="https://picovideomedia.s3.amazonaws.com/static/video/1-design.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div> */}
            </div>
          </div>
          <div class="col-md-8 d-flex flex-column align-items-center justify-content-center text-center text-lg-center">
            <h1 class="fw-normal black-1 display-2 page-title">
              Digital Product Passport Planner
            </h1>

            <p class="display-4 line-height-4 p-2">
              Gather sustainability data, manage products, and prepare your
              Digital Product Passport using a template-based tool
            </p>
            {/* <Link
              to="/request-invite-dpp-planner/"
              class="btn btn-outline-primary"
            >
              Request free access &rarr;
            </Link> */}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3"></div>

    <hr size={1} />
    <div class="gradient-callout-silver-reverse">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Digital Product Passport Planner features
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              {capabilityData.rowdata[0].rowdata.map((item) => (
                <FeatureLink
                  title={item.title}
                  desc={item.description}
                  image={item.image ? item.image : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container my-2 mt-4 py-4">
        <div class="row">
          <div class="col-12">
            <h3 class="text-center mb-4 black-4">
              Take a video tour of Digital Product Passport Planner
            </h3>
            <div class="d-flex flex-row flex-wrap justify-content-center">
              <YoutubeComponent id="ShMhe4EBKsg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr size={1} />

    <CalloutSupportSection
      title="getting started with Digital Product Passport Planner"
      section="dpp-planner"
      color="bluegreen"
    />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Planner"
      description="Gather and organize product sustainability data for your products to prepare your Digital Product Passport"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/passport-planner-attribute-editor-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
