//React
import React from "react";

//Gatsby

//Packages

//Components
import Layout from "../../components/layout";
import PostCta from "../../components/marketing/postCta";
import { Metatags } from "../../components/marketing/metatags";

const Snippet = (props) => (
  <Layout>
    <hr size={1} />
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h1 class="display-2 fw-normal">PicoNext Prism Newsletter</h1>
          <p class="lead">
            Get the latest on brand experience &amp; blockchain strategies with
            the PicoNext Prism Newsletter &mdash; subscribe below.
          </p>
        </div>
      </div>
    </div>
    <PostCta context="emailSub" />
    <div class="my-4" />
  </Layout>
);

export default Snippet;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="PicoNext - Prism Newsletter"
      description="Strategies for experience brands - Direct from the front lines to your inbox"
      image="https://picovideomedia.s3.amazonaws.com/static/logos/prism-marquee.png?t=2"
    />
  );
};
