//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layoutLanding";
import { Metatags } from "../../components/marketing/metatags";

import ContactLeadForm from "../../components/marketing/contactLeadForm";

const About = (props) => (
  <Layout>
    <div class="flex-grow-1">
      <div class="container">
        <div class="row my-3">
          <div class="col-lg-8 p-4">
            <div class="blue-1 landing-subhead">Strategy guide</div>
            <h1 class="h2">
              <span class="fw-normal">Mitigate brand misinformation</span>

              <br />
              <span class="fw-light">
                and deepfakes in AI-generated content
              </span>
            </h1>
            <p class="lead line-height-4 py-3">
              In this free guide, see how you can use blockchains to mitigate
              damage from AI-generated brand misinformation and deepfakes
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center my-4">
              {/* <StaticImage
                    src="../../images/featureperspective/resortsplus-usecases/resortsplus-maldives-R.png"
                    alt="Eight Web3 Loyalty Use Cases"
                    loading="lazy"
                    placeholder="blurred"
                    // layout="fullWidth"
                    width={200}
                    height={260}
                    className="mx-3"
                    transformOptions={{ cropFocus: `north` }}
                    // height={400}
                    // style={{ position: `absolute` }}
                  /> */}
              <StaticImage
                src="../../images/featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-L.png"
                alt="Prevent damage from AI deepfakes"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                width={200}
                height={260}
                className="mx-3"
                transformOptions={{ cropFocus: `north` }}
                // height={400}
                // style={{ position: `absolute` }}
              />
              <StaticImage
                src="../../images/landing/202304-ai-content-authentication/report-AI-content-authentication.png"
                alt="Increase customer trust in your brand"
                loading="lazy"
                placeholder="blurred"
                // layout="fullWidth"
                // width={210}
                height={260}
                className="mx-3 "
                // style={{ position: `absolute` }}
              />
            </div>
            <div class=" bg-gray-7 rounded-corners p-3">
              <strong>In this free guide, you'll discover:</strong>
              <ul>
                <li>
                  The risks that brand misinformation and deepfakes pose to your
                  business
                </li>

                <li>
                  The spectrum of options to mitigate damage from AI-generated
                  misinformation
                </li>
                <li>How to use blockchains to authenticate brand content</li>
                <li>
                  Examples how to visualize content provenance and history
                </li>
                <li>
                  And more ideas and strategies to use blockchains to verify
                  brand content
                </li>
              </ul>
            </div>
            {/* <div class="d-flex flex-row align-items-center justify-content-center my-4">
                
                </div> */}

            <p class="lead line-height-4 mt-4">
              Receive your free copy of "Mitigate misinformation and deepfakes
              from AI-generated brand content" by filling out the form
            </p>
          </div>
          <div class="col-lg-4 landing-offer-col">
            <ContactLeadForm
              leadSource="offer-AI-brand-misinformtion"
              hideTitle={true}
              title="Request invite"
              subtitle="Please fill out the form below to receive your free guide"
              cta="Submit"
              context="landing"
              offerAttachment="PicoNext--Report-AI-Content-Authentication.pdf"
              offerTitle="Mitigate brand misinformation and deepfakes in AI-generated content"
              formCallout={true}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Authenticating AI-generated brand content"
      description="Get access to the strategy guide 'Mitigate brand misinformation and deepfakes in AI-generated content'"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "landing/202304-ai-content-authentication/report-AI-content-authentication.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
