//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

// import AlternatingCallout from "../../components/marketing/alternatingCallout";
// import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
// import FeatureQuad from "../../components/marketing/featureQuad";
import PriceComparison from "../../components/marketing/priceComparison";

//Icons

const About = (props) => (
  <Layout>
    <div class="gradient-callout-cousteau py-2 mb-2">
      <div class="container my-2 py-4">
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h3 class="fw-light text-center white-1">
                Use flexible Digital Product Passport plans to accelerate your
                business
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h1 class="fw-medium black-1">Digital Product Passport Pricing</h1>

            {/* <p class="lead">
              Deliver tangible insight into product sustainability and comply
              with regulatory requirements
            </p> */}
          </div>
        </div>
      </div>
    </div>
    {/* <FeaturePreviewSectionPlatform /> */}
    {/* <div class="d-flex align-items-center justify-content-center mb-3">
      <Link
        to="/about/features"
        class="btn btn-primary btn-cta mb-1 "
        activeClassName="btn btn-primary btn-cta"
      >
        See more PicoNext features <FaArrowRight class="btn-arrow" />
      </Link>
    </div> */}
    <hr size={1} />
    {/* <h2 class="text-center mt-3 mb-4">Digital Product Passport Platform</h2> */}
    <PriceComparison />
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Pricing"
      description="See pricing for the PicoNext Digital Product Platform"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-autumncollection-R.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
