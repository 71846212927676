//React
import React from "react";

//Gatsby
// import { Link } from 'gatsby'

//Icons
const accordionData = [
  {
    id: 1,
    title:
      "Battery information available to those with a need-to-know, as well as the European Commission (EC)",
  },
  {
    id: 2,
    title:
      "Battery information available to the EC, national regulatory organizations (“notified bodies”), and market surveillance authorities",
  },
  {
    id: 3,
    title:
      "Individual battery item-level information available to those with a need-to-know",
  },
];

const accordionContent = (id) => {
  switch (id) {
    case 1:
      return (
        <>
          <p>
            The following non-public information is required at the battery
            model level
          </p>
          <ul>
            <li>
              Detailed composition, including materials used in the cathode,
              anode and electrolyte
            </li>

            <li>
              Part numbers for components and contact details of sources for
              replacement spares
            </li>

            <li>
              Dismantling information, including at least:
              <ul>
                <li>
                  Exploded diagrams of the battery system/pack showing the
                  location of battery cells
                </li>

                <li>Disassembly sequences</li>

                <li>Type and number of fastening techniques to be unlocked</li>

                <li>Tools required for disassembly</li>

                <li>Warnings if risk of damaging parts exist</li>

                <li>Amount of cells used and layout</li>
              </ul>
            </li>

            <li>Safety measures</li>
          </ul>
        </>
      );
    case 2:
      return (
        <>
          <p>
            The following non-public information is required at the battery
            model level:
          </p>
          <ul>
            <li>
              Results of test reports proving compliance with the requirements
              of the E.U. Batteries Regulation (or associated regulations)
            </li>
          </ul>
        </>
      );
    case 3:
      return (
        <>
          <p>
            The following non-public information is required at the battery item
            level:
          </p>
          <ul>
            <li>
              Battery performance and durability parameters, when the battery is
              placed on the market and when it is subject to changes in its
              status
            </li>

            <li>State of battery health</li>

            <li>
              Battery status, i.e.: “original”, “repurposed”, “re-used”,
              “remanufactured”, or “waste”
            </li>

            <li>
              Information from battery use, including:
              <ul>
                <li>Number of charging and discharging cycles</li>

                <li>Negative events, such as accidents</li>

                <li>
                  Periodically recorded information on environmental conditions,
                  including temperature, and on the state of charge
                </li>
              </ul>
            </li>
          </ul>
        </>
      );

    default:
      return <></>;
  }
};
const Snippet = (props) => {
  return (
    <div id="accordion">
      {accordionData.map((item) => (
        <div class="card">
          <div class="card-header" id={`heading-${item.id}`}>
            <h5 class="mb-0">
              <button
                class="btn btn-link"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${item.id}`}
                aria-expanded={"false"}
                // aria-expanded={item.id === 1 ? 'true' : 'false'}
                aria-controls={`collapse-${item.id}`}
              >
                <div class="caret"></div>
                {item.title}
              </button>
            </h5>
          </div>

          <div
            id={`collapse-${item.id}`}
            class={`collapse`}
            // class={`collapse ${item.id === 1 ? 'show' : ''}`}
            aria-labelledby={`heading-${item.id}`}
            data-parent="#accordion"
          >
            <div class="card-body">{accordionContent(item.id)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Snippet;
