//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import CalloutSupportSection from "../../components/marketing/calloutSupportSection";
import FeaturePreviewSectionPlatform from "../../components/marketing/featurePreviewSectionPlatform";

//Icons

const About = (props) => (
  <Layout>
    <div class="">
      <div class="container container-page my-0 pt-4 pb-2">
        <div class="row">
          <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
            <h1 class="fw-light black-1 display-2">
              PicoNext Platform Overview for Brands
            </h1>

            <p class="lead line-height-3 p-2">
              See the PicoNext platform capabilities and how they can help you
              deliver Digital Product Passports
            </p>
          </div>
        </div>
      </div>
    </div>

    <FeaturePreviewSectionPlatform />
    <CalloutSupportSection
      title="launching a program with public ledgers"
      section=""
      color="bluegreen"
    />

    <div class="mt-1 mb-2 mx-lg-5"></div>
  </Layout>
);

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Digital Product Passport Platform Overview"
      description="Overview of Digital Product Passport platform features for brands"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/passport-usecases/fashionnetwork-autumncollection-L.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
