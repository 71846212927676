//React
import React from "react";

//Gatsby

//Icons
const accordionData = [
  { id: 1, title: "Durability" },
  { id: 2, title: "Reliability" },
  { id: 3, title: "Reusability" },
  { id: 4, title: "Upgradability" },
  { id: 5, title: "Reparability" },
  { id: 6, title: "Possibility of maintenance and refurbishment" },
  { id: 7, title: "Presence of substances of concern" },
  { id: 8, title: "Energy use or energy efficiency" },
  { id: 9, title: "Resource use or resource efficiency" },
  { id: 10, title: "Recycled content" },
  { id: 11, title: "Possibility of recovery of materials" },
  {
    id: 12,
    title:
      "Environmental impacts, including carbon and environmental footprint",
  },
  { id: 13, title: "The carbon footprint of the product" },
  { id: 14, title: "Expected generation of waste materials" },
];

const accordionContent = (id) => {
  switch (id) {
    case 1:
      return (
        <>
          Durability and reliability of the product or its components, including
          <ul>
            <li>Guaranteed lifetime</li>
            <li>Technical lifetime</li>
            <li>Mean time between failures</li>
            <li>Indication of real use information on the product</li>
            <li>Resistance to stresses or aging mechanisms</li>
          </ul>
        </>
      );
    case 2:
      return (
        <>
          Quantity, characteristics and availability of consumables needed for
          proper use and maintenance
        </>
      );
    case 3:
      return <>Options and Incorporation of used components</>;
    case 4:
      return <>Ease of upgrading</>;
    case 5:
      return (
        <>
          Ease of repair and maintenance as expressed through:
          <ul>
            <li>
              Characteristics, availability and delivery time of spare parts
            </li>
            <li>Modularity</li>
            <li>Compatibility with commonly available spare parts</li>
            <li>Availability of repair and maintenance instructions</li>
            <li>Number of materials and components used</li>
            <li>Use of standard components</li>
            <li>
              Use of component and material coding standards for the
              identification of components and materials
            </li>
            <li>Number and complexity of processes and tools needed</li>
            <li>Ease of non-destructive disassembly and re-assembly</li>
            <li>Conditions for access to product data</li>
            <li>
              Conditions for access to or use of hardware and software needed
            </li>
          </ul>
        </>
      );
    case 6:
      return (
        <>
          Re-use, remanufacturing and refurbishment as expressed through
          <ul>
            <li>Number of materials and components used</li>
            <li>Use of standard components</li>
            <li>
              Use of component and material coding standards for the
              identification of components and materials
            </li>
            <li>Number and complexity of processes and tools needed</li>
            <li>Ease of non- destructive disassembly and re-assembly</li>
            <li>Conditions for access to product data</li>
            <li>
              Conditions for access to or use of hardware and software needed
            </li>
            <li>
              Conditions of access to test protocols or not commonly available
              testing equipment
            </li>
            <li>
              Availability of guarantees specific to remanufactured or
              refurbished products
            </li>
            <li>
              Conditions for access to or use of technologies protected by
              intellectual property rights
            </li>
            <li>Modularity</li>
          </ul>
        </>
      );
    case 7:
      return (
        <>
          Use of substances, on their own, as constituents of substances or in
          mixtures, during the production process of products, or leading to
          their presence in products, including once these products become waste
        </>
      );
    case 8:
      return <></>;
    case 9:
      return (
        <>
          Consumption of energy, water and other resources in one or more life
          cycle stages of the product, including the effect of physical factors
          or software and firmware updates on product efficiency and including
          the impact on deforestation
        </>
      );
    case 10:
      return (
        <>
          Use or content of recycled materials
          <br />
          Possibility of remanufacturing or recycling
          <br />
          Ease and quality of recycling as expressed through:
          <br />
          <ul>
            <li>
              Use of easily recyclable materials, safe, easy and non-destructive
              access to recyclable components and materials or components and
              materials containing hazardous substances
            </li>
            <li>Material composition and homogeneity</li>
            <li>
              Possibility for high-purity sorting, number of materials and
              components used
            </li>
            <li>Use of standard components</li>
            <li>
              Use of component and material coding standards for the
              identification of components and materials
            </li>
            <li>Number and complexity of processes and tools needed</li>
            <li>Ease of non-destructive disassembly and re-assembly</li>
            <li>conditions for access to product data</li>
            <li>
              Conditions for access to or use of hardware and software needed
            </li>
          </ul>
        </>
      );
    case 11:
      return (
        <>
          Avoidance of technical solutions detrimental to re-use, upgrading,
          repair, maintenance, refurbishment, remanufacturing and recycling of
          products and components
        </>
      );
    case 12:
      return (
        <>
          The environmental footprint of the product, expressed as a
          quantification, in accordance with the applicable delegated act, of a
          product’s life cycle environmental impacts, whether in relation to one
          or more environmental impact categories or an aggregated set of impact
          categories
        </>
      );
    case 13:
      return (
        <>
          Emissions to air, water or soil released in one or more life cycle
          stages of the product
        </>
      );
    case 14:
      return (
        <>
          Weight and volume of the product and its packaging, and the
          product-to-packaging relation
          <br />
          Microplastic release
          <br />
          Amounts of waste generated, including plastic waste and packaging
          waste and their ease of Re-use, and amounts of hazardous waste
          generated
        </>
      );
    default:
      return <></>;
  }
};
const Snippet = (props) => {
  return (
    <div id="accordion">
      {accordionData.map((item) => (
        <div class="card">
          <div class="card-header" id={`heading-${item.id}`}>
            <h5 class="mb-0">
              <button
                class="btn btn-link"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${item.id}`}
                aria-expanded={item.id === 1 ? "true" : "false"}
                aria-controls={`collapse-${item.id}`}
              >
                <div class="caret"></div>
                {item.title}
              </button>
            </h5>
          </div>

          <div
            id={`collapse-${item.id}`}
            class={`collapse ${item.id === 1 ? "show" : ""}`}
            aria-labelledby={`heading-${item.id}`}
            data-bs-parent="#accordion"
          >
            <div class="card-body">{accordionContent(item.id)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Snippet;
