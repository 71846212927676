//React
import React from "react";

//Gatsby
import { graphql, Link } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";
import BlogCallout from "../../components/marketing/blogCallout";

//Icons
// import { FaArrowRight } from "react-icons/fa";

const useCaseData = [
  {
    context: "Event Experiences",
    contextColor: "yellow-1",
    title: "Capture event experiences",
    description:
      "Deliver digital tokens that capture event experiences as digital mementos for customers, including with immersive multimedia and no-code minting",
    image: "nft2EventBandsAlt",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "images",
    // videosource: 'console-1',
  },

  {
    context: "Event admission",
    contextColor: "pink-1",
    title: "Event tickets",
    description:
      "Use tokens as tickets to admit customers into events, and make it easy for staff to verify by scanning QR codes with their smartphones",
    image: "nftLoyaltyRedeemEvent",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-2',
  },

  {
    context: "Event benefits",
    contextColor: "green-1",
    title: "Build token-enabled communities",
    description:
      "Build community among your event attendees with curated, token-enabled content that engage customers with exclusive benefits",
    image: "nft2EventBandsStore",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "mobile",
    // videosource: 'console-3',
  },
  {
    context: "Gen Z & Millennials",
    contextColor: "sky-1",
    title: "Access new audiences through social impact",
    description:
      "Connect deeper with Gen Z / Millennial customers through branded experience tokens that support the social impact causes they care most about",
    image: "nft2LoyaltyImpactA",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'gray-3',
    anchor: "mobile",
    // videosource: 'console-2',
  },
  // {
  //   context: 'Authentication & Resale',
  //   contextColor: 'dkorange-1',
  //   title: 'Authenticate tickets and benefit from resale',
  //   description:
  //     'Verify event tickets as authentic and participate in secondary resale commissions through programmable smart contracts',
  //   image: 'nft3EventB',
  //   iconShow: false,
  //   // //iconContext: 'iphone',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'gray-3',
  //   anchor: 'mobile',
  //   // videosource: 'console-3',
  // },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: true,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-cousteau py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Deliver engaging, branded event experiences using tokens
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">Event Marketing</h1>

              <p class="lead">
                Engage customers with branded event experiences, enhanced by
                blockchain technology
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />
      <BlogCallout
        context="events"
        title="Event Resources"
        desc="Articles to help you engage customers using tokenized events"
        showMoreButton={true}
      />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Token-enabled Events"
      description="Engage customers with tokens and branded events using PicoNext"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/feature-nft-2-metadata-event.png"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    blogs: allMdx(
      sort: { frontmatter: { date: DESC } }
      filter: {
        frontmatter: { path: { glob: "/article/*" }, publish: { eq: 1 } }
        id: {
          in: [
            "230c7015-2e89-56a6-9a58-23504f5d554f"
            "8e6a0e3e-bbb2-5fd4-85b3-5b0b87602b11"
          ]
        }
      }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`;
