//React
import React from "react";

//Gatsby
import { graphql } from "gatsby";

//Packages

//Components
import Layout from "../../components/layout";
import { Metatags } from "../../components/marketing/metatags";

import AlternatingCallout from "../../components/marketing/alternatingCallout";

//Icons

const useCaseData = [
  {
    context: "Debunk deepfakes",
    contextColor: "yellow-1",
    title: "Authenticate branded content",
    description:
      "Use blockchains to cryptographically sign and verify your branded content, allowing users to trust that what they see actually comes from you",
    image: "auth1Content",
    iconShow: false,
    // //iconContext: 'imagespread',
    iconColor: "blue",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "experience",
    // videosource: 'console-1',
  },

  {
    context: "Increase user trust",
    contextColor: "sky-1",
    title: "Protect brand reputation",
    description:
      "Protect your brand's reputation from deepfake and AI-generated misinformation by giving users a blockchain-verified source of content you produce",
    image: "auth2Content",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "sky",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "trust",
    // videosource: 'console-3',
  },
  {
    context: "Content transparency",
    contextColor: "green-1",
    title: "Show content provenance",
    description:
      "Enable audiences to inspect content history on public, distributed blockchains and trace that back to verified sources",
    image: "auth3Content",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    lineClass: "gray-5",
    anchor: "transparency",
    // videosource: 'console-2',
  },
  {
    context: "Simplified workflows",
    contextColor: "seafoam-1",
    title: "No-code workflows",
    description:
      "Remove the complexity of developing blockchain smart contracts by using no-code workflows",
    image: "auth4Content",
    iconShow: false,
    // //iconContext: 'iphone',
    iconColor: "dkgreen",
    iconcolor: "white",
    iconfill: "white",
    // lineClass: 'green-1',
    anchor: "sustainability",
    // videosource: 'console-2',
  },
  // {
  //   title: 'Publish across channels',
  //   description:
  //     'From PicoNext, publish your videos across channels and meet your prospective employees where they are - including on TikTok, Instagram Stories and more',
  //   image: 'featureVideoSharing',
  //   iconShow: false,
  //   // //iconContext: 'imagespread',
  //   iconColor: 'dkorange',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   lineClass: 'red-1',
  //   anchor: 'images',
  // },
  // {
  //   context: 'Solution',
  //   contextColor: 'dkgreen-1',

  //   title: 'Social design',
  //   description:
  //     "PicoNext helps you drive revenue through consistent engagement with your customers. It's easy to discover designers with fresh content templates you can remix to drive your business - no matter where you are.",
  //   image: 'usecaseRestaurantScreenImage',
  //   iconShow: false,
  //   //iconContext: 'lightning',
  //   iconColor: 'dkgreen',
  //   iconcolor: 'white',
  //   iconfill: 'white',
  //   // lineClass: 'dkorange-1',
  //   anchor: 'share',
  // },
];

const About = (props) => {
  return (
    <Layout>
      <div class="gradient-callout-seatosky py-2 mb-2">
        <div class="container my-2 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <h3 class="fw-light text-center white-1">
                  Authenticate branded content and protect your brand from
                  AI-generated deepfakes
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="container container-page my-0 pt-4 pb-2">
          <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="fw-medium black-1">
                Authentication for AI-Generated Content
              </h1>

              <p class="lead line-height-4">
                Use blockchains to authenticate your branded content as genuine,
                and protect your brand's reputation from deepfake content and
                AI-generated misinformation
              </p>
            </div>
          </div>
        </div>
      </div>
      <AlternatingCallout featureData={useCaseData} />
    </Layout>
  );
};

export default About;

export const Head = ({ data }) => {
  return (
    <Metatags
      title="Verify AI generated content using blockchains"
      description="Ensure AI generated content authenticity and protect your brand's reputation from deepfakes"
      image={`https://${process.env.HOSTNAME}${data?.metadataImage?.edges?.[0]?.node?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`}
    />
  );
};

export const pageQuery = graphql`
  query {
    metadataImage: allFile(
      filter: {
        relativePath: {
          eq: "featureperspective/contentnetwork-usecases/contentnetwork-retail-athleisure-L.jpg"
        }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
